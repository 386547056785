import React from 'react'

const Icon: React.FC = (props) => {
  return (
    <img
      src={require('assets/images/wallet/TokenPocket.svg').default}
      alt="TokenPocket"
      style={{ width: '32px', height: '32px', ...props }}
    />
  )
}

export default Icon
