import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" color="white" {...props}>
      <path d="M24 0H0V24H24V0Z" fill="white" fillOpacity="0.01" />
      <path
        d="M9.1419 21.5856C7.46635 21.0868 5.9749 20.1606 4.79393 18.9335C5.2345 18.4113 5.5 17.7367 5.5 17C5.5 15.3432 4.15685 14 2.5 14C2.39977 14 2.3007 14.0049 2.203 14.0145C2.0699 13.3638 2 12.6901 2 12C2 10.9547 2.16039 9.94685 2.4579 8.9997C2.47191 8.9999 2.48594 9 2.5 9C4.15685 9 5.5 7.65685 5.5 6C5.5 5.52435 5.3893 5.07455 5.1923 4.675C6.34875 3.59969 7.76025 2.79495 9.32605 2.36151C9.8222 3.33404 10.8333 4.00001 12 4.00001C13.1667 4.00001 14.1778 3.33404 14.674 2.36151C16.2398 2.79495 17.6512 3.59969 18.8077 4.675C18.6107 5.07455 18.5 5.52435 18.5 6C18.5 7.65685 19.8432 9 21.5 9C21.5141 9 21.5281 8.9999 21.5421 8.9997C21.8396 9.94685 22 10.9547 22 12C22 12.6901 21.9301 13.3638 21.797 14.0145C21.6993 14.0049 21.6002 14 21.5 14C19.8432 14 18.5 15.3432 18.5 17C18.5 17.7367 18.7655 18.4113 19.2061 18.9335C18.0251 20.1606 16.5336 21.0868 14.8581 21.5856C14.4714 20.3759 13.338 19.5 12 19.5C10.662 19.5 9.5286 20.3759 9.1419 21.5856Z"
        stroke="#181818"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M12 15.5C13.933 15.5 15.5 13.933 15.5 12C15.5 10.067 13.933 8.5 12 8.5C10.067 8.5 8.5 10.067 8.5 12C8.5 13.933 10.067 15.5 12 15.5Z"
        stroke="#181818"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default Icon
