import React, { useState } from 'react'
import styled from 'styled-components'
import { Flex, Box, Text, ExpandableLabel, LinkExternal, Grid } from 'uikit'
import { useTranslation } from 'react-i18next'
import { getApy } from 'utils/compoundApyHelpers'

const Footer = styled(Flex)`
  width: 100%;
  background: white;
`

const BulletList = styled.ul`
  list-style-type: none;
  margin-top: 12px;
  padding: 0;
  li {
    margin: 0;
    padding: 0;
    align-items: flex-start;
  }
  li::before {
    content: '•' !important;
    margin-right: 4px;
    color: ${({ theme }) => theme.colors.textSubtle};
  }
  li::marker {
    font-size: 12px;
  }
`

const StyledDivide = styled.div`
  background: rgba(115, 126, 141, 0.16);
  height: 1px;
  margin: -8px 8px 0 8px;
`

interface RoiCalculatorFooterProps {
  isFarm: boolean
  apr: number
  displayApr: string
  autoCompoundFrequency: number
  multiplier: string
  linkLabel: string
  linkHref: string
  performanceFee: number
  clickId: string
  details?: any
}

const RoiCalculatorFooter: React.FC<RoiCalculatorFooterProps> = ({
  isFarm,
  apr,
  autoCompoundFrequency,
  linkLabel,
  linkHref,
  performanceFee,
  clickId,
  details,
}) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const { t } = useTranslation()

  const gridRowCount = isFarm ? 3 : 2
  const apy = (getApy(apr, autoCompoundFrequency > 0 ? autoCompoundFrequency : 1, 365, performanceFee) * 100).toFixed(2)

  return (
    <Footer p="16px" flexDirection="column">
      <StyledDivide />
      <Flex justifyContent="center" id={clickId}>
        <ExpandableLabel expanded={isExpanded} onClick={() => setIsExpanded((prev) => !prev)}>
          <span style={{ fontWeight: 'normal' }}>{isExpanded ? t('COMPONENTS_34') : t('COMPONENTS_35')}</span>
        </ExpandableLabel>
      </Flex>
      {isExpanded && (
        <Box px="8px">
          <Grid gridTemplateColumns="2.5fr 1fr" gridRowGap="8px" gridTemplateRows={`repeat(${gridRowCount}, auto)`}>
            {/* {isFarm && (
              <>
                <Text color="textSubtle" small>
                  {t('APR (incl. LP rewards)')}
                </Text>
                <Text small textAlign="right">
                  {displayApr}%
                </Text>
              </>
            )} */}
            <Text color="textSubtle" small>
              {isFarm ? t('COMPONENTS_76') : t('COMPONENTS_77')}
            </Text>
            <Text small textAlign="right">
              {apr.toFixed(2)}%
            </Text>
            {details?.doubleDigTokenApr && isFarm ? (
              <>
                <Text color="textSubtle" small>
                  {t('COMPONENTS_78', { asset: details?.doubleDigToken?.symbol })}
                </Text>
                <Text small textAlign="right">
                  {details?.doubleDigTokenApr.toFixed(2)}%
                </Text>
                <Text color="textSubtle" small>
                  {t('COMPONENTS_79')}
                </Text>
                <Text small textAlign="right">
                  {details?.doubleDigFeeApr.toFixed(2)}%
                </Text>
              </>
            ) : null}
            <Text color="textSubtle" small>
              {t('COMPONENTS_216', { number: autoCompoundFrequency > 0 ? autoCompoundFrequency : 1 })}
            </Text>
            <Text small textAlign="right">
              {apy}%
            </Text>
          </Grid>
          <BulletList>
            <li>
              <Text fontSize="14px" textAlign="left" color="textSubtle" display="inline">
                {t('COMPONENTS_80')}
              </Text>
            </li>
            {isFarm && (
              <li>
                <Text fontSize="14px" textAlign="left" color="textSubtle" display="inline">
                  {t('COMPONENTS_81')}
                </Text>
              </li>
            )}
            <li>
              <Text fontSize="14px" textAlign="left" color="textSubtle" display="inline">
                {t('COMPONENTS_82')}
              </Text>
            </li>
            {performanceFee > 0 && (
              <li>
                <Text mt="14px" fontSize="14px" textAlign="left" color="textSubtle" display="inline">
                  {t('COMPONENTS_83', { asset: performanceFee })}
                </Text>
              </li>
            )}
          </BulletList>
          <Flex justifyContent="center" mt="24px" mb="10px">
            <LinkExternal href={linkHref}>
              <span style={{ fontWeight: 'normal' }}>{linkLabel}</span>
            </LinkExternal>
          </Flex>
        </Box>
      )}
    </Footer>
  )
}

export default RoiCalculatorFooter
