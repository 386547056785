import React, { useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Box from '../../components/Box/Box'
import Grid from '../../components/Box/Grid'
import Text from '../../components/Text/Text'
import { Modal, ModalBody } from '../Modal'
import config from './config'
import { Login } from './types'
import WalletCard, { MoreWalletCard } from './WalletCard'

interface Props {
  login: Login
  onDismiss?: () => void
  displayCount?: number
}

const WalletWrapper = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.cardBorder};
`

const ConnectModal: React.FC<Props> = ({ login, onDismiss = () => null, displayCount = 3 }) => {
  const [showMore, setShowMore] = useState(false)
  const displayListConfig = showMore ? config : config.slice(0, displayCount)
  const { t } = useTranslation()

  return (
    <Modal
      title="Connect to a wallet"
      onDismiss={onDismiss}
      minWidth={isMobile ? '90%' : '340px'}
      bodyPadding="0px"
      style={{ maxWidth: '340px' }}
    >
      {/* {config.map((entry, index) =>
      isMobile ? (
        <WalletCard
          key={entry.title}
          login={login}
          walletConfig={entry}
          onDismiss={onDismiss}
          mb={index < config.length - 1 ? '8px' : '0'}
        />
      ) : !isMobile && !entry?.onlyH5 ? (
        <WalletCard
          key={entry.title}
          login={login}
          walletConfig={entry}
          onDismiss={onDismiss}
          mb={index < config.length - 1 ? '8px' : '0'}
        />
      ) : null
    )} */}
      <ModalBody width="340px">
        <WalletWrapper py="24px" maxHeight="453px" overflowY="auto">
          <Grid gridTemplateColumns="1fr 1fr">
            {displayListConfig.map((wallet) => (
              <Box key={wallet.title}>
                <WalletCard walletConfig={wallet} login={login} onDismiss={onDismiss} />
              </Box>
            ))}
            {!showMore && <MoreWalletCard onClick={() => setShowMore(true)} />}
          </Grid>
        </WalletWrapper>
        <Box p="16px 24px 15px">
          <Text textAlign="center" color="textRemark" as="p" mb="16px" fontSize="14px">
            {t('COMPONENTS_218')}
          </Text>
          {/* <Text color="white">
            <Button
              as="a"
              target="_blank"
              href="https://docs.mojitoswap.finance/get-started/connect-wallet-to-mojitoswap"
              width="100%"
              style={{ borderRadius: '30px' }}
              // {...getExternalLinkProps()}
            >
              {t('COMPONENTS_219')}
            </Button>
          </Text> */}
        </Box>
      </ModalBody>
    </Modal>
  )
}

export default ConnectModal
