import React, { useState, useCallback, CSSProperties } from 'react'
import { Currency, Pair } from 'shardex-sdk'
import { Button, ChevronDownIcon, Text } from '../../uikit'
import styled from 'styled-components'
import { darken } from 'polished'
import { useCurrencyBalance } from '../../state/wallet/hooks'
import CurrencySearchModal from '../SearchModal/CurrencySearchModal'
import CurrencyLogo from '../CurrencyLogo'
import DoubleCurrencyLogo from '../DoubleLogo'
import { RowBetween } from '../Row'
import { Input as NumericalInput } from '../NumericalInput'
import { useActiveWeb3React } from '../../hooks'
import { useTranslation } from 'react-i18next'

const InputRow = styled.div<{ selected: boolean; isFocus: boolean }>`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 6px 15px;
  background: ${({ isFocus }) => (isFocus ? 'transparent' : '#efeff2')};
  border: 1px solid ${({ isFocus }) => (isFocus ? '#5218f4' : 'transparent')};
  border-radius: 8px;
`
const CurrencySelect = styled.button<{ selected: boolean }>`
  align-items: center;
  height: 40px;
  font-size: 18px;
  font-weight: 500;
  background-color: transparent;
  // color: ${({ selected, theme }) => (selected ? theme.colors.text : '#FFFFFF')};
  color: #fff;
  border-radius: 8px;
  outline: none;
  cursor: pointer;
  user-select: none;
  border: none;
  padding: 0 0.5rem;

  :hover {
    background-color: white;
    color: #fff;
  }
`
const LabelRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  color: ${({ theme }) => theme.colors.text};
  font-size: 14px;
  line-height: 1rem;
  padding: 0 0 8px;

  span:hover {
    cursor: pointer;
    color: ${({ theme }) => darken(0.2, theme.colors.textSubtle)};
  }
`
const Aligner = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const InputPanel = styled.div<{ hideInput?: boolean }>`
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  border-radius: ${({ hideInput }) => (hideInput ? '0px' : '4px')};
  background-color: transparent;
  z-index: 1;
`
const Container = styled.div<{ hideInput: boolean }>`
  border-radius: 16px;
  background-color: transparent;
`

interface CurrencyInputPanelProps {
  value: string
  onUserInput: (value: string) => void
  onMax?: () => void
  showMaxButton: boolean
  label?: string
  onCurrencySelect?: (currency: Currency) => void
  currency?: Currency | null
  disableCurrencySelect?: boolean
  hideBalance?: boolean
  pair?: Pair | null
  hideInput?: boolean
  otherCurrency?: Currency | null
  id: string
  showCommonBases?: boolean
  styles?: CSSProperties
  showWSHM?: boolean
}

export default function CurrencyInputPanel({
  value,
  onUserInput,
  onMax,
  showMaxButton,
  label,
  onCurrencySelect,
  currency,
  disableCurrencySelect = false,
  hideBalance = false,
  pair = null, // used for double token logo
  hideInput = false,
  otherCurrency,
  id,
  showCommonBases,
  styles,
  showWSHM = false,
}: CurrencyInputPanelProps) {
  const [modalOpen, setModalOpen] = useState(false)
  const { t } = useTranslation()
  const { account } = useActiveWeb3React()
  const selectedCurrencyBalance = useCurrencyBalance(account ?? undefined, currency ?? undefined)
  const translatedLabel = label || t('COMPONENTS_177')
  const handleDismissSearch = useCallback(() => {
    setModalOpen(false)
  }, [setModalOpen])
  const [isFocus, setFocus] = useState(false)

  return (
    <InputPanel id={id} style={styles}>
      <Container hideInput={hideInput}>
        {!hideInput && (
          <LabelRow>
            <RowBetween>
              <Text
                fontSize="14px"
                style={{
                  fontFamily: 'mojitofont',
                  color: '#494E67',
                }}
              >
                {translatedLabel}
              </Text>
              {account && (
                <Text
                  onClick={onMax}
                  fontSize="14px"
                  style={{
                    display: 'inline',
                    cursor: 'pointer',
                    fontWeight: 500,
                    fontFamily: 'mojitofont',
                    color: '#494E67',
                  }}
                >
                  {!hideBalance && !!currency && selectedCurrencyBalance
                    ? t('COMPONENTS_32', { asset: selectedCurrencyBalance?.toSignificant(6) })
                    : ' -'}
                </Text>
              )}
            </RowBetween>
          </LabelRow>
        )}
        <InputRow
          isFocus={isFocus}
          style={hideInput ? { padding: '0', borderRadius: '8px' } : {}}
          selected={disableCurrencySelect}
        >
          {!hideInput && (
            <>
              <NumericalInput
                fontSize="16px"
                className="token-amount-input"
                value={value}
                onUserInput={(val) => {
                  onUserInput(val)
                }}
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
              />
              {account && currency && showMaxButton && label !== 'To' && (
                <Button
                  style={{
                    fontSize: '14px',
                    fontWeight: 500,
                    fontFamily: 'mojitofont',
                    background: '#5218F40D',
                    color: '#5218F4',
                    borderRadius: '43px',
                    marginRight: '6px',
                    width: '50px',
                    height: '22px',
                  }}
                  onClick={onMax}
                  scale="sm"
                  variant="text"
                >
                  {t('COMPONENTS_31')}
                </Button>
              )}
            </>
          )}

          <CurrencySelect
            selected={!!currency}
            className="open-currency-select-button"
            onClick={() => {
              if (!disableCurrencySelect) {
                setModalOpen(true)
              }
            }}
          >
            <Aligner>
              {pair ? (
                <DoubleCurrencyLogo currency0={pair.token0} currency1={pair.token1} size={16} margin />
              ) : currency ? (
                <CurrencyLogo currency={currency} size="24px" style={{ marginRight: '8px' }} />
              ) : null}
              {pair ? (
                <Text id="pair">
                  {pair?.token0.symbol}:{pair?.token1.symbol}
                </Text>
              ) : (
                <Text
                  id="pair"
                  style={{
                    fontSize: '14px',
                    color: '#01142A',
                    fontFamily: 'mojitofont',
                    marginRight: '4px',
                  }}
                >
                  {(currency && currency.symbol && currency.symbol.length > 20
                    ? `${currency.symbol.slice(0, 4)}...${currency.symbol.slice(
                        currency.symbol.length - 5,
                        currency.symbol.length
                      )}`
                    : currency?.symbol) || t('COMPONENTS_33')}
                </Text>
              )}
              {!disableCurrencySelect && <ChevronDownIcon />}
            </Aligner>
          </CurrencySelect>
        </InputRow>
      </Container>
      {!disableCurrencySelect && onCurrencySelect && (
        <CurrencySearchModal
          showWSHM={showWSHM}
          isOpen={modalOpen}
          onDismiss={handleDismissSearch}
          onCurrencySelect={onCurrencySelect}
          selectedCurrency={currency}
          otherSelectedCurrency={otherCurrency}
          showCommonBases={showCommonBases}
        />
      )}
    </InputPanel>
  )
}
