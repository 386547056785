import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Box, Button, Flex, Input, Text } from '../../uikit'
import { useUserSlippageTolerance } from 'state/user/hooks'
import { useResponsive } from '../../hooks/useResponse'
import { useTranslation } from 'react-i18next'

const MAX_SLIPPAGE = 5000
const RISKY_SLIPPAGE_LOW = 50
const RISKY_SLIPPAGE_HIGH = 500

const StyleInput = styled(Input)`
  &:focus {
    // box-shadow: ${({ theme }) => theme.shadows.focus};
    border: ${({ theme }) => `3px solid ${theme.colors.primary}`};
  }
`

const Option = styled.div`
  padding: 0 4px;
`

const Options = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;

  ${Option}:first-child {
    padding-left: 0;
  }

  ${Option}:last-child {
    padding-right: 0;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    flex-direction: row;
  }
`

const predefinedValues = [
  { label: '0.1%', value: 0.1 },
  { label: '0.5%', value: 0.5 },
  { label: '1%', value: 1 },
]

type SlippageToleranceSettingsModalProps = {
  translateString: (translationId: number, fallback: string) => string
}

const SlippageToleranceSettings = ({ translateString }: SlippageToleranceSettingsModalProps) => {
  const { isMobile } = useResponsive()
  const { t } = useTranslation()
  const [userSlippageTolerance, setUserslippageTolerance] = useUserSlippageTolerance()
  const [value, setValue] = useState(userSlippageTolerance / 100)
  const [error, setError] = useState<string | null>(null)
  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = evt.target
    setValue(parseFloat(inputValue))
  }

  // Updates local storage if value is valid
  useEffect(() => {
    try {
      const rawValue = value * 100
      if (!Number.isNaN(rawValue) && rawValue > 0 && rawValue < MAX_SLIPPAGE) {
        setUserslippageTolerance(rawValue)
        setError(null)
      } else {
        setError(t('COMPONENTS_53'))
      }
    } catch {
      setError(t('COMPONENTS_53'))
    }
  }, [value, setError, setUserslippageTolerance, translateString, t])

  // Notify user if slippage is risky
  useEffect(() => {
    if (userSlippageTolerance < RISKY_SLIPPAGE_LOW) {
      setError(t('COMPONENTS_205'))
    } else if (userSlippageTolerance > RISKY_SLIPPAGE_HIGH) {
      setError(t('COMPONENTS_206'))
    }
  }, [userSlippageTolerance, setError, translateString, t])

  return (
    <Box mb="0px">
      <Flex alignItems="center" mb="8px">
        <Text>{t('COMPONENTS_57')}</Text>
        {/* <QuestionHelper size={16} text={t('COMPONENTS_56')} /> */}
      </Flex>
      <Options>
        <Flex
          mb={['8px', '8px', 0]}
          mr={[0, 0, '8px']}
          style={{ alignItems: isMobile ? 'flex-start' : 'flex-start', width: '100%' }}
        >
          {predefinedValues.map(({ label, value: predefinedValue }) => {
            const handleClick = () => setValue(predefinedValue)

            return (
              <Option key={predefinedValue}>
                <Button
                  // style={{ borderRadius: '4px', border: `2px solid ${theme.colors.primary}` }}
                  style={{
                    borderRadius: '8px',
                    boxShadow: 'none',
                    height: isMobile ? '36px' : '48px',
                    width: isMobile ? '80px' : '78px',
                    background: value === predefinedValue ? '#FF3093' : '#F5F5F5',
                  }}
                  onClick={handleClick}
                  scale="sm"
                >
                  <Text color={value === predefinedValue ? '#fff' : '#01142A'}>{label}</Text>
                </Button>
              </Option>
            )
          })}
        </Flex>
        <Flex alignItems="center">
          <Option>
            <StyleInput
              style={{
                borderRadius: '8px',
                background: '#fff',
                height: isMobile ? '36px' : '42px',
                border: '1px solid #5218F4',
              }}
              type="number"
              step={0.1}
              min={0.1}
              placeholder="5%"
              value={value}
              onChange={handleChange}
              isWarning={error !== null}
            />
          </Option>
          <Option>
            <Text fontSize="14px">%</Text>
          </Option>
        </Flex>
      </Options>
      {error && (
        <Text mt="8px" color="failure">
          {error}
        </Text>
      )}
    </Box>
  )
}

export default SlippageToleranceSettings
