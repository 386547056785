import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path d="M3.22691 5.35664L8.00419 10.1341L12.7773 5.361" stroke="#494E67" strokeWidth="1.7" />
    </Svg>
  )
}

export default Icon
