import React from 'react'
import { Text } from '../../uikit'
import { ChainId, Currency, currencyEquals, ETHER, Token } from 'shardex-sdk'
import styled from 'styled-components'

import { SUGGESTED_BASES } from '../../constants'
import { AutoColumn } from '../Column'
import QuestionHelper from '../QuestionHelper'
import { AutoRow } from '../Row'
import CurrencyLogo from '../CurrencyLogo'
import { useTranslation } from 'react-i18next'

const BaseWrapper = styled.div<{ disable?: boolean }>`
  display: flex;
  padding: 6px;
  border-radius: 8px;
  align-items: center;
  :hover {
    cursor: ${({ disable }) => !disable && 'pointer'};
    background-color: ${({ theme, disable }) => !disable && theme.colors.backgroundHover};
  }

  background-color: ${({ theme, disable }) => disable && theme.colors.tertiary};
  opacity: ${({ disable }) => disable && '0.4'};
`

const BoxWrapper = styled(AutoColumn)`
  border: 1px solid ${({ theme }) => theme.colors.borderDark};
  border-radius: 12px;
  padding: 18px 22px;
  margin-bottom: 5px;
`

export default function CommonBases({
  chainId,
  onSelect,
  selectedCurrency,
}: {
  chainId?: ChainId
  selectedCurrency?: Currency | null
  onSelect: (currency: Currency) => void
}) {
  const { t } = useTranslation()
  return (
    <BoxWrapper gap="md">
      <AutoRow>
        <Text fontSize="14px" fontWeight={500}>
          {t('COMPONENTS_92')}
        </Text>
        <QuestionHelper text={t('COMPONENTS_164')} infoStyles={{ width: '16px', height: '16px' }} />
      </AutoRow>
      <AutoRow gap="4px">
        <BaseWrapper
          onClick={() => {
            if (!selectedCurrency || !currencyEquals(selectedCurrency, ETHER)) {
              onSelect(ETHER)
            }
          }}
          disable={selectedCurrency === ETHER}
        >
          <CurrencyLogo currency={ETHER} style={{ marginRight: 8 }} />
          <Text fontSize="14px">{ETHER.name}</Text>
        </BaseWrapper>
        {(chainId ? SUGGESTED_BASES[chainId] : []).map((token: Token) => {
          const selected = selectedCurrency instanceof Token && selectedCurrency.address === token.address
          return (
            <BaseWrapper onClick={() => !selected && onSelect(token)} disable={selected} key={token.address}>
              <CurrencyLogo currency={token} style={{ marginRight: 8 }} />
              <Text fontSize="14px">{token.symbol}</Text>
            </BaseWrapper>
          )
        })}
      </AutoRow>
    </BoxWrapper>
  )
}
