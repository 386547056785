import React, { CSSProperties, useCallback, useState } from 'react'
import styled from 'styled-components'
import { Placement } from 'uikit'
import Tooltip from '../Tooltip'

const QuestionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.2rem;
  border: none;
  background: none;
  outline: none;
  cursor: default;
  border-radius: 36px;
  // background-color: ${({ theme }) => theme.colors.invertedContrast};
  // color: ${({ theme }) => theme.colors.textSubtle};

  :hover,
  :focus {
    // opacity: 0.7;
    color: ${({ theme }) => theme.colors.textSubtle};
  }
`

const StyledImgInfo = styled.img`
  cursor: pointer;
  width: 24px;
  height: 24px;
`

export default function QuestionHelper({
  text,
  placement,
  styles,
  infoStyles,
}: {
  text: any
  color?: string
  placement?: Placement
  size?: number
  styles?: CSSProperties
  infoStyles?: CSSProperties
}) {
  const [show, setShow] = useState<boolean>(false)

  const open = useCallback(() => setShow(true), [setShow])
  const close = useCallback(() => setShow(false), [setShow])

  return (
    <span style={{ marginLeft: 4, ...styles }}>
      <Tooltip text={text} show={show} placement={placement ?? 'top'}>
        <QuestionWrapper onClick={open} onMouseEnter={open} onMouseLeave={close}>
          <StyledImgInfo src={require('assets/images/icon/info.svg').default} style={{ ...infoStyles }} />
        </QuestionWrapper>
      </Tooltip>
    </span>
  )
}
