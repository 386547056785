import React from 'react'
import Text from '../Text/Text'
import { StyledInput } from './styles'
import { TextfieldProps } from './types'
import { Flex } from '../Box'
import { useResponsive } from 'hooks/useResponse'

const Textfield: React.FC<TextfieldProps> = ({
  label,
  value,
  placeholder,
  onUserInput,
  inputProps,
  inputStyles,
  onFocus = () => {},
}) => {
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onUserInput(e.target.value)
  }
  const { isMobile } = useResponsive()

  return (
    <Flex justifyContent="space-between" alignItems="center" style={{ height: '30px' }}>
      <StyledInput
        value={value}
        onChange={handleOnChange}
        placeholder={placeholder}
        onFocus={onFocus}
        style={inputStyles}
        {...inputProps}
      />
      <Text color="textRemark" fontSize={isMobile ? '16px' : '20px'}>
        {label}
      </Text>
    </Flex>
  )
}

export default Textfield
