import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 16 16" {...props}>
      <path
        d="M10.6427 3.22691L5.86519 8.00419L10.6383 12.7773"
        fill="none"
        stroke={props.color}
        strokeOpacity="0.6"
        strokeWidth="1.5"
      />
    </Svg>
  )
}

export default Icon
