import React, { useState, useRef, useEffect } from 'react'
import { useWeb3React } from '@web3-react/core'
import { Button, Text } from 'uikit'
import BigNumber from 'bignumber.js'
import { FarmWithStakedValue } from 'pages/Farms/components/FarmCard/FarmCard'
import { getBalanceNumber } from 'utils/formatBalance'
import { useHarvest } from 'hooks/useHarvest'
import { useProjectTokenUSDT } from 'state/hooks'
import { useCountUp } from 'react-countup'
import Column from 'components/Column'
import tokens from 'constants/tokens'
import { useTokenUsdtPrice } from 'utils/useTokenUsdtPrice'
import { getAddress } from 'utils/addressHelpers'

import { ActionContainer, ActionTitles, ActionContent, Earned, Staked } from './styles'
import styled from 'styled-components'
import useElipisis from 'hooks/useElipisis'
import { useTranslation } from 'react-i18next'

const TableFarmConfirmButton = styled(Button)`
  border-radius: 30px;
  background: ${({ disabled }) => {
    if (disabled) {
      return '#C9C9C9'
    }
    return 'linear-gradient(to right, #14d89e, #0bc5d9)'
  }};
  color: #fff;
  height: 42px;
  width: 120px;
`

const HarvestAction: React.FunctionComponent<FarmWithStakedValue> = ({ pid, userData, doubleDigToken }) => {
  const { account } = useWeb3React()
  const { t } = useTranslation()
  const earningsBigNumber = userData && account ? new BigNumber(userData.earnings) : null
  const cakePrice = useProjectTokenUSDT()
  let earnings: any = null
  let earningsBusd = 0
  let displayBalance = '-'

  const doubleDigBigNumber = userData && account ? new BigNumber(userData?.doubleDigEarnings ?? 0) : null
  const rewardTokenPrice = useTokenUsdtPrice(getAddress(doubleDigToken?.address ?? tokens.usdt.address)) ?? 1
  let doubleEarnings: any = null
  let doubleEarningsUsd = 0
  let doubleEarningsDisplayBalance = '-'

  if (earningsBigNumber) {
    earnings = getBalanceNumber(earningsBigNumber)
    earningsBusd = new BigNumber(earnings).multipliedBy(cakePrice).toNumber()
    displayBalance = earnings.toLocaleString()
  }

  if (doubleDigBigNumber) {
    doubleEarnings = getBalanceNumber(doubleDigBigNumber)
    doubleEarningsUsd = new BigNumber(doubleEarnings).multipliedBy(rewardTokenPrice).toNumber()
    doubleEarningsDisplayBalance = doubleEarningsUsd.toLocaleString()
  }

  const [pendingTx, setPendingTx] = useState(false)
  const { onReward } = useHarvest(pid)
  const elipisis = useElipisis()

  const { countUp, update } = useCountUp({
    start: 0,
    end: earningsBusd,
    duration: 1,
    separator: ',',
    decimals: 3,
  } as any) as any

  const updateValue = useRef(update)

  useEffect(() => {
    updateValue.current(earningsBusd)
  }, [earningsBusd, updateValue])

  return (
    <ActionContainer>
      <ActionTitles>
        <Text fontSize="12px" color="textSubtle" mb="-5px">
          {t('FARM_46')}
        </Text>
      </ActionTitles>
      <ActionContent>
        <Column>
          <div>
            <Earned>{displayBalance}</Earned>
            {countUp && (
              <Staked style={{ marginTop: '4px', fontSize: '14px' }}>≈ ${countUp === '0.000' ? '-' : countUp}</Staked>
            )}
          </div>
          {doubleDigToken ? (
            <div>
              <Text fontSize="12px" mt="20px" mb="2px" color="textSubtle">
                {doubleDigToken.symbol} {t('FARM_47')}
              </Text>
              <Earned>{doubleEarnings?.toFixed(3, 1) ?? 0}</Earned>
              {doubleEarningsDisplayBalance && (
                <Staked style={{ marginTop: '4px', fontSize: '14px' }}>≈ ${doubleEarningsDisplayBalance}</Staked>
              )}
            </div>
          ) : null}
        </Column>
        <TableFarmConfirmButton
          disabled={!earnings || pendingTx || !account}
          onClick={async () => {
            setPendingTx(true)
            try {
              await onReward()
            } finally {
              setPendingTx(false)
            }
          }}
          ml="4px"
        >
          {t('FARM_48')}
          {pendingTx && <span style={{ display: 'inline-block', width: '20px', textAlign: 'left' }}> {elipisis}</span>}
        </TableFarmConfirmButton>
      </ActionContent>
    </ActionContainer>
  )
}

export default HarvestAction
