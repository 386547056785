import React, { useContext } from 'react'
import { Menu as UikitMenu } from '../../uikit'
import { useWeb3React } from '@web3-react/core'
import { allLanguages } from 'constants/localisation/languageCodes'
import { LanguageContext } from 'hooks/LanguageContext'
import useTheme from 'hooks/useTheme'
import useGetLocalProfile from 'hooks/useGetLocalProfile'
import useAuth from 'hooks/useAuth'
import links from './config'
import { isTransactionRecent, useAllTransactions } from 'state/transactions/hooks'
import { TransactionDetails } from 'state/transactions/reducer'
import useProjectTokenUsdtPrice from '../../utils/useTokenUsdtPrice'

const newTransactionsFirst = (a: TransactionDetails, b: TransactionDetails) => b.addedTime - a.addedTime

const Menu: React.FC = (props) => {
  const { account } = useWeb3React()
  const { login, logout } = useAuth()
  const { selectedLanguage, setSelectedLanguage } = useContext(LanguageContext)
  const { isDark, toggleTheme } = useTheme()
  const cakeBusdPrice = useProjectTokenUsdtPrice()
  const profile = useGetLocalProfile()
  const transactions = useAllTransactions()

  const sortedRecentTransactions = React.useMemo(() => {
    if (!account) {
      return []
    }
    const txs = Object.values(transactions) as any
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst)
  }, [account, transactions])

  const pendingCount = React.useMemo(() => {
    if (!account) {
      return 0
    }
    const hashs = Reflect.ownKeys(sortedRecentTransactions ?? {})

    if (!sortedRecentTransactions.length) {
      return 0
    }
    let count = 0
    for (let i = 0; i < hashs.length; i++) {
      if (i > 6) {
        return count
      }
      if (!sortedRecentTransactions[hashs[i] as string]?.receipt) {
        count++
      } else {
        return count
      }
    }
    return count
  }, [sortedRecentTransactions, account])
  return (
    <UikitMenu
      links={links}
      account={account as string}
      login={login}
      logout={logout}
      isDark={isDark}
      toggleTheme={toggleTheme}
      currentLang={selectedLanguage?.code || ''}
      langs={allLanguages}
      setLang={setSelectedLanguage}
      cakePriceUsd={cakeBusdPrice}
      profile={profile}
      pendingCount={pendingCount}
      {...props}
    />
  )
}

export default Menu
