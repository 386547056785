import styled from 'styled-components'
import Box from '../Box/Box'
import { TransparentInput } from '../Input/Input'
import { BalanceInputProps } from './types'
import Text from '../Text/Text'
import IconButton from '../Button/IconButton'

export const StyledBalanceInput = styled(Box)<{ isWarning: BalanceInputProps['isWarning'] }>`
  background-color: white;
  border: 1px solid ${({ isWarning }) => (isWarning ? '#FF5B65' : '#C9C9C9')};
  border-radius: 8px;
  padding: 8px 16px;
`

export const StyledInput = styled(TransparentInput)<{ textAlign?: string }>`
  background: transparent;
  border-radius: 0;
  border: none;
  box-shadow: none;
  padding-left: 0;
  padding-right: 0;
  text-align: right;

  ::placeholder {
    color: ${({ theme }) => theme.colors.textSubtle};
  }

  &:focus:not(:disabled) {
    box-shadow: none;
  }
`

export const UnitContainer = styled(Text)`
  margin-left: 4px;
  text-align: right;
  color: ${({ theme }) => theme.colors.textSubtle};
  white-space: nowrap;
`

export const SwitchUnitsButton = styled(IconButton)`
  width: 16px;
`
