import React from 'react'
import styled from 'styled-components'
import { Tag, Flex, Heading } from 'uikit'
import FarmDoubleLogo from 'components/DoubleLogo/FarmDoubleLogo'
import { Text } from 'uikit'

export interface ExpandableSectionProps {
  lpLabel: string
  version: number
  multiplier?: string
  isCommunityFarm?: boolean
  farmImage?: string
  tokenSymbol?: string
  endBlock?: number
}

const Wrapper = styled(Flex)`
  padding: 24px;
  padding-bottom: 0px;
  svg {
    margin-right: 4px;
  }
`

const MultiplierTag = styled(Tag)`
  margin-left: 4px;
  background: #d4f8ee;
  color: #ff3093;
  border-radius: 13px;
  height: 25px;
`

const TimeLimitedTag = styled.div`
  background: rgba(10, 196, 221, 0.15);
  border-radius: 13px;
  height: 25px;
  width: auto;
  padding: 0 8px;
  text-align: center;
  line-height: 25px;
  font-size: 16px;
  color: #0ac4dd;
`

const CardHeading: React.FC<ExpandableSectionProps> = ({
  lpLabel,
  version,
  multiplier,
  farmImage,
  endBlock,
}) => {
  // debugger
  const [currency0, currency1] = farmImage?.split('/') as string[]
  return (
    <Wrapper justifyContent="space-between" alignItems="center" mb="12px">
      {/* <Image src={getTokenUrl(`${tokenSymbol?.toLowerCase()}`)} alt={tokenSymbol} width={32} height={32} /> */}
      <FarmDoubleLogo currency0={currency0} currency1={currency1} />
      <Flex flexDirection="column" alignItems="flex-end">
        <Heading mb="4px">
          <Text fontWeight={700} color="#01142a" fontSize="24px">
            {lpLabel.split(' ')[0]}
          </Text>
        </Heading>
        {endBlock ? <TimeLimitedTag>Time-limited</TimeLimitedTag> : null}
        <Flex justifyContent="center">
          {/* isCommunityFarm ? <CommunityTag /> : <CoreTag /> */}
          {version === 2 ? null : (
            <MultiplierTag variant="secondary">
              <Text fontWeight={700} color="#FF3093" fontSize="16px" fontFamily="mojitofont" lineHeight="19px">
                {multiplier}
              </Text>
            </MultiplierTag>
          )}
        </Flex>
      </Flex>
    </Wrapper>
  )
}

export default CardHeading
