import React from 'react'
import styled from 'styled-components'
import { HelpIcon, useTooltip } from 'uikit'
import { useTranslation } from 'react-i18next'

const ReferenceElement = styled.div`
  display: inline-block;
`

export interface MultiplierProps {
  multiplier: string
}

const MultiplierWrapper = styled.div`
  color: #01142a;
  width: 36px;
  text-align: right;
  margin-right: 14px;
  font-size: 18px;

  ${({ theme }) => theme.mediaQueries.lg} {
    text-align: left;
    margin-right: 0;
  }
`

const Container = styled.div`
  display: flex;
  align-items: center;
`

const Multiplier: React.FunctionComponent<MultiplierProps> = ({ multiplier }) => {
  const displayMultiplier = multiplier ? multiplier.toLowerCase() : '-'
  const { t } = useTranslation()
  const tooltipContent = (
    <div>
      {t('FARM_33')}
      <br />
      <br />
      {t('FARM_34')}
    </div>
  )
  const { targetRef, tooltip, tooltipVisible } = useTooltip(tooltipContent, {
    placement: 'top-end',
    trigger: 'hover',
    arrowPadding: undefined,
    tooltipPadding: undefined,
    tooltipOffset: [20, 10],
  })

  return (
    <Container>
      <MultiplierWrapper>{displayMultiplier}</MultiplierWrapper>
      <ReferenceElement ref={targetRef}>
        <HelpIcon style={{ marginTop: '4px' }} color="textSubtle" />
      </ReferenceElement>
      {tooltipVisible && tooltip}
    </Container>
  )
}

export default Multiplier
