/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import fetchMinning from './fetchMinning'
import { MinningState } from '../types'

const initialState: MinningState = { data: [], totalReward: 0, rewardPools: 0 }

export const minningSlice = createSlice({
  name: 'Minning',
  initialState,
  reducers: {
    setMinningData: (state, action) => {
      state.data = action.payload
    },
    setMinningUserReward: (state, action) => {
      state.totalReward = action.payload
    },
    setMinningUserRewardPool: (state, action) => {
      state.rewardPools = action.payload
    },
  },
})

// Actions
export const { setMinningData, setMinningUserReward, setMinningUserRewardPool } = minningSlice.actions

// Thunks
export const fetchMinningDataAsync = (account?: string, library?: any) => async (dispatch) => {
  const minning = await fetchMinning(account, library)
  const sortList = minning.sort((prev, next) => prev.pid - next.pid)
  let user = 0
  let pools = 0
  for (let i = 0; i < sortList.length; i++) {
    user += Number(sortList[i].userReward)
    if (Number(sortList[i].userReward) > 0.0001) {
      pools += 1
    }
  }
  dispatch(setMinningUserReward(user))
  dispatch(setMinningData(sortList))
  dispatch(setMinningUserRewardPool(pools))
}

export default minningSlice.reducer
