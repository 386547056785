import { PoolCategory } from 'constants/types'
import web3NoAccount from 'utils/web3'
import Web3 from 'web3'
import { AbiItem } from 'web3-utils'

// Addresses
import {
  getAddress,
  getAirdropAddress,
  getLuckyDrawResultAddress,
  getMasterChefAddress,
  getMasterChefV2Address,
  getMinningAddress,
  getMojitoCattleAddress,
  getMojitoCattleFactoryAddress,
  getNftMarketAddress,
  getPointCenterIfoAddress,
  getProfileAddress,
  getReferralAddress,
  getRollingAddress,
  getSecondAirdropAddress,
  getTradeRobotNftAddress,
  getVaultAddress,
  getVaultV2Address,
} from 'utils/addressHelpers'

// ABI
import krc20Abi from 'constants/abis/erc20.json'
import krc721Abi from 'constants/abis/erc721.json'
import krc721CollectionAbi from 'constants/abis/erc721collection.json'
import ifoV2Abi from 'constants/abis/ifoV2.json'
import ifoV2VesterAbi from 'constants/abis/ifoVester.json'
import ifoV2WhiteListAbi from 'constants/abis/ifoWhiteLister.json'
import lpTokenAbi from 'constants/abis/lpToken.json'
import luckyDrawResultAbi from 'constants/abis/luckyDrawResult.json'
import masterChef from 'constants/abis/masterchef.json'
import masterChefV2 from 'constants/abis/masterchefV2.json'
import merkleDistributor from 'constants/abis/merkle-distributor.json'
import minning from 'constants/abis/minning.json'
import projectTokenABI from 'constants/abis/projectToken.json'
import mojitoCattleAbi from 'constants/abis/mojitoCattle.json'
import mojitoCattleFactoryAbi from 'constants/abis/mojitoCattleFactory.json'
import nftAbi from 'constants/abis/nft.json'
import nftMarketAbi from 'constants/abis/nftMarket.json'
import pointCenterIfo from 'constants/abis/pointCenterIfo.json'
import profileABI from 'constants/abis/profile.json'
import referral from 'constants/abis/referral.json'
import RollingAbi from 'constants/abis/rolling.json'
import sousChef from 'constants/abis/sousChef.json'
import sousChefBnb from 'constants/abis/sousChefBnb.json'
import vaultChef from 'constants/abis/vault.json'

import { JsonRpcProvider } from '@ethersproject/providers'
import { Contract } from 'ethers'
import { getProjectTokenAddress } from './addressHelpers'
import { poolsV2 } from '../constants/pools'

const getContract = (abi: any, address: string, web3?: Web3) => {
  const _web3 = web3 ?? web3NoAccount
  return new _web3.eth.Contract(abi as unknown as AbiItem, address)
}

export const getAirdropContract = (library: any) => {
  const signer = library?.getSigner()
  const provider = signer ?? new JsonRpcProvider(process.env.REACT_APP_NETWORK_URL).getSigner()
  return new Contract(getAirdropAddress(), merkleDistributor, provider)
}

export const getTwoAirdropContract = (library: any) => {
  const signer = library?.getSigner()
  const provider = signer ?? new JsonRpcProvider(process.env.REACT_APP_NETWORK_URL).getSigner()
  return new Contract(getSecondAirdropAddress(), merkleDistributor, provider)
}

export const getMinningContract = (library: any) => {
  const signer = library?.getSigner()
  const provider = signer ?? new JsonRpcProvider(process.env.REACT_APP_NETWORK_URL).getSigner()
  return new Contract(getMinningAddress(), minning, provider)
}

export const getReferralContract = (library: any, index: number) => {
  const signer = library?.getSigner()
  const provider = signer ?? new JsonRpcProvider(process.env.REACT_APP_NETWORK_URL).getSigner()
  return new Contract(getReferralAddress(index), referral, provider)
}

export const getKrc20Contract = (address: string, web3?: Web3) => {
  return getContract(krc20Abi, address, web3)
}

export const getKrc20Contract2 = (address: string) => {
  return new Contract(address, krc20Abi, new JsonRpcProvider(process.env.REACT_APP_NETWORK_URL))
}

export const getLpContract = (address: string, web3?: Web3) => {
  return getContract(lpTokenAbi, address, web3)
}

export const getProjectTokenContract = (web3?: Web3) => {
  return getContract(projectTokenABI, getProjectTokenAddress(), web3)
}

export const getMasterchefContract = (web3?: Web3) => {
  return getContract(masterChef, getMasterChefAddress(), web3)
}

export const getMasterchefV2Contract = (web3?: Web3) => {
  return getContract(masterChefV2, getMasterChefV2Address(), web3)
}

export const getVaultContract = (web3?: Web3) => {
  return getContract(vaultChef, getVaultAddress(), web3)
}

export const getVaultV2Contract = (web3?: Web3) => {
  return getContract(vaultChef, getVaultV2Address(), web3)
}

export const getSouschefContract = (id: number, web3?: Web3) => {
  if (!id) return null
  // add auto pool function
  const config = poolsV2.find((pool) => pool.sousId === id) as any
  const abi = config?.poolCategory === PoolCategory.KCS ? sousChefBnb : sousChef
  return getContract(abi, getAddress(config.contractAddress), web3)
}

export const getRollingContract = (web3?: Web3) => {
  return getContract(RollingAbi, getRollingAddress(), web3)
}

export const getProfileContract = (library?: any) => {
  const signer = library?.getSigner()
  const simpleRpcProvider = new JsonRpcProvider(process.env.REACT_APP_NETWORK_URL)
  const provider = signer ?? simpleRpcProvider
  return new Contract(getProfileAddress(), profileABI, provider)
}

export const getMojitoCattleContract = (library?: any) => {
  const signer = library?.getSigner()
  const provider = signer ?? new JsonRpcProvider(process.env.REACT_APP_NETWORK_URL)
  return new Contract(getMojitoCattleAddress(), mojitoCattleAbi, provider)
}

export const getMojitoFactoryContract = (library?: any) => {
  const signer = library?.getSigner()
  const simpleRpcProvider = new JsonRpcProvider(process.env.REACT_APP_NETWORK_URL)
  const provider = signer ?? simpleRpcProvider
  return new Contract(getMojitoCattleFactoryAddress(), mojitoCattleFactoryAbi, provider)
}

export const getPointCenterIfoContract = (library?: any) => {
  const signer = library?.getSigner()
  const provider = signer ?? new JsonRpcProvider(process.env.REACT_APP_NETWORK_URL).getSigner()
  return new Contract(getPointCenterIfoAddress(), pointCenterIfo, provider)
}

export const getIfoV2Contract = (address: string, library?: any) => {
  const signer = library?.getSigner()
  const provider = signer ?? new JsonRpcProvider(process.env.REACT_APP_NETWORK_URL).getSigner()
  return new Contract(address, ifoV2Abi, provider)
}

export const getIfoV2WhiteListContract = (address: string, library?: any) => {
  const signer = library?.getSigner()
  const provider = signer ?? new JsonRpcProvider(process.env.REACT_APP_NETWORK_URL).getSigner()
  return new Contract(address, ifoV2WhiteListAbi, provider)
}

export const getIfoV2VesterContract = (address: string, library?: any) => {
  const signer = library?.getSigner()
  const provider = signer ?? new JsonRpcProvider(process.env.REACT_APP_NETWORK_URL).getSigner()
  return new Contract(address, ifoV2VesterAbi, provider)
}

export const getErc721Contract = (address: string, library?: any) => {
  const signer = library?.getSigner()
  const provider = signer ?? new JsonRpcProvider(process.env.REACT_APP_NETWORK_URL).getSigner()
  return new Contract(address, krc721Abi, provider)
}

export const getTradeRobotNftContract = (library?: any) => {
  const signer = library?.getSigner()
  const provider = signer ?? new JsonRpcProvider(process.env.REACT_APP_NETWORK_URL)
  return new Contract(getTradeRobotNftAddress(), nftAbi, provider)
}

export const getNftMarketContract = (library?: any) => {
  const signer = library?.getSigner()
  const provider = signer ?? new JsonRpcProvider(process.env.REACT_APP_NETWORK_URL)
  return new Contract(getNftMarketAddress(), nftMarketAbi, provider)
}

export const getErc721CollectionContract = (library?: any, address?: string) => {
  const signer = library?.getSigner()
  const provider = signer ?? new JsonRpcProvider(process.env.REACT_APP_NETWORK_URL)
  return new Contract(address, krc721CollectionAbi, provider)
}

export const getLuckyDrawResultContract = (library?: any) => {
  const signer = library?.getSigner()
  const provider = signer ?? new JsonRpcProvider(process.env.REACT_APP_NETWORK_URL)
  return new Contract(getLuckyDrawResultAddress(), luckyDrawResultAbi, provider)
}
