import React from 'react'
import { SvgProps } from '../types'
import styled from 'styled-components'

const SyncAltSource = require('../../../../assets/images/menu/altIcon.svg').default

const SyncAlt = styled.img`
  width: 16px;
  height: 16px;
`

const Icon: React.FC<SvgProps> = () => {
  return <SyncAlt src={SyncAltSource} />
}

export default Icon
