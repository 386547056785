import { ChainId, JSBI, Percent, Token, WETH, ROUTER_ADDRESS } from 'shardex-sdk'
import BigNumber from 'bignumber.js'
import tokens from './tokens'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

export const BUNDLE_ID = '1'
dayjs.extend(utc)

export const SDT_PER_BLOCK_INIT = new BigNumber(3)

export const timeframeOptions = {
  WEEK: '1 week',
  MONTH: '1 month',
  // THREE_MONTHS: '3 months',
  // YEAR: '1 year',
  HALF_YEAR: '6 months',
  ALL_TIME: 'All time',
}

export const RA = ROUTER_ADDRESS[Number(process.env.REACT_APP_CHAIN_ID)]

// a list of tokens by chain
type ChainTokenList = {
  readonly [chainId in ChainId]: Token[]
}

export const USDT = {
  [ChainId.TESTNET]: new Token(ChainId.TESTNET, tokens.usdt.address[ChainId.TESTNET], 18, 'USDT', 'USDT'),
  [ChainId.MAINNET]: new Token(ChainId.MAINNET, tokens.usdt.address[ChainId.MAINNET], 18, 'USDT', 'USDT'),
}


// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  [ChainId.TESTNET]: [WETH[ChainId.TESTNET]],
  [ChainId.MAINNET]: [],
}

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 */

export const CUSTOM_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.MAINNET]: {},
  // [ChainId.TESTNET]: {
  //   '0xAe2933C2aD27984983f9396e25218a70fC791812': [SDT]
  // },
}

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {
  [ChainId.TESTNET]: [USDT[ChainId.TESTNET]],
  [ChainId.MAINNET]: [USDT[ChainId.MAINNET]],
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  [ChainId.TESTNET]: [WETH[ChainId.TESTNET], USDT[ChainId.TESTNET]],
  [ChainId.MAINNET]: [WETH[ChainId.MAINNET], USDT[ChainId.MAINNET]],
}

export const PINNED_PAIRS: { readonly [chainId in ChainId]?: [Token, Token][] } = {
  [ChainId.TESTNET]: [[USDT[ChainId.TESTNET], WETH[ChainId.TESTNET]]],
  [ChainId.MAINNET]: [[USDT[ChainId.MAINNET], WETH[ChainId.MAINNET]]],
}

export const NetworkContextName = 'NETWORK'

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 80
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20

// one basis point
export const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10000))
export const BIPS_BASE = JSBI.BigInt(10000)
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

// used to ensure the user doesn't send so much ETH so they end up with <.01
export const MIN_ETH: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16)) // .01 ETH

export const CHAIN_BLOCK_TIME = 3

export function calculateProjectTokenPerBlockByTimestamp() {
  const startTimestamp = new Date('2022/11/10 11:38:49').getTime()
  const nowTimestamp = new Date().getTime()
  const allTimestamp = new BigNumber(nowTimestamp).minus(startTimestamp).toNumber()
  const days180 = 60 * 60 * 24 * 1000 * 180

  if (days180 > allTimestamp) {
    return SDT_PER_BLOCK_INIT
  }
  const n = Number(new BigNumber(allTimestamp).div(days180).toFixed(0, 1))
  let chainPerBlock = CHAIN_BLOCK_TIME
  for (let i = 0; i < n; i++) {
    chainPerBlock -= chainPerBlock * 0.2
  }
  return new BigNumber(chainPerBlock)
}

// console.log(`per block${calculateSDTPerBlockByTimestamp().toNumber()} SDT`)

export const MASTERCHEF_PER_BLOCK = calculateProjectTokenPerBlockByTimestamp().multipliedBy(0.9)
export const MINNING_PER_BLOCK = calculateProjectTokenPerBlockByTimestamp().multipliedBy(0.1)

export const BLOCKS_PER_YEAR = new BigNumber((60 / CHAIN_BLOCK_TIME) * 60 * 24 * 365) // 10512000

export const BASE_URL = 'https://mojitoswap.finance'
export const BASE_EXCHANGE_URL = process.env.REACT_APP_OFFICIAL_URL
export const BASE_ADD_LIQUIDITY_URL = `/add`
export const BASE_LIQUIDITY_POOL_URL = `/pool`
export const LOTTERY_MAX_NUMBER_OF_TICKETS = 50
export const LOTTERY_TICKET_PRICE = 1
export const IPFS_GATEWAY = 'https://ipfs.io/ipfs'
export const DEFAULT_TOKEN_DECIMAL = new BigNumber(10).pow(18)
