import { useCurrency } from 'hooks/Tokens'
import { useTradeExactIn } from 'hooks/Trades'
import { tryParseAmount } from 'state/swap/hooks'
import tokens from 'constants/tokens'

const useProjectTokenUsdtPrice = () => {
  const adddress = tokens.sdt.address[process.env.REACT_APP_CHAIN_ID as string]
  const usdtAddress = tokens.usdt.address[process.env.REACT_APP_CHAIN_ID as string]
  const inputCurrency = useCurrency(adddress)
  const outputCurrency = useCurrency(usdtAddress)
  const parsedAmount = tryParseAmount('1', inputCurrency ?? undefined)
  const bestTradeExactIn = useTradeExactIn(parsedAmount, outputCurrency ?? undefined)
  const price = bestTradeExactIn?.executionPrice.toSignificant(6)
  return price ? parseFloat(price) : 0
}

export const useTokenUsdtPrice = (tokenAddress) => {
  const usdtAddress = tokens.usdt.address[process.env.REACT_APP_CHAIN_ID as string]
  const inputCurrency = useCurrency(tokenAddress)
  const outputCurrency = useCurrency(usdtAddress)
  const parsedAmount = tryParseAmount('1', inputCurrency ?? undefined)
  const bestTradeExactIn = useTradeExactIn(parsedAmount, outputCurrency ?? undefined)
  const p = bestTradeExactIn?.executionPrice.toSignificant(6)
  return parseFloat(p) ?? 0
}

export default useProjectTokenUsdtPrice
