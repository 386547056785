import React from 'react'
import { useTheme } from 'styled-components'
import Heading from '../../components/Heading/Heading'
import getThemeValue from '../../util/getThemeValue'
import {
  ModalBody,
  ModalHeader,
  ModalTitle,
  ModalContainer,
  ModalCloseButton,
  ModalBackButton,
  ModalBackButtonGrey,
  ModalNoHeaderCloseButton,
} from './styles'
import { ModalProps } from './types'
import { useResponsive } from '../../../hooks/useResponse'

const Modal: React.FC<ModalProps> = ({
  title,
  onDismiss,
  onBack,
  children,
  hideCloseButton = false,
  bodyPadding = '24px',
  showHeader = true,
  headerBackground = 'transparent',
  minWidth = '320px',
  isGreyBack = false,
  ...props
}) => {
  const theme = useTheme()

  const { isMobile } = useResponsive()

  return (
    <ModalContainer minWidth={minWidth} {...props}>
      {showHeader && (
        <ModalHeader background={getThemeValue(`colors.${headerBackground}`, headerBackground)(theme)}>
          <ModalTitle>
            {onBack && !isGreyBack && <ModalBackButton onBack={onBack} />}
            {onBack && isGreyBack && <ModalBackButtonGrey onBack={onBack} />}
            <Heading style={{ fontWeight: 'bold', fontSize: isMobile ? '16px' : '18px' }}>{title}</Heading>
          </ModalTitle>
          {!hideCloseButton && <ModalCloseButton onDismiss={onDismiss} />}
        </ModalHeader>
      )}

      {!showHeader && <ModalNoHeaderCloseButton onDismiss={onDismiss} />}
      <ModalBody p={bodyPadding}>{children}</ModalBody>
    </ModalContainer>
  )
}

export default Modal
