import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.colors.background};

    img {
      height: auto;
      max-width: 100%;
    }
    -webkit-tap-highlight-color: rgba(0,0,0,0) !important;
  }

  ul {
    list-style: none; 
  }

  li {
    display: flex;
    align-items: center;
  }

  li::before {
    content: "•";
    color: ${({ theme }) => theme.colors.primary};
    margin-right: 8px;
  }
  
  .rc-tabs-nav-measure-ping-right::after, .rc-tabs-nav-wrap-ping-right::after{
    border: none;
  }

  .rc-tabs-nav-more{
    border: none;
    background: white;
    font-size: 16px;
  }

  a {
    :active,:hover, :focus {
      text-decoration: none !important;
      color: inherit;
    }
  }
`

export default GlobalStyle
