import { useCallback } from 'react'
import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core'
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from '@web3-react/injected-connector'
import {
  UserRejectedRequestError as UserRejectedRequestErrorWalletConnect,
  WalletConnectConnector,
} from '@web3-react/walletconnect-connector'
import { connectorLocalStorageKey, ConnectorNames } from '../uikit'
import useToast from 'hooks/useToast'

import { injected, connectorsByName } from '../connectors/index'
import { switchNetwork } from 'utils/wallet'

const useAuth = () => {
  const chainId = process.env.REACT_APP_CHAIN_ID
  const { activate, deactivate } = useWeb3React()
  const { toastError } = useToast()

  const login = useCallback(
    (connectorID: ConnectorNames) => {
      const connector = connectorsByName?.[connectorID] ? connectorsByName[connectorID] : injected
      if (connector) {
        activate(connector, async (error: Error) => {
          window.localStorage.removeItem(connectorLocalStorageKey)
          if (error instanceof UnsupportedChainIdError) {
            switchNetwork(chainId as any)
          } else if (error instanceof NoEthereumProviderError) {
            toastError('Provider Error', 'No provider was found')
          } else if (
            error instanceof UserRejectedRequestErrorInjected ||
            error instanceof UserRejectedRequestErrorWalletConnect
          ) {
            if (connector instanceof WalletConnectConnector) {
              const walletConnector = connector as WalletConnectConnector
              walletConnector.walletConnectProvider = null
            }
            toastError('Authorization Error', 'Please authorize to access your account')
          } else {
            toastError(error.name, error.message)
          }
        })
      } else {
        toastError("Can't find connector", 'The connector config is wrong')
      }
    },
    [activate, chainId, toastError]
  )

  return { login, logout: deactivate }
}

export default useAuth
