import { useWeb3React } from '@web3-react/core'
import BN from 'bignumber.js'
import { AprText } from 'components/Balance'
import Column from 'components/Column'
import Elipisis from 'components/Elipisis/Elipisis'
import QuestionHelper from 'components/QuestionHelper'
import RoiCalculatorModal from 'components/RoiCalculatorModal'
import Row from 'components/Row'
import UnlockButton from 'components/UnlockButton'
import { PoolCategory } from 'constants/types'
import { useSousApprove } from 'hooks/useApprove'
import { useERC20 } from 'hooks/useContract'
import { useSousHarvest } from 'hooks/useHarvest'
import { useSousStake } from 'hooks/useStake'
import { useSousUnstake } from 'hooks/useUnstake'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetApiPrice, usePoolFromPid } from 'state/hooks'
import { Pool } from 'state/types'
import styled from 'styled-components'
import { AddIcon, Box, Button, CalculateIcon, IconButton, MinusIcon, Text, useModal } from 'uikit'
import { getAddress } from 'utils/addressHelpers'
import { getApy, getPoolApr } from 'utils/apr'
import FarmDoubleLogo from '../../../components/DoubleLogo/FarmDoubleLogo'
import Card from './Card'
import CardFooter from './CardFooter'
import CardTitle from './CardTitle'
import CompoundButton from './CompoundButton'
import CompoundModal from './CompoundModal'
import DepositModal from './DepositModal'
import Divider from './Divider'
import HarvestButton from './HarvestButton'
import WithdrawModal from './WithdrawModal'
import PoolCutDown from './PoolCutDown'
import BigNumber from 'bignumber.js'

export const getBalanceNumber = (balance: BigNumber, decimals = 18) => {
  const displayBalance = new BigNumber(balance).dividedBy(new BigNumber(10).pow(decimals))
  if (displayBalance.gt(0) && displayBalance.lte(0.01)) {
    return new BigNumber(displayBalance).toNumber()
  }
  return new BigNumber(new BigNumber(displayBalance).toFixed(2, 1)).toNumber()
}

export const getDisplayBalance = (balance: BigNumber, decimals = 18) => {
  const displayBalance = new BigNumber(balance).dividedBy(new BigNumber(10).pow(decimals))
  if (displayBalance.gt(0) && displayBalance.lte(0.01)) {
    return '< 0.01'
  }
  return new BigNumber(new BigNumber(displayBalance).toFixed(2, 1)).toString(10)
}

interface HarvestProps {
  pool: Pool
  autoPoolRewardPercent: number
  finished: boolean
}

const PoolHeader = styled.div<{ finished?: boolean }>`
  background: ${({ finished }) => (finished ? '#737E8D29' : 'white')};
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 24px;
`
const PoolHeaderTitle = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: flex-start;
  height: 56px;
`

const PoolContent = styled.div`
  padding: 24px;
`

const PoolFinishedSash = styled.div`
  background-image: url(${require('../../../assets/images/pool-finished-sash.svg').default});
  background-position: top right;
  background-repeat: no-repeat;
  height: 135px;
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 1;
  width: 135px;
`

const StyledCardActions = styled.div`
  display: flex;
  justify-content: center;
  margin: 16px 0;
  width: 100%;
  box-sizing: border-box;
`

const StyledDetails = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
`

const UnlockButtonInPool = styled(UnlockButton)<{ connected: boolean }>`
  background: linear-gradient(to right, #14d89e, #0bc5d9);
  color: #fff;
  border: none;
  height: 48px;
  border-radius: 30px;
  width: ${({ connected }) => {
    if (connected) {
      return 'auto'
    }
    return '100%'
  }};
`

const ApproveButton = styled(Button)`
  background: ${({ disabled }) => {
    if (disabled) {
      return '#C9C9C9'
    }
    return 'linear-gradient(to right, #14d89e, #0bc5d9)'
  }};
  border-radius: 30px;
  height: 48px;
  color: #fff;
  box-shadow: none;
`

const PoolCard: React.FC<HarvestProps> = ({ pool, autoPoolRewardPercent, finished }) => {
  const {
    sortOrder,
    sousId,
    stakingToken,
    earningToken,
    harvest,
    poolCategory,
    totalStaked,
    startBlock,
    endBlock,
    userData,
    stakingLimit,
    withdrawFee,
  } = pool

  // Pools using native BNB behave differently than pools using a token
  const autoPool = usePoolFromPid(1)

  const isKcsPool = poolCategory === PoolCategory.KCS
  const isAutoPool = sortOrder === 1
  const isManualPool = sortOrder === 0
  const { t } = useTranslation()
  const stakingTokenContract = useERC20(stakingToken.address ? getAddress(stakingToken.address) : '')
  const { account } = useWeb3React()
  const { onApprove } = useSousApprove(stakingTokenContract, sortOrder, sousId)

  const { onStake } = useSousStake(sousId, sortOrder, isKcsPool)
  const { onUnstake } = useSousUnstake(sousId, sortOrder)
  const { onReward } = useSousHarvest(sousId, sortOrder, isKcsPool)

  // APR
  const rewardTokenPrice: any = useGetApiPrice(
    earningToken.address ? earningToken.address[process.env.REACT_APP_CHAIN_ID as any] : '0'
  )

  const stakingTokenPrice: any = useGetApiPrice(
    stakingToken.address ? stakingToken.address[process.env.REACT_APP_CHAIN_ID as any] : '0'
  )

  const getTotalStakedBalance = React.useMemo(() => {
    if (isAutoPool) {
      const autoBalance = new BN(autoPool.totalStaked ?? 0)
      return getBalanceNumber(autoBalance, stakingToken.decimals)
    }
    if (isManualPool) {
      const manualBalance = new BN(totalStaked ?? new BN(0)).minus(autoPool.totalStaked ?? 0)
      return getBalanceNumber(manualBalance, stakingToken.decimals)
    }
    return getBalanceNumber(new BN(totalStaked ?? 0), stakingToken.decimals)
  }, [isAutoPool, isManualPool, totalStaked, stakingToken.decimals, autoPool.totalStaked])

  // calc pool apr by the staked amount  / total stake amount
  const aprPercent = isManualPool || isAutoPool ? (isAutoPool ? autoPoolRewardPercent : 1 - autoPoolRewardPercent) : 1

  const apr = React.useMemo(() => {
    // debugger
    let tApr = getPoolApr(
      stakingTokenPrice,
      rewardTokenPrice,
      getTotalStakedBalance,
      parseFloat(pool.tokenPerBlock) * aprPercent
    )

    if (isAutoPool) {
      // autoPool apr = apy
      tApr = getApy(tApr, 1, 365) * 100
      tApr = new BigNumber(tApr ?? 0).toFixed(2, 1)
    }

    console.log('apr', tApr)
    return tApr
  }, [aprPercent, getTotalStakedBalance, isAutoPool, pool.tokenPerBlock, rewardTokenPrice, stakingTokenPrice])

  const [requestedApproval, setRequestedApproval] = useState(false)
  const [pendingTx, setPendingTx] = useState(false)

  const allowance = new BigNumber(userData?.allowance || 0)
  const stakingTokenBalance = new BigNumber(userData?.stakingTokenBalance || 0)

  const stakedBalance = new BigNumber(userData?.stakedBalance || 0)

  const earnings = new BigNumber(userData?.pendingReward || 0)

  // const initStakeBalance = stakedBalance && stakedBalance ? new BN(stakedBalance).minus(earnings) : new BN(0)
  const initStakeBalance = new BN(stakedBalance)

  const accountHasStakedBalance = stakedBalance?.toNumber() > 0

  const needsApproval = !accountHasStakedBalance && !allowance.toNumber() && !isKcsPool
  const isCardActive = finished && accountHasStakedBalance

  const convertedLimit = new BigNumber(stakingLimit as any).multipliedBy(
    new BigNumber(10).pow(earningToken?.decimals as any)
  )

  const [onPresentDeposit] = useModal(
    <DepositModal
      max={stakingLimit && stakingTokenBalance.isGreaterThan(convertedLimit) ? convertedLimit : stakingTokenBalance}
      onConfirm={onStake}
      tokenName={stakingLimit ? `${stakingToken.symbol} (${stakingLimit} ${t('POOL_41')})` : stakingToken.symbol}
      stakingTokenDecimals={stakingToken.decimals}
      onDismiss={() => {
        console.log('on dismiss')
      }}
    />
  )

  const [onPresentCompound] = useModal(
    <CompoundModal
      onDismiss={() => {
        console.log('on dismiss')
      }}
      earnings={earnings}
      onConfirm={onStake}
      tokenName={stakingToken.symbol}
    />
  )

  // onPresentCompound()

  const [onPresentWithdraw] = useModal(
    <WithdrawModal
      max={stakedBalance}
      onConfirm={onUnstake}
      tokenName={stakingToken.symbol}
      stakingTokenDecimals={stakingToken.decimals}
    />
  )

  const handleApprove = useCallback(async () => {
    try {
      setRequestedApproval(true)
      const txHash = await onApprove()
      // user rejected tx or didn't go thru
      if (!txHash) {
        setRequestedApproval(false)
      }
    } catch (e) {
      console.error(e)
    }
  }, [onApprove, setRequestedApproval])

  const pendingReward = React.useMemo(() => {
    // console.log('userData?.pendingReward', userData)
    return (
      new BN(userData?.pendingReward ?? 0)
        .div(10 ** (earningToken.decimals ?? 0))
        .toFixed(4, 1)
        .toString() ?? 0
    )
  }, [userData, earningToken])

  const stakedTokenBalance = React.useMemo(() => {
    // console.log('userData?.stakedBalance', userData?.stakedBalance)
    return (
      new BN(userData?.stakedBalance ?? 0)
        .div(10 ** (earningToken.decimals ?? 0))
        .toFixed(2, 1)
        .toString() ?? 0
    )
  }, [userData, earningToken])

  const earnedTokenprice = useGetApiPrice(getAddress(earningToken.address as any))

  const pendingRewardUSDT = React.useMemo(() => {
    return (
      new BN(pendingReward !== 'NaN' ? pendingReward : 0)
        .multipliedBy(earnedTokenprice ?? 0)
        .toFixed(2, 1)
        .toString() ?? 0
    )
  }, [pendingReward, earnedTokenprice])

  const stakedTokenUSDT = React.useMemo(() => {
    return (
      new BN(stakedTokenBalance ?? 0)
        .multipliedBy(stakingTokenPrice ?? 0)
        .toFixed(2, 1)
        .toString() ?? 0
    )
  }, [stakedTokenBalance, stakingTokenPrice])

  const earningSymbol = earningToken?.symbol
  const stakingSymbol = stakingToken?.symbol

  const [onPresentApyModal] = useModal(
    <RoiCalculatorModal
      earningTokenPrice={Number(earnedTokenprice)}
      stakingTokenPrice={stakingTokenPrice}
      stakingTokenBalance={stakedBalance.plus(stakingTokenBalance)}
      apr={apr}
      stakingTokenSymbol={stakingSymbol}
      linkLabel={`Get ${stakingSymbol}`}
      linkHref={`${process.env.REACT_APP_OFFICIAL_URL}/swap`}
      earningTokenSymbol={earningSymbol}
      autoCompoundFrequency={isAutoPool ? 10000 : 0}
      performanceFee={0}
    />
  )

  const openRoiModal = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    onPresentApyModal()
  }

  return (
    <Card
      isActive={isCardActive}
      isFinished={finished && sousId !== 0}
      style={{ width: '365px', height: 'auto', borderRadius: '20px', overflow: 'hidden' }}
    >
      {finished && sousId !== 0 && <PoolFinishedSash />}
      <PoolHeader finished={finished}>
        <PoolHeaderTitle>
          <CardTitle
            isFinished={finished && sousId !== 0}
            style={{ color: finished ? '#494E67' : '#01142a', fontSize: '24px', lineHeight: 1 }}
          >
            {t('POOL_69')} {earningSymbol}
            {sortOrder === 2 ? <QuestionHelper color="#666" text={t('POOL_42')} size={18} /> : null}
          </CardTitle>
          <Text color="#666" fontSize="16px" fontWeight={400}>
            {pool.poolCategory === PoolCategory.Auto ? t('POOL_43') : `${t('POOL_44')} ${stakingSymbol}`}
          </Text>
        </PoolHeaderTitle>
        <div style={{ width: '56px', position: 'relative', marginRight: '24px' }}>
          <FarmDoubleLogo currency0={earningToken.symbol.toLowerCase()} currency1={stakingToken.symbol.toLowerCase()} />
        </div>
      </PoolHeader>

      <Divider />

      <PoolContent>
        <StyledDetails style={{ width: '100%' }}>
          <Row>
            <Text color="#666" fontWeight={500}>
              {sortOrder === 1 ? 'APY' : 'APR'}
            </Text>
            {sortOrder === 1 && <QuestionHelper color="#666" text={t('POOL_45')} size={18} />}
          </Row>
          {finished || !Number(apr) || apr < 0 ? (
            '-'
          ) : (
            <>
              <AprText
                styles={{ fontWeight: 700 }}
                fontSize="24px"
                color="#FF7008"
                isDisabled={finished}
                value={apr}
                decimals={2}
                unit="%"
              />
              {/* <ApyButton
                pid={isAutoPool ? 1 : 0}
                lpLabel="SDT"
                lpSymbol="SDT"
                addLiquidityUrl="https://app.mojitoswap.finance/swap"
                isAutoPool={isAutoPool}
                poolApr={poolApr}
                projectTokenPrice={projectTokenPrice}
                apr={apr}
              /> */}
              <Button onClick={openRoiModal} variant="text" width="20px" height="20px" padding="0px" marginLeft="4px">
                <CalculateIcon color="textSubtle" width="20px" />
              </Button>
            </>
          )}
        </StyledDetails>
        {sortOrder !== 1 ? (
          <StyledDetails style={{ width: '100%', marginTop: '20px' }}>
            <Text color="#666" fontWeight={500}>
              {t('POOL_46')}
            </Text>
            <Text color="#01142A" fontSize="16px">
              0%
            </Text>
          </StyledDetails>
        ) : (
          <StyledDetails style={{ marginTop: '20px' }}>
            <PoolCutDown userData={userData} withdrawFee={withdrawFee} />
          </StyledDetails>
        )}

        {account && !needsApproval && (
          <Row
            style={{
              marginTop: '32px',
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <Column>
              <Row>
                <Text color="#FF3093" style={{ marginRight: '5px' }}>{`${earningSymbol}`}</Text>
                <Text color="#666">{t('POOL_49')}</Text>
              </Row>
              <Text color="#01142A" fontSize="24px" fontWeight={700}>
                {pendingReward !== 'NaN' ? pendingReward : 0}
              </Text>
              <Text fontWeight={400} color="rgba(18, 25, 45, 0.38)">
                {pendingRewardUSDT && '≈ '}${pendingRewardUSDT ?? 0}
              </Text>
            </Column>

            {account && harvest && !isAutoPool && (
              <Column>
                <HarvestButton
                  disabled={!earnings.toNumber() || pendingTx}
                  text={pendingTx ? `${t('POOL_51')}...` : t('POOL_50')}
                  onClick={async () => {
                    setPendingTx(true)
                    try {
                      await onReward()
                    } finally {
                      setPendingTx(false)
                    }
                  }}
                />
                {isManualPool && (
                  <>
                    <Box style={{ marginTop: '10px' }} />
                    <CompoundButton
                      disabled={!earnings.toNumber() || pendingTx}
                      text={pendingTx ? `${t('POOL_51')}...` : t('POOL_31')}
                      onClick={async () => {
                        onPresentCompound()
                      }}
                    />
                  </>
                )}
              </Column>
            )}
          </Row>
        )}

        <Row
          style={{
            marginTop: '32px',
            display: 'flex',
            flexFlow: account && !needsApproval ? 'row nowrap' : 'column nowrap',
            alignItems: account && !needsApproval ? 'center' : 'flex-start',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          {account && !needsApproval && (
            <Column>
              <Row style={{ alignItems: 'center' }}>
                <Text color="#FF3093" style={{ marginRight: '5px' }}>{`${stakingSymbol}`}</Text>
                <Text color="#666">{t('POOL_52')}</Text>
                {sortOrder === 1 && <QuestionHelper color="#666" text={t('POOL_53')} size={18} />}
                {sortOrder === 3 && <QuestionHelper color="#666" text={t('POOL_54')} size={18} />}
              </Row>
              <Text color="#01142A" fontSize="24px" fontWeight={700}>
                {sortOrder !== 1 ||
                getDisplayBalance(sortOrder === 1 ? initStakeBalance : stakedBalance, stakingToken.decimals) === '0'
                  ? ''
                  : '≈'}
                {getDisplayBalance(sortOrder === 1 ? initStakeBalance : stakedBalance, stakingToken.decimals)}
              </Text>
              <Text fontWeight={400} color="rgba(18, 25, 45, 0.38)">
                ${stakedTokenUSDT}
              </Text>
            </Column>
          )}

          {account && needsApproval && (
            <Column>
              <Row>
                <Text color="#666">{t('POOL_55')}</Text>
              </Row>
            </Column>
          )}

          {!account && (
            <Column>
              <Row>
                <Text color="#666"> {`${t('POOL_52')} ${stakingSymbol}`}</Text>
              </Row>
            </Column>
          )}

          <StyledCardActions style={{ width: account && !needsApproval ? 'auto' : '100%' }}>
            {!account && <UnlockButtonInPool connected={Boolean(account)} />}
            {account &&
              (needsApproval ? (
                <div style={{ flex: 1 }}>
                  <ApproveButton disabled={finished || requestedApproval} onClick={handleApprove} width="100%">
                    {t('POOL_56')}
                    {(finished || requestedApproval) && <Elipisis />}
                  </ApproveButton>
                </div>
              ) : (
                <>
                  <IconButton
                    mr="20px"
                    style={{
                      background:
                        stakedBalance.eq(new BigNumber(0)) || pendingTx
                          ? '#C9C9C9'
                          : 'linear-gradient(91.57deg, #FF3093 1.17%, #5218F4 99.12%)',
                      borderRadius: '10px',
                      boxShadow: 'none',
                    }}
                    disabled={stakedBalance.eq(new BigNumber(0)) || pendingTx}
                    onClick={onPresentWithdraw}
                  >
                    <MinusIcon color="#fff" />
                  </IconButton>
                  <IconButton
                    style={{
                      background: 'linear-gradient(91.57deg, #FF3093 1.17%, #5218F4 99.12%)',
                      borderRadius: '10px',
                      boxShadow: 'none',
                    }}
                    disabled={finished && sousId !== 0}
                    onClick={onPresentDeposit}
                  >
                    <AddIcon color="#fff" />
                  </IconButton>
                </>
              ))}
          </StyledCardActions>
        </Row>
      </PoolContent>
      <CardFooter
        projectLink={earningToken.projectLink}
        decimals={stakingToken.decimals}
        totalStaked={getTotalStakedBalance}
        startBlock={startBlock}
        endBlock={endBlock}
        isFinished={finished}
        poolCategory={poolCategory}
        tokenName={stakingToken.symbol}
        tokenAddress={earningToken.address ? getAddress(earningToken.address) : ''}
        tokenDecimals={earningToken.decimals}
        contractAddress={pool.contractAddress}
      />
    </Card>
  )
}

export default React.memo(PoolCard, (prev, next) => {
  if (prev.pool.userData === next.pool.userData && prev.pool.withdrawFee === next.pool.withdrawFee) {
    return true
  }
  return false
})
