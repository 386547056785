import React, { useEffect, useRef, CSSProperties } from 'react'
import CountUp from 'react-countup'
import { Text } from 'uikit'

interface TextProps {
  isDisabled?: boolean
  fontSize?: string
  color?: string
}

interface BalanceProps extends TextProps {
  value: number
  decimals?: number
  unit?: string
  styles?: CSSProperties
}

const Balance: React.FC<BalanceProps> = ({ value, fontSize, color, decimals, isDisabled, unit, styles }) => {
  const previousValue: any = useRef(0)

  useEffect(() => {
    previousValue.current = value
  }, [value])

  return (
    <Text bold color={isDisabled ? 'textDisabled' : color} fontSize={fontSize} fontWeight="500" style={styles}>
      <CountUp start={previousValue.current} end={value} decimals={decimals} duration={1} separator="," />
      {value && unit && <span style={{ paddingLeft: '4px' }}>{unit}</span>}
    </Text>
  )
}

export const AprText: React.FC<BalanceProps> = ({ value, fontSize, color, decimals, isDisabled, unit, styles }) => {
  const previousValue: any = useRef(0)

  useEffect(() => {
    previousValue.current = value
  }, [value])

  return (
    <Text bold color={isDisabled ? 'textDisabled' : color} fontSize={fontSize} fontWeight="500" style={styles}>
      <CountUp start={previousValue.current} end={value} decimals={decimals} duration={1} separator="," />
      {value && unit && <span style={{ paddingLeft: '4px' }}>{unit}</span>}
    </Text>
  )
}

Balance.defaultProps = {
  fontSize: '32px',
  isDisabled: false,
  color: '#494E67',
  decimals: 3,
}

export default Balance
