import React, { useState } from 'react'
import BigNumber from 'bignumber.js'
import styled, { keyframes } from 'styled-components'
import { Flex, Text, Skeleton } from 'uikit'
import { FarmV2 } from 'state/types'
import { provider as ProviderType } from 'web3-core'
import { MouseoverTooltip } from 'components/Tooltip'
import ExpandableSectionButton from 'components/ExpandableSectionButton'
import { BASE_ADD_LIQUIDITY_URL } from 'constants/index'
import getLiquidityUrlPathParts from 'utils/getLiquidityUrlPathParts'
import DetailsSection from './DetailsSection'
import CardHeading from './CardHeading'
import CardActionsContainer from './CardActionsContainer'
import ApyButton from './ApyButton'
import Timer from './Timer'
import BN from 'bignumber.js'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { useBlockNumber } from 'state/application/hooks'
import { CHAIN_BLOCK_TIME } from '../../../../constants'
import { TorchesLp, TorchesLogo } from '../FarmTable/Farm'

import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { projectToken } from '../../../../constants/migrate'

dayjs.extend(utc)
dayjs.extend(timezone)

export interface FarmWithStakedValue extends FarmV2 {
  apr?: number
  mainTokenApr?: number
  doubleDigTokenApr?: number
  doubleDigFeeApr?: number
  liquidity?: BigNumber
}

const RainbowLight = keyframes`
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
`

const StyledCardAccent = styled.div`
  /* background: linear-gradient(
    45deg,
    rgba(255, 0, 0, 1) 0%,
    rgba(255, 154, 0, 1) 10%,
    rgba(208, 222, 33, 1) 20%,
    rgba(79, 220, 74, 1) 30%,
    rgba(63, 218, 216, 1) 40%,
    rgba(47, 201, 226, 1) 50%,
    rgba(28, 127, 238, 1) 60%,
    rgba(95, 21, 242, 1) 70%,
    rgba(186, 12, 248, 1) 80%,
    rgba(251, 7, 217, 1) 90%,
    rgba(255, 0, 0, 1) 100%
  ); */
  background-size: 300% 300%;
  // animation: ${RainbowLight} 2s linear infinite;
  border-radius: 20px;
  filter: blur(6px);
  position: absolute;
  top: -2px;
  right: -2px;
  bottom: -2px;
  left: -2px;
  z-index: -1;
`

const FCard = styled.div`
  align-self: baseline;
  background: ${(props) => props.theme.card.background};
  border-radius: 20px;
  box-shadow: 0px 2px 12px -8px rgba(25, 19, 38, 0.1), 0px 1px 1px rgba(25, 19, 38, 0.05);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0px;
  position: relative;
  text-align: center;
`

const Divider = styled.div`
  background-color: rgba(115, 126, 141, 0.16);
  height: 1px;
  margin: 28px auto;
  width: 100%;
`

const ExpandingWrapper = styled.div<{ expanded: boolean }>`
  height: ${(props) => (props.expanded ? '100%' : '0px')};
  overflow: hidden;
  padding: ${(props) => (props.expanded ? '0 24px' : '0px')};
`

interface FarmCardProps {
  farm: FarmWithStakedValue
  removed: boolean
  projectTokenPrice?: BigNumber
  provider?: ProviderType
  account?: string
}

const FarmCard: React.FC<FarmCardProps> = ({ farm, removed, projectTokenPrice, account }) => {
  const { t } = useTranslation()
  const currentBlock = useBlockNumber()

  const [showExpandableSection, setShowExpandableSection] = useState(false)

  // We assume the token name is coin pair + lp e.g. CAKE-BNB LP, LINK-BNB LP,
  // NAR-CAKE LP. The images should be cake-bnb.svg, link-bnb.svg, nar-cake.svg
  const farmImage = farm.lpSymbol.split(' ')[0].toLocaleLowerCase()
  const totalValueFormatted = React.useMemo(() => {
    return farm.liquidity && farm?.liquidity.gte(0)
      ? `$${farm.liquidity.toNumber().toLocaleString(undefined, { maximumFractionDigits: 0 })}`
      : '-'
  }, [farm.liquidity])

  const lpLabel = farm.lpSymbol && farm.lpSymbol.toUpperCase().replace('MOJITO', '')
  const earnLabel = farm.dual ? farm.dual.earnLabel : 'SDT'
  const Id = farm.lpSymbol.split(' ')[0].replace('/', '-').toLowerCase()
  const farmAPR = farm.apr && farm.apr.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })

  const liquidityUrlPathParts = getLiquidityUrlPathParts({
    quoteTokenAddress: farm.quoteToken.address,
    tokenAddress: farm.token.address,
  })
  const addLiquidityUrl = `${BASE_ADD_LIQUIDITY_URL}/${liquidityUrlPathParts}`
  const lpAddress = farm.lpAddresses[process.env.REACT_APP_CHAIN_ID as any]

  const localEndTimeTip = React.useMemo(() => {
    if (farm?.dual?.rightTip) {
      const userTimezone = dayjs.tz.guess()
      return dayjs(farm.dual.rightTip).tz(userTimezone).format('YYYY-MM-DD HH:mm:ss')
    }
    return ''
  }, [farm])

  const leftSeconds = farm?.dual?.endBlock ? (farm?.dual?.endBlock - currentBlock) * CHAIN_BLOCK_TIME : -1

  const aprToolTips = (
    <div>
      <Flex justifyContent="space-between">
        <Text color="textSubtle" fontSize="14px">
          {t('FARM_74')}
        </Text>
        <Text color="text" fontSize="14px">
          {new BN(farm?.doubleDigFeeApr).toFixed(2, 1)}%
        </Text>
      </Flex>
      <Flex justifyContent="space-between" mt="8px">
        <Text color="textSubtle" fontSize="14px">
          {t('FARM_75')}
        </Text>
        <Text color="text" fontSize="14px">
          {new BN(farm?.mainTokenApr).toFixed(2, 1)}%
        </Text>
      </Flex>
      <Flex justifyContent="space-between" mt="8px">
        <Text color="textSubtle" fontSize="14px">
          {t('FARM_76', { asset: farm?.doubleDigToken?.symbol })}
        </Text>
        <Text color="text" fontSize="14px">
          {new BN(farm?.doubleDigTokenApr).toFixed(2, 1)}%
        </Text>
      </Flex>
    </div>
  )

  return (
    <FCard id={Id}>
      {farm.token.symbol.toUpperCase() === projectToken.symbol.toUpperCase() && <StyledCardAccent />}
      <CardHeading
        lpLabel={lpLabel}
        multiplier={farm.multiplier}
        isCommunityFarm={farm.isCommunity}
        farmImage={farmImage}
        tokenSymbol={farm.token.symbol}
        version={farm?.version ?? 1}
        endBlock={farm?.dual?.endBlock}
      />
      <Divider style={{ margin: '14px 0 14px 0' }} />
      {farm?.torchesSupport ? (
        <MouseoverTooltip placement="top" text={t('FARM_103')}>
          <TorchesLp
            href="https://www.torches.finance/en/crypto-bank"
            target="_blank"
            height="32px"
            style={{ marginBottom: '12px' }}
          >
            <TorchesLogo src={require('assets/images/farm/torches.png').default} />
            <Text fontSize="14px">{t('FARM_101')}</Text>
          </TorchesLp>
        </MouseoverTooltip>
      ) : null}
      {leftSeconds > 0 ? <Timer leftSeconds={leftSeconds} /> : null}
      <div style={{ padding: '0 24px' }}>
        {!removed && (
          <Flex justifyContent="space-between" alignItems="center">
            <Text color="#666" fontSize="16px" lineHeight="19px">
              {t('FARM_82')}
            </Text>
            <Text
              bold
              style={{ display: 'flex', alignItems: 'center' }}
              color="#FF7008"
              fontSize="24px"
              lineHeight="29px"
            >
              {farm.apr >= 0 ? (
                <>
                  {farm.doubleDigTokenApr ? (
                    <MouseoverTooltip placement="top" text={aprToolTips}>
                      <Text
                        bold
                        style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                        color="#FF7008"
                        fontSize="24px"
                        lineHeight="29px"
                      >
                        {farmAPR}%
                      </Text>
                    </MouseoverTooltip>
                  ) : (
                    <Text
                      bold
                      style={{ display: 'flex', alignItems: 'center' }}
                      color="#FF7008"
                      fontSize="24px"
                      lineHeight="29px"
                    >
                      {farmAPR}%
                    </Text>
                  )}
                  <ApyButton
                    pid={farm.pid}
                    variant="text-and-button"
                    lpLabel={lpLabel}
                    addLiquidityUrl={addLiquidityUrl}
                    projectTokenPrice={projectTokenPrice}
                    multiplier={farm.multiplier}
                    lpSymbol={farm.lpSymbol}
                    apr={farm?.version === 2 ? farm?.mainTokenApr : farm.apr}
                    details={farm}
                    version={farm?.version ?? 1}
                  />
                </>
              ) : (
                <Skeleton height={24} width={80} />
              )}
            </Text>
          </Flex>
        )}
        <Flex justifyContent="space-between" style={{ marginTop: '16px' }}>
          <Text color="#666" fontSize="16px" lineHeight="19px">
            {t('FARM_89')}
          </Text>
          <Text color="#01142A" fontSize="16px">
            {earnLabel} {farm?.version === 2 && farm?.doubleDigToken ? `+ ${farm?.doubleDigToken.symbol}` : null} +{' '}
            {t('FARM_95')}
          </Text>
        </Flex>
        <Flex justifyContent="space-between" style={{ marginTop: '16px' }}>
          <Text color="#666" fontSize="16px" lineHeight="19px">
            {t('FARM_91')}
          </Text>
          <Text color="#01142A" fontSize="16px">
            0%
          </Text>
        </Flex>

        {farm?.dual && farm.dual.showTips && (
          <Flex justifyContent="space-between" style={{ marginTop: '16px' }}>
            <Text color="#666" fontSize="16px" lineHeight="19px">
              {farm.dual.leftTip}
            </Text>
            <Text color="#01142A" fontSize="16px">
              {localEndTimeTip ?? ''}
            </Text>
          </Flex>
        )}

        <CardActionsContainer farm={farm} account={account} addLiquidityUrl={addLiquidityUrl} />
      </div>
      <Divider style={{ margin: '28px 0 0 0' }} />
      <ExpandableSectionButton
        onClick={() => setShowExpandableSection(!showExpandableSection)}
        expanded={showExpandableSection}
      />
      <ExpandingWrapper expanded={showExpandableSection}>
        <DetailsSection
          removed={removed}
          bscScanAddress={`${process.env.REACT_APP_EXPLORER_URL}/address/${
            farm.lpAddresses[process.env.REACT_APP_CHAIN_ID as string]
          }`}
          infoAddress={`/info/pool/${lpAddress}`}
          totalValueFormatted={totalValueFormatted}
          lpLabel={lpLabel}
          addLiquidityUrl={addLiquidityUrl}
          showTorches={farm?.torchesSupport ?? false}
          version={farm?.version ?? 1}
        />
      </ExpandingWrapper>
    </FCard>
  )
}

export default FarmCard
