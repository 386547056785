import BigNumber from 'bignumber.js'
import projectTokenABI from 'constants/abis/projectToken.json'
import sousChefABI from 'constants/abis/sousChef.json'
import vaultABI from 'constants/abis/vault.json'
import wkcsABI from 'constants/abis/weth.json'
import { poolsV2 } from 'constants/pools'
import { PoolCategory } from 'constants/types'
import { ETHER } from 'shardex-sdk'
import { getAddress, getWChainAddress } from 'utils/addressHelpers'
import multicall from 'utils/multicall'

// TODO THIS LOGICS NOT REALLY NEED
export const fetchBlockLimits = async (poolsInfo) => {
  const poolsWithEnd = poolsInfo.filter((p) => p.sousId !== 0)
  const callsStartBlock = poolsWithEnd.map((poolConfig) => {
    return {
      address: getAddress(poolConfig.contractAddress),
      name: 'startBlock',
    }
  })
  const callsEndBlock = poolsWithEnd.map((poolConfig) => {
    return {
      address: getAddress(poolConfig.contractAddress),
      name: 'bonusEndBlock',
    }
  })

  const starts = await multicall(sousChefABI, callsStartBlock)
  const ends = await multicall(sousChefABI, callsEndBlock)

  return poolsWithEnd.map((cakePoolConfig, index) => {
    const startBlock = starts[index]
    const endBlock = ends[index]
    return {
      sousId: cakePoolConfig.sousId,
      startBlock: new BigNumber(startBlock).toJSON(),
      endBlock: new BigNumber(endBlock).toJSON(),
    }
  })
}

export const fetchPoolsV2BlockLimits = async () => {
  const blocksInfo = await fetchBlockLimits(poolsV2)
  return blocksInfo
}

export const fetchTotalStaking = async (poolsInfo) => {
  const autoPools = poolsInfo.filter((p) => p.poolCategory === PoolCategory.Auto)
  const manualPools = poolsInfo.filter((p) => p.poolCategory === PoolCategory.Manual)
  const kcsPool = poolsInfo.filter((p) => p.stakingToken.symbol.toUpperCase() === ETHER.name)

  const nonKcsPool = poolsInfo.filter(
    (p) =>
      p.stakingToken.symbol !== ETHER.name &&
      p.poolCategory !== PoolCategory.Auto &&
      p.poolCategory !== PoolCategory.Manual
  )
  // no kcs pool has staked

  const manualPoolCalls = manualPools.map((poolConfig) => {
    return {
      address: getAddress(poolConfig.stakingToken.address as any),
      name: 'balanceOf',
      params: [getAddress(poolConfig.contractAddress)],
    }
  })

  const autoPoolCalls = [
    {
      address: getAddress(autoPools[0].contractAddress),
      name: 'balanceOf',
    },
    {
      address: getAddress(autoPools[0].contractAddress),
      name: 'totalShares',
    },
    {
      address: getAddress(autoPools[0].contractAddress),
      name: 'withdrawFee',
    },
  ]

  const callsKcsPools = kcsPool.map((poolConfig) => {
    return {
      address: getWChainAddress(),
      name: 'balanceOf',
      params: [getAddress(poolConfig.contractAddress)],
    }
  })

  const nonKcsPoolsCalls = nonKcsPool.map((poolConfig) => {
    return {
      address: getAddress(poolConfig.stakingToken.address as any),
      name: 'balanceOf',
      params: [getAddress(poolConfig.contractAddress)],
    }
  })

  const manualPoolsTotalStaked = await multicall(projectTokenABI, manualPoolCalls)

  const autoPoolsTotalStaked = await multicall(vaultABI, autoPoolCalls)

  const kcsPoolsTotalStaked = await multicall(wkcsABI, callsKcsPools)
  const nonKcsPoolsTotalStaked = await multicall(projectTokenABI, nonKcsPoolsCalls)

  return [
    ...manualPools.map((p, index) => ({
      sortOrder: p.sortOrder,
      sousId: p.sousId,
      totalStaked: new BigNumber(manualPoolsTotalStaked[index]).toString(10),
    })),
    {
      sortOrder: autoPools[0].sortOrder,
      sousId: autoPools[0].sousId,
      totalStaked: new BigNumber(autoPoolsTotalStaked[0]).toString(10),
      totalShare: new BigNumber(autoPoolsTotalStaked[1]).toString(10),
      withdrawFee: new BigNumber(autoPoolsTotalStaked[2]).div(10000).times(100).toString(),
    },
    ...kcsPool.map((p, index) => ({
      sortOrder: p.sortOrder,
      sousId: p.sousId,
      totalStaked: new BigNumber(kcsPoolsTotalStaked[index]).toJSON(),
    })),
    ...nonKcsPool.map((p, index) => ({
      sortOrder: p.sortOrder,
      sousId: p.sousId,
      totalStaked: new BigNumber(nonKcsPoolsTotalStaked[index]).toJSON(),
    })),
  ]
}

export const fetchPoolsV2TotalStaking = async () => {
  const poolInfoV2 = await fetchTotalStaking(poolsV2)
  return poolInfoV2
}
