import React from 'react'
import styled from 'styled-components'
import { SvgProps } from '../types'

const ImgIcon = styled.img`
  width: 32px;
  height: 32px;
  margin-right: 8px;
`

const Icon: React.FC<SvgProps> = () => {
  return <ImgIcon src={require('assets/images/market/default.png').default} />
}

export default Icon
