import React from 'react'
import { Flex, Text } from 'uikit'
import styled from 'styled-components'
import { isMobile } from 'react-device-detect'

const StyledImgTitle = styled.img`
  width: 49.2px;
  height: 18.4px;
`

interface Props {
  title: string
  version: number
  style?: any
}

const Imgs = {
  titleV1: require('assets/images/farm/title-1.svg').default,
  titleRightV1: require('assets/images/farm/title-1-right.svg').default,
  titleV2: require('assets/images/farm/title-2.svg').default,
  titleRightV2: require('assets/images/farm/title-2-right.svg').default,
}

const Title: React.FC<Props> = ({ title, version, style }) => {
  return (
    <Flex justifyContent="center" alignItems="center" mb={isMobile ? '20px' : '35px'} style={style}>
      <StyledImgTitle src={Imgs[`titleV${version}`]} />
      <Text fontSize={isMobile ? '20px' : '24px'} ml="12px" mr="12px" bold textAlign="center">
        {title}
      </Text>
      <StyledImgTitle src={Imgs[`titleRightV${version}`]} />
    </Flex>
  )
}

export default Title
