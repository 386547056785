import { ChainId } from 'shardex-sdk'
import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { FarmTableDoubleLogo } from 'components/DoubleLogo/FarmDoubleLogo'
import { Button, LinkExternal, Text, Flex, Link } from '../../uikit'
import { ArrowUpCircle } from 'react-feather'
import { AutoColumn } from '../Column'
import { getKCCExplorerLink } from '../../utils'
import { Wrapper, Section, ConfirmedIcon, ContentHeader } from './helpers'
import { SwapButton } from '../Button'
import { useTranslation } from 'react-i18next'
import farmList from 'constants/farmsV2'
import { projectToken } from '../../constants/migrate'

type TransactionSubmittedContentProps = {
  onDismiss: () => void
  hash: string | undefined
  chainId: ChainId
  pid?: number
}

const TorchesContainer = styled.div`
  width: 100%;
  background: #f5f5f5;
  border-radius: 12px;
  padding: 20px;
  position: relative;
  margin-top: 25px;
`

const TorchesImgContainer = styled.div`
  background: #01142a;
  border-radius: 0 12px 0 12px;
  height: 34px;
  width: 109px;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

const TorchesImg = styled.img`
  height: 20px;
`

const SuccessImg = styled.img`
  height: 16px;
  width: 16px;
  margin-right: 7px;
`

const ImgSuccess = require('assets/images/icon/success.svg').default

const TransactionSubmittedContent = ({ onDismiss, chainId, hash, pid }: TransactionSubmittedContentProps) => {
  const theme = useContext(ThemeContext)
  const { t } = useTranslation()

  const targetPair = pid ? farmList.filter((item) => item.pid === pid && item.torchesSupport) : []
  const farmImage = targetPair[0]?.lpSymbol.split(' ')[0]?.toLocaleLowerCase()
  const [currency0, currency1] = farmImage ? (farmImage?.split('/') as string[]) : []

  return (
    <Wrapper>
      <Section>
        <ContentHeader onDismiss={onDismiss}>{t('COMPONENTS_158')}</ContentHeader>
        <ConfirmedIcon>
          <ArrowUpCircle strokeWidth={1} size={70} color={theme.colors.primary} />
        </ConfirmedIcon>
        <AutoColumn gap="8px" justify="center">
          {chainId && hash && (
            <Text color="primary">
              <LinkExternal href={getKCCExplorerLink(chainId, hash, 'transaction')}>{t('COMPONENTS_159')}</LinkExternal>
            </Text>
          )}
          {targetPair.length ? null : (
            <SwapButton style={{ height: '42px' }} onClick={onDismiss} mt="20px">
              <Text color="#fff" fontSize="18px" fontWeight={500}>
                {t('COMPONENTS_50')}
              </Text>
            </SwapButton>
          )}
        </AutoColumn>
        {targetPair.length ? (
          <TorchesContainer>
            <TorchesImgContainer>
              <TorchesImg src={require('assets/images/farm/torches-full.png').default} />
            </TorchesImgContainer>
            <Flex alignItems="center" mb="12px">
              <FarmTableDoubleLogo currency0={currency0 as any} currency1={currency1 as any} />
              <Text fontSize="16px">{targetPair[0]?.lpSymbol}</Text>
            </Flex>
            <Text fontSize="14px" mb="15px">
              Deposit LP on Torches to get double earning
            </Text>
            <Flex alignItems="center" mb="15px">
              <SuccessImg src={ImgSuccess} />
              <Text fontSize="14px">LP deposit earning ({projectToken.symbol} farming reward)</Text>
            </Flex>
            <Flex alignItems="center" mb="15px">
              <SuccessImg src={ImgSuccess} />
              <Text fontSize="14px">80% Collateral rate for borrowing</Text>
            </Flex>
            <Button
              as={Link}
              external
              href="https://www.torches.finance/en/crypto-bank"
              style={{ width: '100%', height: '40px' }}
            >
              Go
            </Button>
          </TorchesContainer>
        ) : null}
      </Section>
    </Wrapper>
  )
}

export default TransactionSubmittedContent
