import React, { CSSProperties } from 'react'
import styled from 'styled-components'
import { Text, Button, Input, InputProps, Flex, Link } from 'uikit'
import { RowBetween } from '../Row/index'
import BN from 'bignumber.js'
import { useResponsive } from '../../hooks/useResponse'
import { useTranslation } from 'react-i18next'

interface ModalInputProps {
  max: string
  symbol: string
  onSelectMax?: () => void
  onChange: (e: React.FormEvent<HTMLInputElement>) => void
  placeholder?: string
  value: string
  addLiquidityUrl?: string
  inputTitle?: string
  styles?: CSSProperties
  isValidAmmount?: boolean
  inputWidth?: string
}

const getBoxShadow = ({ isWarning = false, theme }) => {
  if (isWarning) {
    return theme.shadows.warning
  }

  return theme.shadows.inset
}

const StyledTokenInput = styled.div<InputProps>`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.input};
  border-radius: 12px;
  // box-shadow: ${getBoxShadow};
  color: ${({ theme }) => theme.colors.text};
  padding: 0px;
  width: 100%;

  ${({ theme }) => theme.mediaQueries.sm} {
    padding: 24px 24px 14px 24px;
    border: 1px solid rgba(115, 126, 141, 0.16);
  }
`

const StyledInput = styled(Input)<{ inputWidth?: string }>`
  box-shadow: none;
  width: 180px;
  border: none;
  ${({ theme }) => theme.mediaQueries.xs} {
    width: ${({ inputWidth }) => inputWidth ?? '80px'};
  }
  font-size: 18px;
  &:focus:not(:disabled) {
    border: none;
  }

  &:focus {
    border: none;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    width: 180px;
    font-size: 24px;
  }
`

const StyledErrorMessage = styled(Text)`
  position: absolute;
  bottom: -32px;
  a {
    display: inline;
  }
  margin-bottom: 5px;
`

const MaxButton = styled(Button)`
  background: #e1faf4;
  width: 42px;
  height: 22px;
  color: #ff3093;
  border-radius: 4px;
  box-shadow: none;
  cursor: pointer;
`

const ModalInput: React.FC<ModalInputProps> = ({
  max,
  symbol,
  onChange,
  onSelectMax,
  value,
  addLiquidityUrl,
  inputTitle,
  styles,
  isValidAmmount,
  inputWidth,
}) => {
  const { t } = useTranslation()

  const { isMobile } = useResponsive()

  const isBalanceZero = max === '0' || !max

  const displayBalance = () => {
    if (isBalanceZero) {
      return '0'
    }

    const balanceNumber = new BN(max)

    if (balanceNumber.gt(0) && balanceNumber.lt(0.01)) {
      return balanceNumber.toString()
    }

    return balanceNumber.toFixed(2, 1)
  }

  return (
    <div style={{ position: 'relative' }}>
      <StyledTokenInput isWarning={isBalanceZero} style={styles}>
        <Flex justifyContent="space-between" alignItems="center">
          <Text color="#494E67" fontSize="14px">
            {inputTitle}
          </Text>
          <Text fontSize="14px" color="#494E67">
            {t('COMPONENTS_178')}: {displayBalance()}
          </Text>
        </Flex>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          style={{ width: '100%', marginTop: '16px', marginBottom: '0px' }}
        >
          <StyledInput onChange={onChange} placeholder="0" value={value} inputWidth={inputWidth} />
          <RowBetween style={{ width: 'auto', alignItems: 'center', justifyContent: 'space-between' }}>
            <MaxButton scale="sm" onClick={onSelectMax}>
              <Text color="#FF3093" fontSize={isMobile ? '12px' : '14px'}>
                {t('COMPONENTS_31')}
              </Text>
            </MaxButton>
            <Text
              fontSize={isMobile ? '12px' : '14px'}
              fontWeight={500}
              style={{ width: 'auto', lineHeight: '100%', textAlign: 'right', marginLeft: '10px' }}
            >
              {symbol}
            </Text>
          </RowBetween>
        </Flex>
      </StyledTokenInput>
      {isBalanceZero && (
        <StyledErrorMessage fontSize="14px" color="failure">
          {t('COMPONENTS_42')}
          <Link fontSize="14px" bold={false} href={addLiquidityUrl} external color="failure">
            {t('COMPONENTS_43')} {symbol}
          </Link>
        </StyledErrorMessage>
      )}
      {!isBalanceZero && !isValidAmmount && (
        <StyledErrorMessage fontSize="14px" color="failure">
          {/* Insufficient balance */}
        </StyledErrorMessage>
      )}
    </div>
  )
}

export default ModalInput
