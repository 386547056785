import React from 'react'
import { AddIcon as UikitAddIcon } from 'uikit'
import { ColumnCenter } from 'components/Column'
import styled from 'styled-components'

const StyledContainer = styled.div`
  background: #efeff2;
  border-radius: 16px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`

function AddIcon() {
  return (
    <ColumnCenter>
      <StyledContainer>
        <UikitAddIcon color="textRemark" />
      </StyledContainer>
    </ColumnCenter>
  )
}

export default AddIcon
