import React, { FunctionComponent } from 'react'
import Helmet from 'react-helmet'
import { useTranslation } from 'react-i18next'

const Meta: FunctionComponent<{ title?: string; description?: string }> = ({ title, description }) => {
  const { t } = useTranslation()
  return (
    <Helmet>
      {title && <title>{title}</title>}
      {description && <meta title={t('COMPONENTS_187')} content={description} />}
    </Helmet>
  )
}

export default Meta
