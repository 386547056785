/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { PriceApiResponse, PriceApiThunk, PriceState } from 'state/types'

const initialState: PriceState = {
  isLoading: false,
  lastUpdated: '',
  data: null as any,
}

// Thunks
export const fetchPrices = createAsyncThunk<PriceApiThunk>('prices/fetch', async () => {
  /*  const response = await fetch('https://api.pancakeswap.info/api/tokens')
  const data = (await response.json()) as PriceApiResponse */

  const data: PriceApiResponse = {
    updated_at: new Date().getTime().toString(),
    data: {},
  }
  // Return normalized token names
  return {
    updated_at: data.updated_at,
    data: Object.keys(data.data).reduce((accum, token) => {
      return {
        ...accum,
        [token.toLowerCase()]: parseFloat(data.data[token].price),
      }
    }, {}),
  }
})

export const pricesSlice = createSlice({
  name: 'prices',
  initialState,
  reducers: {
    updatePriceList: (state, action) => {
      state.isLoading = true
      state.lastUpdated = action.payload.updated_at
      state.data = action.payload.data
    },
  },
  /* extraReducers: (builder) => {
    builder.addCase(fetchPrices.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchPrices.fulfilled, (state, action: PayloadAction<PriceApiThunk>) => {
      state.isLoading = false
      state.lastUpdated = action.payload.updated_at
      state.data = action.payload.data
    })
  }, */
})

export const { updatePriceList } = pricesSlice.actions

export default pricesSlice.reducer
