import { ChainId } from 'shardex-sdk'

export default {
  merkleDistributor: {
    [ChainId.MAINNET]: '0x15208918b552D0E3A0502d1f454084F84432EB54',
    [ChainId.TESTNET]: '0x38A0fF5405914a71C635616abf1B16CD52BECB0b',
  },
  secondMerkleDistributor: {
    [ChainId.MAINNET]: '0x4f28d28df7da07b7631ea2b4f1993ac35573ab70',
    [ChainId.TESTNET]: '0xf05e899E29278AaAb71A89da9b1D67A6412F2EaB',
  },
  masterChef: {
    [ChainId.MAINNET]: '0x25C6d6A65C3ae5d41599Ba2211629B24604Fea4F',
    [ChainId.TESTNET]: '0x84F10c60Aa2d69aA38Ae307D3bd57e2825BD5617',
  },
  masterChefV2: {
    [ChainId.MAINNET]: '0xfdfcE767aDD9dCF032Cbd0DE35F0E57b04495324',
    [ChainId.TESTNET]: '0x1b6e106169dD10b42efA064769c937753fa5b32f',
  },
  mulltiCall: {
    [ChainId.MAINNET]: '0xF3F655D5c3f074aE2Bbd5e96b25D334C19942e3c',
    [ChainId.TESTNET]: '0x116a4C39256212D3A18Dadd2148F36C29DF10DA4',
  },
  vault: {
    [ChainId.MAINNET]: '0xF0D7C82E9f9bE85b2d1F8bedE40Afe1c1Fa7560c',
    [ChainId.TESTNET]: '0x516dbE24c123C35930ef217553C177f81be0E64B',
  },
  vaultV2: {
    [ChainId.MAINNET]: '0x3db85383aB912C9D9C003Bd2ec09107E0f37B8A0',
    [ChainId.TESTNET]: '0x3F9FaF1ED1866674A9D881Be8EBD5192F35a573E',
  },
  swapMining: {
    [ChainId.MAINNET]: '0x79Aa527F8D54016a75F5799da4eE198bb522413D',
    [ChainId.TESTNET]: '0x4af2211877FbAb15289291ACf26075583AF695EC',
  },
  referral: {
    [ChainId.MAINNET]: [
      '0x91E609094Aeb6Cb4cEDc6d3175694fd90d7e2690',
      '0xD3BA7e2F0589aa0c61c51ceC872202b6066129fc',
      '0x5D36A4DB638D7B4b6C2A8df616c181B7a9E7ECB0',
      '0xA76Ee9e227d0De5277EbBf2f67375c648844Ce42',
      '0xB4eBEA9AE9d3fFFA344926e7Dc388E35F2Cd44E3',
      '0xbe9b516987dDb3CFb54508B1dA119b17552be7E8',
      '0x9Dd2Ae950Ade895517D282E8C0Ef111607Db5B85',
    ],
    [ChainId.TESTNET]: [
      '0x3783949E8D1861AcAcc4AdA9A4604e6E962Fe48d',
      '0x9e5af01E0f228d309a078BE70392EBF8b8a9608d',
      '0x182252dBA6E64A163914Fb96ABBDE5246CCE3a61',
      '0x7d7B832685C566d7234fea21609F635ab3802D87',
      '0x5BFF2b172177b6E83361510175C8884600dc78B4',
      '0x34E6fcd7949680CeF8b4d71A98ddB55F304B7db8',
      '0x2dA9b6f431A050a73f01ceFC900e834606EC0Bf3',
    ],
  },
  profile: {
    [ChainId.MAINNET]: '0xb4c9817c173F20c342b05E91300eBfFe4E680E42',
    [ChainId.TESTNET]: '0xeD07dFAA1B252b92F2A1bd29D3dD5C4Ee4B4eC52',
  },
  mojitoCattleFactory: {
    [ChainId.MAINNET]: '0x5a99B2eB91Bea312201e1a9292ea45553F7E26Fe',
    [ChainId.TESTNET]: '0x3AEF703Ff188EF6f574687DA22EbB3de99D5ad32',
  },
  pointCenterIfo: {
    [ChainId.MAINNET]: '',
    [ChainId.TESTNET]: '',
  },
  mojitoCattle: {
    [ChainId.MAINNET]: '0x483Db86352f87225a3A2181886A4E8970552825F',
    [ChainId.TESTNET]: '0x33a2cd72c04A740310bDa2b0C545653AD74f38eb',
  },
  lemonNft: {
    [ChainId.MAINNET]: '0x4Ca64bF392ee736f6007Ce93E022DeB471a9dFd1',
    [ChainId.TESTNET]: '0x975dD57640eb66639fD3FdA9Ed7D73B155[ChainId.MAINNET]eeb',
  },
  nftMarket: {
    [ChainId.MAINNET]: '0x8AA946F3FB38297917Ca91DCAf816721C59D0655',
    [ChainId.TESTNET]: '0xF1D711FE6bdF6DB5588e7A2F238C7bA45B548aD2',
    // [ChainId.MAINNET]: '0xF1D711FE6bdF6DB5588e7A2F238C7bA45B548aD2',
    // [ChainId.TESTNET]: '0xAFcaC01f389548fdf8FE444B46bddb90c379a1D9',
  },
  luckyDrawResult: {
    [ChainId.MAINNET]: '0xfe445ad4b9F6cED1BBce57222487aBa67a7CEfE3',
    [ChainId.TESTNET]: '0xa27D419FD9C18a512fF456861c1D1D24D63ca420',
  },
  rolling: {
    [ChainId.MAINNET]: '0x495d95989915bC24b963E0211516b47AE7[ChainId.TESTNET]e35',
    [ChainId.TESTNET]: '0xBc79F1e64CeBC7a815cAB1575E2d7d23dd47eEA3',
  },
}
