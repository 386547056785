import React from 'react'
import styled from 'styled-components'
import { Flex, Text } from 'uikit'
import getTimePeriods from 'utils/getTimePeriods'
import { useTranslation } from 'react-i18next'

export interface TimerProps {
  leftSeconds?: number
  mode?: string
}

const Wrapper = styled(Flex)`
  height: 44px;
  width: 100%;
  background: rgba(10, 196, 221, 0.15);
  padding: 0 25px;
  justify-content: space-between;
  align-items: center;
  margin: -14px 0 14px 0;
`

const ImgClock = styled.img`
  width: 20px;
  height: 20px;
`

const Timer: React.FC<TimerProps> = ({ leftSeconds, mode }) => {
  const { t } = useTranslation()
  const timeUntil = getTimePeriods(leftSeconds)
  const isLastMinutes = timeUntil.days === 0 && timeUntil.hours === 0 && timeUntil.minutes === 0
  const days = timeUntil.months > 0 ? timeUntil.months * 30 + timeUntil.days : timeUntil.days
  if (mode === 'table') {
    return (
      <Flex justifyContent="space-between" alignItems="center">
        <Text color="#0AC4DD" mr="8px">
          {t('LAUNCHPAD_52')}:
        </Text>
        <Flex alignItems="center">
          <Text>{days}</Text>
          <Text fontSize="14px">&nbsp;D&nbsp;</Text>
          <Text>{timeUntil.hours}</Text>
          <Text fontSize="14px">&nbsp;H&nbsp;</Text>
          <Text>{isLastMinutes ? 1 : timeUntil.minutes}</Text>
          <Text fontSize="14px">&nbsp;M</Text>
        </Flex>
      </Flex>
    )
  }
  return (
    <Wrapper>
      <Flex>
        <ImgClock src={require('assets/images/farm/timelock.svg').default} />
        <Text color="#0AC4DD" ml="8px">
          {t('LAUNCHPAD_52')}
        </Text>
      </Flex>
      <Flex alignItems="center">
        <Text>{days}</Text>
        <Text fontSize="14px">&nbsp;D&nbsp;</Text>
        <Text>{timeUntil.hours}</Text>
        <Text fontSize="14px">&nbsp;H&nbsp;</Text>
        <Text>{isLastMinutes ? 1 : timeUntil.minutes}</Text>
        <Text fontSize="14px">&nbsp;M</Text>
      </Flex>
    </Wrapper>
  )
}

export default Timer
