import React from 'react'
import { Currency, Percent, Price, ETHER } from 'shardex-sdk'
import { Text } from '../../uikit'
import { AutoColumn } from '../../components/Column'
import { AutoRow } from '../../components/Row'
import { ONE_BIPS } from '../../constants'
import { Field } from '../../state/mint/actions'
import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'

export function PoolPriceBar({
  currencies,
  noLiquidity,
  poolTokenPercentage,
  price,
  ACurrencyIsWKCS,
  BCurrencyIsWKCS,
}: {
  currencies: { [field in Field]?: Currency }
  noLiquidity?: boolean
  poolTokenPercentage?: Percent
  price?: Price
  ACurrencyIsWKCS?: boolean
  BCurrencyIsWKCS?: boolean
}) {
  const { t } = useTranslation()
  const theme: any = useTheme()
  const BCurrencySymbol = BCurrencyIsWKCS ? ETHER.symbol : currencies[Field.CURRENCY_B]?.symbol
  const ACurrencySymbol = ACurrencyIsWKCS ? ETHER.symbol : currencies[Field.CURRENCY_A]?.symbol
  return (
    <AutoColumn gap="md">
      <AutoRow justify="space-around" gap="4px">
        <AutoColumn justify="center">
          <Text fontSize="16px" fontWeight={800} color={theme.colors.text}>
            {price?.toSignificant(6) ?? '-'}
          </Text>
          <Text fontSize="14px" fontWeight={400} color="textRemark" pt={1}>
            {BCurrencySymbol} {t('ADDLIQUIDITY_18')} {ACurrencySymbol}
          </Text>
        </AutoColumn>
        <AutoColumn justify="center">
          <Text fontSize="16px" fontWeight={800} color={theme.colors.text}>
            {price?.invert()?.toSignificant(6) ?? '-'}
          </Text>
          <Text fontSize="14px" fontWeight={400} color="textRemark" pt={1}>
            {ACurrencySymbol} {t('ADDLIQUIDITY_18')} {BCurrencySymbol}
          </Text>
        </AutoColumn>
        <AutoColumn justify="center">
          <Text fontSize="16px" fontWeight={800} color={theme.colors.text}>
            {noLiquidity && price
              ? '100'
              : (poolTokenPercentage?.lessThan(ONE_BIPS) ? '<0.01' : poolTokenPercentage?.toFixed(2)) ?? '0'}
            %
          </Text>
          <Text fontSize="14px" fontWeight={400} color="textRemark" pt={1}>
            {t('ADDLIQUIDITY_17')}
          </Text>
        </AutoColumn>
      </AutoRow>
    </AutoColumn>
  )
}

export default PoolPriceBar
