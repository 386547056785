import React, { useState } from 'react'
import styled from 'styled-components'
import { ChevronDown, ChevronUp } from 'react-feather'
import { Flex, MetamaskIcon } from 'uikit'
import Balance from 'components/Balance'
import { CommunityTag, CoreTag, KcsTag, AutoTag, ManualTag } from 'components/Tags'
import { useBlockNumber } from 'state/application/hooks'
import { PoolCategory } from 'constants/types'
import { addTokenToWallet } from 'utils/wallet'
import { getTokenUrl } from '../../../utils/getTokenUrl'
import { Text } from 'uikit'
import { StyledLinkExternal } from 'pages/Farms/components/FarmCard/DetailsSection'
import { getContractExplorerUrl, getTokenExplorerUrl } from '../../../utils/urlHelpers'
import BN from 'bignumber.js'
import { useTranslation } from 'react-i18next'

const tags = {
  [PoolCategory.KCS]: KcsTag,
  [PoolCategory.CORE]: CoreTag,
  [PoolCategory.COMMUNITY]: CommunityTag,
  [PoolCategory.Auto]: AutoTag,
  [PoolCategory.Manual]: ManualTag,
}

const StyledFooter = styled.div<{ isFinished: boolean }>`
  border-top: 1px solid ${({ theme }) => (theme.isDark ? '#524B63' : '#E9EAEB')};
  color: ${({ isFinished, theme }) => theme.colors[isFinished ? 'textDisabled2' : 'primary2']};
  padding: 24px;
`

const Image = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-left: 8px;
  margin-right: 12px;
`

const StyledDetailsButton = styled.button`
  align-items: center;
  background-color: transparent;
  border: 0;
  color: ${(props) => props.theme.colors.primary};
  cursor: pointer;
  display: inline-flex;
  font-size: 16px;
  font-weight: 500;
  height: 32px;
  justify-content: center;
  outline: 0;
  padding: 0;
  margin-right: 10px;
  &:hover {
    opacity: 0.9;
  }

  & > svg {
    margin-left: 4px;
  }
`

const Details = styled.div`
  margin-top: 14px;
`

const Row = styled(Flex)`
  align-items: center;
  justify-content: flex-end;
  width: auto;
`

const FlexFull = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-right: 10px;
`

const Label = styled.div`
  font-size: 16px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`
const TokenLink = styled.a`
  font-size: 16px;
  text-decoration: none;
  color: ${(props) => props.theme.colors.primary};
  cursor: pointer;
`

const CardFooter: React.FC<any> = ({
  tokenAddress,
  totalStaked,
  tokenName,
  tokenDecimals,
  isFinished,
  startBlock,
  endBlock,
  poolCategory,
  contractAddress,
}) => {
  const currentBlock = useBlockNumber()
  const [isOpen, setIsOpen] = useState(false)
  const { t } = useTranslation()
  const Icon = isOpen ? ChevronUp : ChevronDown

  const handleClick = () => setIsOpen(!isOpen)
  const Tag = tags[poolCategory]

  const blocksUntilStart = Math.max(startBlock - (currentBlock ?? 0), 0)
  const blocksRemaining = Math.max(endBlock - (currentBlock ?? 0), 0)

  const isMetaMaskInScope = !!(window as any).ethereum?.isMetaMask

  return (
    <StyledFooter isFinished={isFinished}>
      <Row>
        <FlexFull style={{ justifyContent: 'space-between', width: '100%' }}>
          <Tag />
        </FlexFull>
        <StyledDetailsButton onClick={handleClick}>
          {isOpen ? t('POOL_23') : t('POOL_24')} <Icon />
        </StyledDetailsButton>
      </Row>
      {isOpen && (
        <Details>
          <Row mb="4px" style={{ justifyContent: 'space-between' }}>
            <FlexFull>
              <Label style={{ color: '#494E67' }}>{t('POOL_25')}</Label>
            </FlexFull>
            <Row>
              <Balance
                fontSize="16px"
                color="#01142A"
                isDisabled={isFinished}
                unit={tokenName.toUpperCase()}
                value={Number(new BN(totalStaked).toFixed(2, 1))}
              />
              <Image src={getTokenUrl(tokenName.toLowerCase())} />
            </Row>
          </Row>
          {blocksUntilStart > 0 && (
            <Row mb="4px" mr="10px">
              <FlexFull>
                <Label>{t('POOL_26')}:</Label>
              </FlexFull>
              <Balance fontSize="14px" isDisabled={isFinished} value={blocksUntilStart} decimals={0} />
            </Row>
          )}
          {blocksUntilStart === 0 && blocksRemaining > 0 && (
            <Row mb="4px" mr="10px">
              <FlexFull>
                <Label>{t('POOL_27')}:</Label>
              </FlexFull>
              <Balance fontSize="14px" isDisabled={isFinished} value={blocksRemaining} decimals={0} />
            </Row>
          )}
          <Row mb="4px">
            <FlexFull>
              <StyledLinkExternal color="#FF3093" style={{ color: '#FF3093' }} href={getTokenExplorerUrl(tokenAddress)}>
                {t('POOL_28')}
              </StyledLinkExternal>
            </FlexFull>
          </Row>
          <Row mb="4px">
            <FlexFull>
              <StyledLinkExternal
                color="#FF3093"
                style={{ color: '#FF3093' }}
                href={getContractExplorerUrl(contractAddress[process.env.REACT_APP_CHAIN_ID as any])}
              >
                {t('POOL_29')}
              </StyledLinkExternal>
            </FlexFull>
          </Row>

          {isMetaMaskInScope && tokenAddress && (
            <Row mb="4px">
              <FlexFull>
                <TokenLink onClick={() => addTokenToWallet(tokenAddress, tokenName, tokenDecimals)}>
                  <Text color="#FF3093" fontSize="16px">
                    {t('POOL_30', { tokenName })}
                  </Text>
                </TokenLink>
                <MetamaskIcon height={15} width={15} ml="6px" mr="2px" />
              </FlexFull>
            </Row>
          )}
        </Details>
      )}
    </StyledFooter>
  )
}

export default React.memo(CardFooter)
