import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" color="white" {...props}>
      <path d="M24 0H0V24H24V0Z" fill="white" fillOpacity="0.01" />
      <path
        d="M19.5 2H5.5C4.94771 2 4.5 2.44771 4.5 3V21C4.5 21.5523 4.94771 22 5.5 22H19.5C20.0523 22 20.5 21.5523 20.5 21V3C20.5 2.44771 20.0523 2 19.5 2Z"
        stroke="#040A2D"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M8.5 15H15.5" stroke="#040A2D" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.5 18H12" stroke="#040A2D" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M15.5 6H8.5V11H15.5V6Z"
        stroke="#040A2D"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default Icon
