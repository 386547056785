import React from 'react'
import { Button, useWalletModal } from 'uikit'
import useAuth from 'hooks/useAuth'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const MojitoButton = styled(Button)`
  border-radius: 22px;
  height: 48px;
  font-size: 16px;
  color: #fff;
  background: linear-gradient(91.57deg, #ff3093 1.17%, #5218f4 99.12%);
`

const UnlockButton = (props) => {
  const { login, logout } = useAuth()
  const { onPresentConnectModal } = useWalletModal(login, logout)
  const { t } = useTranslation()
  return (
    <MojitoButton onClick={onPresentConnectModal} {...props}>
      {props.text ? props.text : t('COMPONENTS_157')}
    </MojitoButton>
  )
}

export default UnlockButton
