import React, { useState } from 'react'
import styled from 'styled-components'
import { FarmWithStakedValue } from 'pages/Farms/components/FarmCard/FarmCard'
import { useMatchBreakpoints } from 'uikit'
import useI18n from 'hooks/useI18n'

import Apr, { AprProps } from './Apr'
import Farm, { FarmProps } from './Farm'
import Earned, { EarnedProps } from './Earned'
import Details from './Details'
import Multiplier, { MultiplierProps } from './Multiplier'
import Liquidity, { LiquidityProps } from './Liquidity'
import ActionPanel from './Actions/ActionPanel'
import CellLayout from './CellLayout'
import { DesktopColumnSchema, DesktopColumnSchemaV2, MobileColumnSchema } from '../types'
import CardHeading from '../FarmCard/CardHeading'
import { useTranslation } from 'react-i18next'
import farmConfig from 'constants/farmsV2'

export interface RowProps {
  apr: AprProps
  farm: FarmProps
  earned: EarnedProps
  liquidity: LiquidityProps
  details: FarmWithStakedValue
  multiplier?: MultiplierProps
  rewarded?: EarnedProps
}

const cells = {
  apr: Apr,
  farm: Farm,
  earned: Earned,
  details: Details,
  multiplier: Multiplier,
  liquidity: Liquidity,
}

const cellsV2 = {
  apr: Apr,
  farm: Farm,
  earned: Earned,
  rewarded: Earned,
  details: Details,
  liquidity: Liquidity,
}

const CellInner = styled.div`
  padding: 24px 0px;
  display: flex;
  width: 100%;
  align-items: center;
  padding-right: 8px;

  ${({ theme }) => theme.mediaQueries.xl} {
    padding-right: 32px;
  }
`

const StyledTr = styled.tr`
  cursor: pointer;
  & + & {
    border-top: 1px solid rgba(115, 126, 141, 0.16);
  }
  user-select: none;
`

const StyledList = styled.div`
  user-select: none;
`

const EarnedMobileCell = styled.td`
  padding: 16px 0 24px 16px;
`

const AprMobileCell = styled.td`
  padding-top: 16px;
  padding-bottom: 24px;
`

const FarmMobileCell = styled.td`
  padding-top: 24px;
`

const FarmMobileLines = styled.div`
  width: 100%;
  height: 1px;
  background: #737e8d29;
`

const FarmTopCell = styled.div`
  padding: 0 32px;
  display: flex;
  justify-content: space-between;
`

const FarmTopCellApr = styled.div`
  padding: 24px 0;
  width: '50%';
`

const FarmMobileToggle = styled.div`
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Row: React.FunctionComponent<RowProps> = (props) => {
  const { details } = props
  const [actionPanelToggled, setActionPanelToggled] = useState(false)
  const TranslateString = useI18n()
  const { t } = useTranslation()
  const toggleActionPanel = () => {
    setActionPanelToggled(!actionPanelToggled)
  }

  const { isXl, isXs } = useMatchBreakpoints()
  const isMobile = !isXl
  const tableSchema = isMobile
    ? MobileColumnSchema
    : details.version === 2
    ? DesktopColumnSchemaV2
    : DesktopColumnSchema
  const columnNames = tableSchema.map((column) => column.name)
  const propsCopy = { ...props }
  const propsKeys = Object.keys(propsCopy)
  const handleRenderRow = () => {
    const farmImage = props.details.lpSymbol.split(' ')[0].toLocaleLowerCase()
    const lpLabel = props.details.lpSymbol && props.details.lpSymbol.toUpperCase().replace('MOJITO', '')

    const Id = props.farm.label.replace('/', '-').toLowerCase()
    const hasEndBlock = farmConfig.find((item: any) => item?.pid === props.details?.pid && item?.dual?.endBlock)

    if (isMobile) {
      return (
        <StyledList id={Id}>
          <CardHeading
            lpLabel={lpLabel}
            multiplier={props.details?.multiplier}
            isCommunityFarm={props.details.isCommunity}
            farmImage={farmImage}
            tokenSymbol={props.details.token.symbol}
            version={props.details.version ?? 1}
            endBlock={hasEndBlock?.dual?.endBlock}
          />
          <FarmMobileLines />
          <FarmTopCell>
            <FarmTopCellApr>
              <CellLayout label={t('FARM_32')}>
                <Earned {...props.earned} />
              </CellLayout>
            </FarmTopCellApr>
            <FarmTopCellApr>
              <CellLayout label={t('FARM_30')}>
                <Apr {...props.apr} details={details} hideButton={isMobile} />
              </CellLayout>
            </FarmTopCellApr>
          </FarmTopCell>
          {actionPanelToggled && details && (
            <tr>
              <td colSpan={6} style={{ width: '351px' }}>
                <ActionPanel {...props} isMobile={isMobile} />
              </td>
            </tr>
          )}
          <FarmMobileLines />
          <FarmMobileToggle onClick={toggleActionPanel}>
            <Details actionPanelToggled={actionPanelToggled} />
          </FarmMobileToggle>
        </StyledList>
      )
    }
    if (!isXs) {
      return (
        <StyledTr onClick={toggleActionPanel} id={Id}>
          {propsKeys.map((key) => {
            const columnIndex = columnNames.indexOf(key)
            if (columnIndex === -1) {
              return null
            }
            switch (key) {
              case 'details':
                return (
                  <td key={key}>
                    <CellInner>
                      <CellLayout>
                        <Details actionPanelToggled={actionPanelToggled} />
                      </CellLayout>
                    </CellInner>
                  </td>
                )
              case 'apr':
                return (
                  <td key={key}>
                    <CellInner>
                      <CellLayout label={t('FARM_30')}>
                        <Apr {...props.apr} details={details} hideButton={isMobile} />
                      </CellLayout>
                    </CellInner>
                  </td>
                )
              case 'rewarded':
                return (
                  <td key={key}>
                    <CellInner>
                      {details?.doubleDigToken ? (
                        <CellLayout label={t('FARM_31')}>
                          <Earned {...props.rewarded} />
                        </CellLayout>
                      ) : (
                        <CellLayout label={' '}>
                          <div> </div>
                        </CellLayout>
                      )}
                    </CellInner>
                  </td>
                )
              default:
                return (
                  <td key={key}>
                    <CellInner>
                      <CellLayout
                        label={TranslateString(tableSchema[columnIndex].translationId, tableSchema[columnIndex].label)}
                      >
                        {React.createElement(
                          details?.version === 2 && !!details?.doubleDigToken ? cellsV2[key] : cells[key],
                          propsCopy[key]
                        )}
                      </CellLayout>
                    </CellInner>
                  </td>
                )
            }
          })}
        </StyledTr>
      )
    }
    return (
      <StyledTr onClick={toggleActionPanel} id={Id}>
        <td>
          <tr>
            <FarmMobileCell>
              <CellLayout>
                <Farm {...props.farm} />
              </CellLayout>
            </FarmMobileCell>
          </tr>
          <tr>
            <EarnedMobileCell>
              <CellLayout label={t('FARM_32')}>
                <Earned {...props.earned} />
              </CellLayout>
            </EarnedMobileCell>
            <AprMobileCell>
              <CellLayout label={t('FARM_30')}>
                <Apr {...props.apr} details={details} hideButton />
              </CellLayout>
            </AprMobileCell>
          </tr>
        </td>
        <td>
          <CellInner>
            <CellLayout>
              <Details actionPanelToggled={actionPanelToggled} />
            </CellLayout>
          </CellInner>
        </td>
      </StyledTr>
    )
  }

  return (
    <>
      {handleRenderRow()}
      {actionPanelToggled && details && !isMobile && (
        <tr>
          <td colSpan={6}>
            <ActionPanel {...props} isMobile={isMobile} />
          </td>
        </tr>
      )}
    </>
  )
}

export default Row
