import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { save, load } from 'redux-localstorage-simple'
import { useDispatch } from 'react-redux'

import application from './application/reducer'
import { updateVersion } from './global/actions'
import user from './user/reducer'
import transactions from './transactions/reducer'
import swap from './swap/reducer'
import mint from './mint/reducer'
import lists from './lists/reducer'
import burn from './burn/reducer'
import multicall from './multicall/reducer'
import farmsReducer from './farms'
import poolsReducer from './pools'
import toasts from './toasts'
import { getThemeCache } from '../utils/theme'
import pricesReducer from './prices'
import blockReducer from './block'
import minningReducer from './minning'
import referralReducer from './referral'
// import statistics from './statistics/reducer'
// import achievementsReducer from './achievements'
// import infoReducer from './info'

type MergedState = {
  user: {
    [key: string]: any
  }
  transactions: {
    [key: string]: any
  }
}
const PERSISTED_KEYS: string[] = ['user', 'transactions']
const loadedState = load({
  states: PERSISTED_KEYS,
}) as MergedState
if (loadedState.user) {
  loadedState.user.userDarkMode = getThemeCache()
}

// @ts-ignore
const store = configureStore({
  reducer: {
    application,
    user,
    transactions,
    swap,
    mint,
    burn,
    multicall,
    lists,
    toasts,
    farms: farmsReducer,
    pools: poolsReducer,
    prices: pricesReducer,
    block: blockReducer,
    minning: minningReducer,
    referral: referralReducer,
    // info: infoReducer,
  },
  // @ts-ignore
  middleware: [...getDefaultMiddleware({ thunk: true, serializableCheck: false }), save({ states: PERSISTED_KEYS })],
  // @ts-ignore
  preloadedState: loadedState,
})

store.dispatch(updateVersion())

export type AppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch = () => useDispatch<any>()

export default store
