import BigNumber from 'bignumber.js'
import React, { useCallback, useMemo, useState } from 'react'
import { Button, LinkExternal, Modal } from 'uikit'
import ModalActions from 'components/ModalActions'
import TokenInput from 'components/TokenInput'
import { getFullDisplayBalance } from 'utils/formatBalance'
import { Text } from 'uikit'
import Row from 'components/Row'
import styled from 'styled-components'
import BN from 'bignumber.js'
import Elipisis from 'components/Elipisis/Elipisis'
import { useResponsive } from '../../../hooks/useResponse'
import { useTranslation } from 'react-i18next'

const ModalActionCancelButton = styled(Button)<{ disabled: boolean }>`
  border-radius: 30px;
  height: 48px;
  width: 142px;
  z-index: 1;
  box-shadow: none;
  position: relative;
  overflow: visible;
  background: ${({ disabled }) => {
    if (disabled) {
      return '#C9C9C9'
    }
    return 'linear-gradient(to right, #14d89e, #0bc5d9)'
  }};
  color: #fff;
  &::after {
    position: absolute;
    z-index: 99;
    top: 2px;
    bottom: 2px;
    right: 2px;
    left: 2px;
    background: ${({ disabled }) => {
      if (disabled) {
        return '#C9C9C9'
      }
      return '#fff'
    }};
    display: ${({ disabled }) => {
      if (disabled) {
        return 'none'
      }
      return 'block'
    }};
    color: ${({ disabled }) => {
      if (disabled) {
        return '#fff'
      }
      return '#FF3093'
    }};
    border-radius: 30px;
    content: 'Cancel';
    line-height: 44px;
  }
`

const ModalActionButton = styled(Button)<{ disabled: boolean }>`
  border-radius: 30px;
  height: 48px;
  width: 142px;
  z-index: 1;
  box-shadow: none;
  position: relative;
  overflow: visible;
  background: ${({ disabled }) => {
    if (disabled) {
      return '#C9C9C9'
    }
    return 'linear-gradient(to right, #14d89e, #0bc5d9)'
  }};
  color: ${({ disabled }) => {
    if (disabled) {
      return '#fff'
    }
    return '#FF3093'
  }};
  &::after {
    position: absolute;
    z-index: 99;
    top: 2px;
    bottom: 2px;
    right: 2px;
    left: 2px;
    background: ${({ disabled }) => {
      if (disabled) {
        return '#C9C9C9'
      }
      return '#fff'
    }};
    display: ${({ disabled }) => {
      if (disabled) {
        return 'none'
      }
      return 'block'
    }};
    color: ${({ disabled }) => {
      if (disabled) {
        return '#fff'
      }
      return '#FF3093'
    }};
    border-radius: 30px;
    content: 'Confirm';
    line-height: 44px;
  }
`

const StyledErrorMessage = styled(Text)`
  position: relative;
  margin-top: 10px;
  padding-left: 10px;
  a {
    display: inline;
  }
`

interface DepositModalProps {
  max: BigNumber
  onConfirm: (amount: string, decimals: number) => void
  onDismiss: () => void
  tokenName?: string
  stakingTokenDecimals?: number
}

const DepositModal: React.FC<DepositModalProps> = ({
  max,
  onConfirm,
  onDismiss,
  tokenName = '',
  stakingTokenDecimals = 18,
}) => {
  const { isMobile } = useResponsive()
  const [val, setVal] = useState('')
  const [pendingTx, setPendingTx] = useState(false)
  const { t } = useTranslation()
  const fullBalance = useMemo(() => {
    return getFullDisplayBalance(max, stakingTokenDecimals)
  }, [max, stakingTokenDecimals])

  const handleChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      setVal(e.currentTarget.value)
    },
    [setVal]
  )

  const handleSelectMax = useCallback(() => {
    setVal(fullBalance)
  }, [fullBalance, setVal])

  const isValidAmmount = React.useMemo(() => {
    if (new BN(val).gt(fullBalance)) {
      return false
    }
    return true
  }, [val, fullBalance])

  return (
    <Modal
      title={`${t('POOL_36')} ${tokenName} ${t('POOL_37')}`}
      onDismiss={onDismiss}
      style={{ width: isMobile ? '96%' : '440px' }}
    >
      <TokenInput
        value={val}
        onSelectMax={handleSelectMax}
        onChange={handleChange}
        max={fullBalance}
        symbol={tokenName}
      />

      {new BN(max).eq(0) && (
        <Row style={{ alignItems: 'center', justifyContent: 'flex-start', marginTop: '20px' }}>
          <Text color="red" fontSize="14px">
            {t('POOL_38')}
          </Text>
          <LinkExternal href="/swap" style={{ alignSelf: 'flex-start', textAlign: 'left' }} color="#FF3093">
            <Text color="#FF3093" fontSize="14px" style={{ marginLeft: '5px' }}>
              {t('POOL_39')} {tokenName}
            </Text>
          </LinkExternal>
        </Row>
      )}

      {!isValidAmmount && (
        <StyledErrorMessage fontSize="14px" color="failure">
          {t('POOL_40')}
        </StyledErrorMessage>
      )}

      <ModalActions>
        <ModalActionCancelButton width="100%" onClick={onDismiss} disabled={false}>
          {t('POOL_33')}
        </ModalActionCancelButton>
        <ModalActionButton
          width="100%"
          disabled={pendingTx || fullBalance === '0' || val === '0' || !val || !isValidAmmount || new BN(val).lte(0)}
          onClick={async () => {
            setPendingTx(true)
            try {
              await onConfirm(val, stakingTokenDecimals)
              onDismiss()
            } finally {
              setPendingTx(false)
            }
          }}
        >
          {pendingTx ? (
            <span>
              Pending <Elipisis />
            </span>
          ) : (
            t('POOL_35')
          )}
        </ModalActionButton>
      </ModalActions>
    </Modal>
  )
}

export default DepositModal
