/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useRef, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Modal, Text, Flex, Checkbox, TextField, useTooltip, ArrowDownIcon } from 'uikit'
import BigNumber from 'bignumber.js'
import { useTranslation } from 'react-i18next'
import { useWeb3React } from '@web3-react/core'
import { getBalanceNumber } from 'utils/formatBalance'
import RoiCalculatorFooter from './RoiCalculatorFooter'
import RoiCard from './RoiCard'
import useRoiCalculatorReducer, { CalculatorMode, EditingCurrency } from './useRoiCalculatorReducer'
import { AlertCircle as Info } from 'react-feather'
import { useResponsive } from 'hooks/useResponse'

interface RoiCalculatorModalProps {
  onDismiss?: () => void
  onBack?: () => void
  earningTokenPrice: number
  apr: number
  displayApr?: string
  linkLabel: string
  linkHref: string
  stakingTokenBalance: BigNumber
  stakingTokenSymbol: string
  stakingTokenPrice: number
  earningTokenSymbol?: string
  multiplier?: string
  autoCompoundFrequency?: number
  performanceFee?: number
  isFarm?: boolean
  initialValue?: string
  details?: any
}

const StyledModal = styled(Modal)`
  width: 345px;
  & > :nth-child(2) {
    padding: 0;
  }
  ${({ theme }) => theme.mediaQueries.md} {
    width: 440px;
  }
`

const ScrollableContainer = styled.div`
  padding: 15px 24px;
  max-height: 500px;
  overflow-y: auto;
  ${({ theme }) => theme.mediaQueries.sm} {
    max-height: none;
  }
`

const StyledInputContainer = styled.div`
  width: 100%;
  height: 140px;
  background: #f5f5f5;
  border-radius: 12px;
  padding: 20px 24px;
`

const StyledLine = styled.div`
  width: 38%;
  height: 1px;
  opacity: 0.28;
  background: #979797;
`

const StyledButton = styled.div<{ disable?: boolean }>`
  border: 1px solid rgba(115, 126, 141, 0.16);
  border-radius: 16px;
  height: 30px;
  width: 72px;
  font-size: 14px;
  text-align: center;
  line-height: 28px;
  cursor: pointer;
  color: ${({ disable, theme }) => (disable ? theme.colors.borderDark : theme.colors.text)};
  ${({ theme }) => theme.mediaQueries.md} {
    width: 98px;
  }
`

const StyledButtonItem = styled.div<{ isActive: boolean; disabled?: boolean }>`
  background: #efeff2;
  border-radius: 8px;
  height: 44px;
  width: 52px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  line-height: 44px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  color: ${({ isActive, disabled, theme }) =>
    disabled ? theme.colors.borderDark : isActive ? 'white' : theme.colors.text};
  background: ${({ isActive, disabled, theme }) =>
    disabled ? '#EFEFF2' : isActive ? theme.colors.primary : '#EFEFF2'};
  ${({ theme }) => theme.mediaQueries.md} {
    width: 72px;
  }
`

const ImgsArrow = styled.img<{ isRotate: boolean }>`
  width: 20px;
  height: 20px;
  margin: 0 auto;
  transform: rotate(${({ isRotate }) => (isRotate ? '180deg' : '0deg')});
`

const RoiCalculatorModal: React.FC<RoiCalculatorModalProps> = ({
  onDismiss,
  onBack,
  earningTokenPrice,
  apr,
  displayApr,
  linkLabel,
  linkHref,
  stakingTokenBalance,
  stakingTokenSymbol,
  stakingTokenPrice,
  multiplier,
  initialValue,
  earningTokenSymbol = 'SDT',
  autoCompoundFrequency = 0,
  performanceFee = 0,
  isFarm = false,
  details = {},
}) => {
  const { t } = useTranslation()
  const { isMobile } = useResponsive()
  const { account } = useWeb3React()
  const [pos, setPos] = useState(false)
  const balanceInputRef = useRef<HTMLInputElement | null>(null)

  const {
    state,
    setPrincipalFromUSDValue,
    setPrincipalFromTokenValue,
    setStakingDuration,
    toggleCompounding,
    setCompoundingFrequency,
    setCalculatorMode,
    setTargetRoi,
  } = useRoiCalculatorReducer(stakingTokenPrice, earningTokenPrice, apr, autoCompoundFrequency, performanceFee)

  const { compounding, activeCompoundingIndex, stakingDuration, editingCurrency, mode } = state.controls
  const { principalAsUSD, principalAsToken } = state.data
  // Auto-focus input on opening modal
  useEffect(() => {
    if (balanceInputRef.current) {
      balanceInputRef.current.focus()
    }

    const getDetailClickId = document.getElementById('roiFooter')
    const getRoiScrollId = document.getElementById('roiScroll')

    const scrollAction = () => {
      setTimeout(() => {
        getRoiScrollId.scrollTo({
          top: 200,
          behavior: 'smooth',
        })
      })
    }
    getDetailClickId.addEventListener('click', scrollAction)

    return () => getDetailClickId.removeEventListener('click', scrollAction)
  }, [])

  // If user comes to calculator from staking modal - initialize with whatever they put in there
  useEffect(() => {
    if (initialValue) {
      setPrincipalFromTokenValue(initialValue)
    }
  }, [initialValue, setPrincipalFromTokenValue])

  const { targetRef, tooltip, tooltipVisible } = useTooltip(
    isFarm ? t('COMPONENTS_72') : t('COMPONENTS_73', { asset: stakingTokenSymbol }),
    { placement: 'top-end', tooltipOffset: [20, 10] }
  )

  const onBalanceFocus = () => {
    setCalculatorMode(CalculatorMode.ROI_BASED_ON_PRINCIPAL)
  }

  const editingUnit = editingCurrency === EditingCurrency.TOKEN ? stakingTokenSymbol : 'USD'
  const editingValue = editingCurrency === EditingCurrency.TOKEN ? principalAsToken : principalAsUSD
  const conversionUnit = editingCurrency === EditingCurrency.TOKEN ? 'USD' : stakingTokenSymbol
  const conversionValue = editingCurrency === EditingCurrency.TOKEN ? principalAsUSD : principalAsToken

  return (
    <StyledModal title={t('COMPONENTS_213')} onDismiss={onBack || onDismiss} onBack={onBack ?? null}>
      <ScrollableContainer id="roiScroll">
        <Flex flexDirection="column" mb="8px">
          <Text color="textRemark" bold fontSize="16px" mb="10px" textTransform="uppercase">
            {t('COMPONENTS_74', { asset: stakingTokenSymbol })}
          </Text>
          <StyledInputContainer>
            {!pos ? (
              <TextField
                label={editingUnit}
                value={editingValue ?? '0.00'}
                placeholder="0.00"
                onUserInput={setPrincipalFromUSDValue}
                onFocus={onBalanceFocus}
                inputStyles={{ width: '50%', textAlign: 'left', fontSize: isMobile ? '16px' : '20px' }}
              />
            ) : (
              <TextField
                label={conversionUnit}
                value={conversionValue}
                placeholder="0.00"
                onUserInput={setPrincipalFromTokenValue}
                inputStyles={{ width: '50%', textAlign: 'left', fontSize: isMobile ? '16px' : '20px' }}
              />
            )}

            <Flex justifyContent="space-between" alignItems="center" mt="5px" mb="5px">
              <StyledLine />
              <div
                onClick={() => {
                  setPos(!pos)
                }}
              >
                <ArrowDownIcon width="24px" height="24px" color="textSubtle" />
              </div>
              <StyledLine />
            </Flex>
            {pos ? (
              <TextField
                label={editingUnit}
                value={editingValue ?? '0.00'}
                placeholder="0.00"
                onUserInput={setPrincipalFromUSDValue}
                onFocus={onBalanceFocus}
                inputStyles={{ width: '50%', textAlign: 'left', fontSize: isMobile ? '16px' : '20px' }}
              />
            ) : (
              <TextField
                label={conversionUnit}
                value={conversionValue}
                placeholder="0.00"
                onUserInput={setPrincipalFromTokenValue}
                inputStyles={{ width: '50%', textAlign: 'left', fontSize: isMobile ? '16px' : '20px' }}
              />
            )}
          </StyledInputContainer>
          <Flex justifyContent="space-between" alignItems="center" mt="13px">
            <StyledButton
              disable={!account}
              onClick={() => {
                if (!account) return
                setPrincipalFromUSDValue(
                  getBalanceNumber(stakingTokenBalance.times(stakingTokenPrice).times(0.25)).toString()
                )
              }}
            >
              25%
            </StyledButton>
            <StyledButton
              disable={!account}
              onClick={() => {
                if (!account) return
                setPrincipalFromUSDValue(
                  getBalanceNumber(stakingTokenBalance.times(stakingTokenPrice).times(0.5)).toString()
                )
              }}
            >
              50%
            </StyledButton>
            <StyledButton
              style={{ width: isMobile ? '110px' : '154px' }}
              disable={!account}
              onClick={() => {
                if (!account) return
                setPrincipalFromUSDValue(getBalanceNumber(stakingTokenBalance.times(stakingTokenPrice)).toString())
              }}
            >
              {t('COMPONENTS_75')}
            </StyledButton>
            <span ref={targetRef}>
              <Info width="16px" height="16px" color="#494E67" />
            </span>
            {tooltipVisible && tooltip}
          </Flex>
          <Text mt="24px" color="textRemark" fontSize="16px" mb="5px">
            {t('COMPONENTS_214')}
          </Text>
          <Flex justifyContent="space-between">
            {['1D', '7D', '30D', '1Y', '5Y'].map((item, index) => {
              return (
                <StyledButtonItem isActive={stakingDuration === index} onClick={() => setStakingDuration(index)}>
                  {item}
                </StyledButtonItem>
              )
            })}
          </Flex>

          {autoCompoundFrequency === 0 && (
            <>
              <Flex justifyContent="space-between" alignItems="center" mt="24px" mb="5px">
                <Text color="textRemark" fontSize="16px">
                  {t('COMPONENTS_215')}
                </Text>
                <Checkbox scale="ss" checked={compounding} onChange={toggleCompounding} />
              </Flex>
              <Flex justifyContent="space-between" mb="24px">
                {['1D', '7D', '14D', '30D'].map((item, index) => {
                  return (
                    <StyledButtonItem
                      disabled={!compounding}
                      isActive={activeCompoundingIndex === index}
                      onClick={() => {
                        if (compounding) {
                          setCompoundingFrequency(index)
                        }
                      }}
                      style={{ width: isMobile ? '62px' : '92px' }}
                    >
                      {item}
                    </StyledButtonItem>
                  )
                })}
              </Flex>

              <ImgsArrow
                src={require('assets/images/farm/arrow-down.svg').default}
                isRotate={mode !== CalculatorMode.ROI_BASED_ON_PRINCIPAL}
              />
            </>
          )}
        </Flex>
        <Flex mt="24px">
          <RoiCard
            earningTokenSymbol={earningTokenSymbol}
            calculatorState={state}
            setTargetRoi={setTargetRoi}
            setCalculatorMode={setCalculatorMode}
          />
        </Flex>
      </ScrollableContainer>
      <RoiCalculatorFooter
        isFarm={isFarm}
        apr={apr}
        displayApr={displayApr}
        autoCompoundFrequency={autoCompoundFrequency}
        multiplier={multiplier}
        linkLabel={linkLabel}
        linkHref={linkHref}
        performanceFee={performanceFee}
        details={details}
        clickId="roiFooter"
      />
    </StyledModal>
  )
}

export default RoiCalculatorModal
