/* eslint-disable import/no-named-as-default */
import { darken } from 'polished'
import React, { useState } from 'react'
import { ChevronDown, ChevronUp } from 'react-feather'
import { Link, useHistory } from 'react-router-dom'
import { ETHER, JSBI, Pair, Percent, WETH } from 'shardex-sdk'
import styled, { useTheme } from 'styled-components'
import { Button, Card as UIKitCard, CardBody, Text } from '../../uikit'

import { useTotalSupply } from '../../data/TotalSupply'

import { useResponsive } from 'hooks/useResponse'
import { useTranslation } from 'react-i18next'
import { useActiveWeb3React } from '../../hooks'
import { useTokenBalance } from '../../state/wallet/hooks'
import { currencyId } from '../../utils/currencyId'
import { unwrappedToken } from '../../utils/wrappedCurrency'
import Card from '../Card'
import { AutoColumn } from '../Column'
import DoubleCurrencyLogo from '../DoubleLogo'
import Row, { RowBetween, RowFixed } from '../Row'
import { Dots } from '../swap/styleds'

export const FixedHeightRow = styled(RowBetween)`
  height: 12px;
`

export const HoverCard = styled(Card)`
  border: 1px solid ${({ theme }) => darken(0.15, theme.colors.invertedContrast)};
  border-radius: 12px;
  user-select: none;
`

export const CardBtn = styled(Button)<{ isPrimary?: boolean }>`
  height: 48px;
  width: 100%;
  background: ${({ theme, isPrimary }) => (isPrimary ? theme.colors.primary : theme.colors.invertedContrast)};
  color: ${({ theme, isPrimary }) => (isPrimary ? 'white' : theme.colors.primary)};
  font-weight: 500;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: 25px;
  box-shadow: none;
  :hover {
    color: ${({ theme, isPrimary }) => (isPrimary ? 'white' : theme.colors.primary)};
  }
`

export const CardBodyFeat = styled(CardBody)`
  width: 416px;
  margin: 0 auto 20px auto;
  padding: 22px 20px;
  border: 1px solid rgba(115, 126, 141, 0.16);
  border-radius: 12px;
  @media (max-width: 768px) {
    max-width: 300px;
  }
`

interface PositionCardProps {
  pair: Pair
  // eslint-disable-next-line react/no-unused-prop-types
  showUnwrapped?: boolean
  // eslint-disable-next-line react/no-unused-prop-types
  removeOnly?: boolean
  // eslint-disable-next-line react/no-unused-prop-types
  width?: string
}

export function MinimalPositionCard({ pair, showUnwrapped = false, width }: PositionCardProps) {
  const { account, chainId } = useActiveWeb3React()
  const { isMobile } = useResponsive()

  const theme: any = useTheme()

  const currency0 = showUnwrapped ? unwrappedToken(pair.token0) : pair.token0
  const currency1 = showUnwrapped ? unwrappedToken(pair.token1) : pair.token1

  const [showMore, setShowMore] = useState(false)

  const userPoolBalance = useTokenBalance(account ?? undefined, pair.liquidityToken)
  const totalPoolTokens = useTotalSupply(pair.liquidityToken)
  const [token0Deposited, token1Deposited] =
    !!pair &&
    !!totalPoolTokens &&
    !!userPoolBalance &&
    // this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
    JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? [
          pair.getLiquidityValue(pair.token0, totalPoolTokens, userPoolBalance, false),
          pair.getLiquidityValue(pair.token1, totalPoolTokens, userPoolBalance, false),
        ]
      : [undefined, undefined]

  return (
    <>
      {userPoolBalance && (
        <UIKitCard
          style={{
            background: 'rgba(255,255,255,0.9)',
            borderRadius: '16px',
            width: width || (isMobile ? '100%' : '480px'),
          }}
        >
          {/* <Text
            style={{
              padding: 0,
              width: '100%',
              height: '40px',
              lineHeight: '50px',
              margin: '8px 0 10px 0',
              textTransform: 'uppercase',
              fontWeight: 700,
              textAlign: 'center',
              color: `${theme.colors.text}`,
            }}
            fontSize="16px"
            color="text"
          >
            {t('COMPONENTS_64')}
          </Text> */}

          <CardBodyFeat>
            <AutoColumn gap="12px" style={{ width: '100%', padding: '0' }}>
              <FixedHeightRow onClick={() => setShowMore(!showMore)}>
                <RowFixed>
                  <DoubleCurrencyLogo currency0={currency0} currency1={currency1} margin size={24} />
                  <Text fontSize="14px" fontWeight={500} color="text" ml="-8px">
                    {currency0.symbol}/{currency1.symbol}
                  </Text>
                </RowFixed>
                <RowFixed>
                  <Text fontSize="14px" color={theme.colors.primary} fontWeight={700}>
                    {userPoolBalance ? userPoolBalance.toSignificant(4) : '-'}
                  </Text>
                </RowFixed>
              </FixedHeightRow>
              <AutoColumn gap="16px" style={{ padding: '0', marginTop: '15px' }}>
                <FixedHeightRow>
                  <Text fontSize="14px" color="textRemark" fontWeight={500}>
                    {currency0.symbol === WETH[chainId].symbol ? ETHER.symbol : currency0.symbol}:
                  </Text>
                  {token0Deposited ? (
                    <RowFixed>
                      <Text ml="6px" color="text" fontSize="14px" fontWeight={500}>
                        {token0Deposited?.toSignificant(6)}
                      </Text>
                    </RowFixed>
                  ) : (
                    '-'
                  )}
                </FixedHeightRow>
                <FixedHeightRow>
                  <Text fontSize="14px" color="textRemark" fontWeight={500}>
                    {currency1.symbol}:
                  </Text>
                  {token1Deposited ? (
                    <RowFixed>
                      <Text ml="6px" fontSize="14px" color="text" fontWeight={500}>
                        {token1Deposited?.toSignificant(6)}
                      </Text>
                    </RowFixed>
                  ) : (
                    '-'
                  )}
                </FixedHeightRow>
              </AutoColumn>
            </AutoColumn>
          </CardBodyFeat>
        </UIKitCard>
      )}
    </>
  )
}

export default function FullPositionCard({ pair, removeOnly }: PositionCardProps) {
  const { account } = useActiveWeb3React()
  const { t } = useTranslation()
  const history = useHistory()

  const theme: any = useTheme()
  const currency0 = unwrappedToken(pair.token0)
  const currency1 = unwrappedToken(pair.token1)

  const [showMore, setShowMore] = useState(false)

  const userPoolBalance = useTokenBalance(account ?? undefined, pair.liquidityToken)
  const totalPoolTokens = useTotalSupply(pair.liquidityToken)

  const poolTokenPercentage =
    !!userPoolBalance && !!totalPoolTokens && JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? new Percent(userPoolBalance.raw, totalPoolTokens.raw)
      : undefined

  const [token0Deposited, token1Deposited] =
    !!pair &&
    !!totalPoolTokens &&
    !!userPoolBalance &&
    // this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
    JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? [
          pair.getLiquidityValue(pair.token0, totalPoolTokens, userPoolBalance, false),
          pair.getLiquidityValue(pair.token1, totalPoolTokens, userPoolBalance, false),
        ]
      : [undefined, undefined]

  return (
    <HoverCard style={{ width: '100%', marginBottom: '10px', background: '#EFEFF2', border: 'none' }}>
      <AutoColumn gap="10px" style={{ width: '100%' }}>
        <FixedHeightRow onClick={() => setShowMore(!showMore)} style={{ cursor: 'pointer' }}>
          <RowFixed>
            <DoubleCurrencyLogo currency0={currency0} currency1={currency1} margin size={24} />
            <Text fontSize="16px" style={{ color: `${theme.colors.text}`, fontWeight: 500 }}>
              {!currency0 || !currency1 ? <Dots>Loading</Dots> : `${currency0.symbol}/${currency1.symbol}`}
            </Text>
          </RowFixed>
          <RowFixed style={{ justifySelf: 'flex-end' }}>
            {showMore ? (
              <ChevronUp size="20" style={{ marginLeft: '10px' }} />
            ) : (
              <ChevronDown size="20" style={{ marginLeft: '10px' }} />
            )}
          </RowFixed>
        </FixedHeightRow>
        {showMore && (
          <>
            <FixedHeightRow style={{ margin: '15px 0 0 0' }}>
              <Row>
                <Text color={theme.colors.text} fontSize="16px" style={{ fontWeight: 500, marginRight: '5px' }}>
                  {t('COMPONENTS_65')}
                </Text>
                <Text color={theme.colors.primary} fontSize="16px" style={{ fontWeight: 700 }}>
                  {userPoolBalance ? userPoolBalance.toSignificant(4) : '-'}
                </Text>
              </Row>
            </FixedHeightRow>
            <AutoColumn
              gap="12px"
              style={{
                padding: '24px',
                marginTop: '6px',
                background: `white`,
                borderRadius: '8px',
              }}
            >
              <FixedHeightRow>
                <RowFixed>
                  <Text fontSize="14px" color="#7F8393" style={{ fontWeight: 500 }}>
                    {t('COMPONENTS_66', { asset: currency0.symbol })}
                  </Text>
                </RowFixed>
                {token0Deposited ? (
                  <RowFixed>
                    <Text fontSize="14px" style={{ fontWeight: 500 }} ml="6px">
                      {token0Deposited?.toSignificant(6)}
                    </Text>
                  </RowFixed>
                ) : (
                  '-'
                )}
              </FixedHeightRow>

              <FixedHeightRow>
                <RowFixed>
                  <Text fontSize="14px" color="#7F8393" style={{ fontWeight: 500 }}>
                    {t('COMPONENTS_66', { asset: currency1.symbol })}
                  </Text>
                </RowFixed>
                {token1Deposited ? (
                  <RowFixed>
                    <Text fontSize="14px" style={{ fontWeight: 500 }} ml="6px">
                      {token1Deposited?.toSignificant(6)}
                    </Text>
                  </RowFixed>
                ) : (
                  '-'
                )}
              </FixedHeightRow>
              <FixedHeightRow>
                <Text fontSize="14px" color="#7F8393" style={{ fontWeight: 500 }}>
                  {t('COMPONENTS_67')}
                </Text>
                <Text fontSize="14px" style={{ fontWeight: 500 }}>
                  {poolTokenPercentage ? `${poolTokenPercentage.toFixed(2)}%` : '-'}
                </Text>
              </FixedHeightRow>
            </AutoColumn>
            <RowBetween marginTop="10px">
              {removeOnly && (
                <CardBtn
                  as={Link}
                  isPrimary={true}
                  to={`/add/${currencyId(currency0)}/${currencyId(currency1)}`}
                  style={{ marginRight: '10px' }}
                >
                  {t('COMPONENTS_68')}
                </CardBtn>
              )}
              <CardBtn
                as={Link}
                isPrimary={true}
                to={`/add/${currencyId(currency0)}/${currencyId(currency1)}`}
                style={{ marginRight: '10px' }}
              >
                {t('COMPONENTS_68')}
              </CardBtn>
              <CardBtn as={Link} to={`/remove/${currencyId(currency0)}/${currencyId(currency1)}`}>
                {t('COMPONENTS_69')}
              </CardBtn>
            </RowBetween>
            <AutoColumn justify="center" style={{ cursor: 'pointer', marginTop: '10px' }}>
              <Text
                fontSize="16px"
                color={theme.colors.primary}
                onClick={() => {
                  history.push(`/add/${pair.token0.address}/${pair.token1.address}`)
                }}
              >
                {t('COMPONENTS_70')}
              </Text>
            </AutoColumn>
          </>
        )}
      </AutoColumn>
    </HoverCard>
  )
}
