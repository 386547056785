import { useWeb3React } from '@web3-react/core'
import { ethers } from 'ethers'
import React, { useCallback } from 'react'
import { useAppDispatch } from 'state'
import { fetchFarmV2UserDataAsync, updateUserAllowance } from 'state/actions'
import { approve } from 'utils/callHelpers'
import { getVaultV2Contract } from 'utils/contractHelpers'
import { Contract } from 'web3-eth-contract'
import { getMasterchefV2Contract } from '../utils/contractHelpers'
import { useMasterchefV2, useSousChef } from './useContract'

// Approve a Farm
export const useApprove = (lpContract: Contract) => {
  const dispatch = useAppDispatch()
  const { account } = useWeb3React()

  const masterChefV2Contract = useMasterchefV2()

  const handleApprove = useCallback(async () => {
    try {
      const tx = await approve(lpContract, masterChefV2Contract, account)

      dispatch(fetchFarmV2UserDataAsync(account))

      return tx
    } catch (e) {
      return false
    }
  }, [account, dispatch, lpContract, masterChefV2Contract])

  return { onApprove: handleApprove }
}

// Approve a Pool
export const useSousApprove = (lpContract: Contract, sortOrder, sousId) => {
  const dispatch = useAppDispatch()
  const { account } = useWeb3React()

  const sousChefContract = useSousChef(sousId)

  const masterchefContract = getMasterchefV2Contract()

  const vaultContract = getVaultV2Contract()

  const contract = React.useMemo(() => {
    return sousId === 0 ? (sortOrder === 1 ? vaultContract : masterchefContract) : sousChefContract
  }, [sortOrder, sousId, vaultContract, masterchefContract, sousChefContract])

  const handleApprove = useCallback(async () => {
    try {
      const tx = await approve(lpContract, contract, account)

      dispatch(updateUserAllowance(sortOrder, account as string) as any)
      return tx
    } catch (e) {
      return false
    }
  }, [lpContract, contract, account, dispatch, sortOrder])

  return { onApprove: handleApprove }
}

// Approve the lottery
/* export const useLotteryApprove = () => {
  const { account } = useWeb3React()
  const cakeContract = useSDT()
  const lotteryContract = useLottery()

  const handleApprove = useCallback(async () => {
    try {
      const tx = await approve(cakeContract, lotteryContract, account)
      return tx
    } catch (e) {
      return false
    }
  }, [account, cakeContract, lotteryContract]) 

  return { onApprove: handleApprove }
}
*/

// Approve an IFO
export const useIfoApprove = (tokenContract: Contract, spenderAddress: string) => {
  const { account } = useWeb3React()
  const onApprove = useCallback(async () => {
    const tx = await tokenContract.methods.approve(spenderAddress, ethers.constants.MaxUint256).send({ from: account })
    return tx
  }, [account, spenderAddress, tokenContract])

  return onApprove
}
