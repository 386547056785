import React from 'react'
import styled, { DefaultTheme } from 'styled-components'
import { Text } from '../Text'
import Flex from '../Box/Flex'
import { AlertProps, variants } from './types'

interface ThemedIconLabel {
  variant: AlertProps['variant']
  theme: DefaultTheme
  hasDescription: boolean
}

const Icons = {
  success: require('assets/images/icon/toast-success.svg').default,
  error: require('assets/images/icon/toast-error.svg').default,
  warning: require('assets/images/icon/toast-info.svg').default,
}

const StyledIcon = styled.img`
  width: 64px;
  height: 64px;
`

const getIcon = (variant: AlertProps['variant'] = variants.INFO) => {
  switch (variant) {
    case variants.DANGER:
      return <StyledIcon src={Icons.error} />
    case variants.WARNING:
      return <StyledIcon src={Icons.error} />
    case variants.SUCCESS:
      return <StyledIcon src={Icons.success} />
    case variants.INFO:
    default:
      return <StyledIcon src={Icons.warning} />
  }
}

const IconLabel = styled.div<ThemedIconLabel>`
  background-color: white;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
  color: ${({ theme }) => theme.alert.background};
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const withHandlerSpacing = 32 + 12 + 8 // button size + inner spacing + handler position
const Details = styled.div<{ hasHandler: boolean }>`
  flex: 1;
  padding-bottom: 20px;
  padding-right: ${({ hasHandler }) => (hasHandler ? `${withHandlerSpacing}px` : '20px')};
  padding-top: 20px;
  display: flex;
  align-items: center;
`

const StyledAlert = styled(Flex)`
  position: relative;
  background-color: ${({ theme }) => theme.alert.background};
  border-radius: 24px;
  box-shadow: 0px 20px 36px -8px rgba(14, 14, 44, 0.1), 0px 1px 1px rgba(0, 0, 0, 0.05);
`

const Alert: React.FC<AlertProps> = ({ title, children, variant, onClick }) => {
  return (
    <StyledAlert>
      <IconLabel variant={variant} hasDescription={!!children}>
        {getIcon(variant)}
      </IconLabel>
      <Details hasHandler={!!onClick}>
        <Text fontWeight="500">{title}</Text>
        {typeof children === 'string' ? (
          <Text fontSize="14px" as="p">
            {children}
          </Text>
        ) : (
          children
        )}
      </Details>
      {/* {onClick && (
        <CloseHandler>
          <IconButton scale="sm" variant="text" onClick={onClick}>
            <CloseIcon width="24px" color="currentColor" />
          </IconButton>
        </CloseHandler>
      )} */}
    </StyledAlert>
  )
}

export default Alert
