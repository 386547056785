import React from 'react'
import { Box, Button, Flex, Text, Input, InputProps } from 'uikit'
import BN from 'bignumber.js'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

export const getDisplayBalance = (balance: any) => {
  const displayBalance = new BN(balance)
  if (displayBalance.gt(0) && displayBalance.lte(0.01)) {
    return '< 0.01'
  }
  return new BN(new BN(displayBalance).toFixed(2, 1)).toString(10)
}

interface TokenInputProps extends InputProps {
  max: number | string
  symbol: string
  value: string
  onSelectMax?: () => void
  onChange: (evt: React.FormEvent<HTMLInputElement>) => void
}

const MaxButton = styled(Button)`
  background: #e1faf4;
  width: 42px;
  height: 22px;
  color: #ff3093;
  border-radius: 4px;
  box-shadow: none;
  font-size: 14px;
  cursor: pointer;
`

const TokenInput: React.FC<TokenInputProps> = ({ max, symbol, onChange, onSelectMax, value }) => {
  const { t } = useTranslation()

  return (
    <Box style={{ border: ' 1px solid rgba(115, 126, 141, 0.16)', borderRadius: '12px', padding: '24px' }}>
      <Flex justifyContent="space-between" minHeight="21px" mb="8px">
        <Text color="#666" fontSize="14px">
          {t('COMPONENTS_141')}
        </Text>
        <Text color="#666" fontSize="14px">
          {t('COMPONENTS_142')}
          {getDisplayBalance(new BN(max))}
        </Text>
      </Flex>
      <Flex alignItems="center">
        <Input onChange={onChange} placeholder="0" value={value} style={{ border: 'none', padding: '0' }} />
        <Flex alignItems="center">
          <div>
            <MaxButton scale="sm" onClick={onSelectMax}>
              <Text color="#FF3093" fontSize="14px">
                {t('COMPONENTS_31')}
              </Text>
            </MaxButton>
          </div>
          <Text color="#01142A" textTransform="uppercase" mx="8px">
            {symbol}
          </Text>
        </Flex>
      </Flex>
    </Box>
  )
}

export default TokenInput
