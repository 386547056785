import { Currency, ETHER, Token } from 'shardex-sdk'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import { WrappedTokenInfo } from '../../state/lists/hooks'
import Logo from '../Logo'
import CoinLogo from '../pancake/CoinLogo'
import { getTokenUrl } from '../../utils/getTokenUrl'

const StyledLogo = styled(Logo)<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
`

export default function CurrencyLogo({
  currency,
  size = '24px',
  style,
}: {
  currency?: Currency
  size?: string
  style?: React.CSSProperties
}) {
  const srcs: string[] = useMemo(() => {
    if (currency === ETHER) return []
    if (currency instanceof Token) {
      if (currency instanceof WrappedTokenInfo) {
        return [getTokenUrl(currency?.symbol as any)]
      }
      return [getTokenUrl(currency?.address)]
    }
    return [getTokenUrl(currency?.symbol as string)]
  }, [currency])

  if (currency === ETHER) {
    return <CoinLogo srcs={[getTokenUrl(ETHER.symbol.toLowerCase())]} size={size} style={style} />
  }

  return (currency as any)?.symbol ? (
    <CoinLogo size={size} srcs={srcs} alt={`${currency?.symbol ?? 'token'} logo`} style={style} />
  ) : (
    <StyledLogo size={size} srcs={srcs} alt={`${currency?.symbol ?? 'token'} logo`} style={style} />
  )
}
