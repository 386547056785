import AddressInputPanel from 'components/AddressInputPanel'
import Card from 'components/Card'
import { AutoColumn } from 'components/Column'
import Container from 'components/Container'
import CurrencyInputPanel from 'components/CurrencyInputPanel'
import ProgressSteps from 'components/ProgressSteps'
import { AutoRow, RowBetween } from 'components/Row'
import AdvancedSwapDetailsDropdown from 'components/swap/AdvancedSwapDetailsDropdown'
import confirmPriceImpactWithoutFee from 'components/swap/confirmPriceImpactWithoutFee'
import ConfirmSwapModal from 'components/swap/ConfirmSwapModal'
import { ArrowWrapper, BottomGrouping, SwapCallbackError, Wrapper } from 'components/swap/styleds'
import TradePrice from 'components/swap/TradePrice'
import SyrupWarningModal from 'components/SyrupWarningModal'
import TokenWarningModal from 'components/TokenWarningModal'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { ArrowDown } from 'react-feather'
import { CurrencyAmount, JSBI, Token, Trade } from 'shardex-sdk'
import styled, { ThemeContext } from 'styled-components'
import { ArrowDownIcon, CardBody, IconButton, Text } from '../../uikit'

import ConnectWalletButton from 'components/ConnectWalletButton'
import Loader from 'components/Loader'
import Meta from 'components/Meta'
import PageHeader from 'components/PageHeader'
import { LinkStyledButton } from 'components/Shared'
import { useActiveWeb3React } from 'hooks'
import { useCurrency } from 'hooks/Tokens'
import { ApprovalState, useApproveCallbackFromTrade } from 'hooks/useApproveCallback'
import { useSwapCallback } from 'hooks/useSwapCallback'
import useWrapCallback, { WrapType } from 'hooks/useWrapCallback'
import { Field } from 'state/swap/actions'
import { useDefaultsFromURLSearch, useDerivedSwapInfo, useSwapActionHandlers, useSwapState } from 'state/swap/hooks'
import { useExpertModeManager, useUserDeadline, useUserSlippageTolerance } from 'state/user/hooks'
import clickEvent from 'utils/gtag'
import { maxAmountSpend } from 'utils/maxAmountSpend'
import { computeTradePriceBreakdown, warningSeverity } from 'utils/prices'
import { useResponsive } from '../../hooks/useResponse'
import { useAnalysisChartVisible } from '../../state/user/hooks'
import AppBody from '../AppBody'
// import PairChart from '../../components/PairChart'
import { CenterBox } from 'components'
import tokens from 'constants/tokens'
import { useTranslation } from 'react-i18next'
import { getAddress } from 'utils/addressHelpers'
import SelectTab from 'components/PageHeader/SelectTab'
import { AnimationButton } from 'components/Button'

const SwapCurrencyContainer = styled(AutoColumn)`
  padding: 0px;
`

export const ThroughLine = styled.div`
  width: 94%;
  height: 1px;
  opacity: 0.27;
  background: #979797;
  position: absolute;
  top: 2px;
  left: 3%;
`


const Swap = () => {
  const loadedUrlParams = useDefaultsFromURLSearch()
  const { t } = useTranslation()

  // token warning stuff
  const [loadedInputCurrency, loadedOutputCurrency] = [
    useCurrency(loadedUrlParams?.inputCurrencyId),
    useCurrency(loadedUrlParams?.outputCurrencyId),
  ]
  const [dismissTokenWarning, setDismissTokenWarning] = useState<boolean>(false)
  const [transactionWarning, setTransactionWarning] = useState<{
    selectedToken: string | null
    purchaseType: string | null
  }>({
    selectedToken: null,
    purchaseType: null,
  })
  const urlLoadedTokens: Token[] = useMemo(
    () => [loadedInputCurrency, loadedOutputCurrency]?.filter((c): c is Token => c instanceof Token) ?? [],
    [loadedInputCurrency, loadedOutputCurrency]
  )

  const tokenNotInSDTSwap = React.useMemo(() => {
    const tokenNotInSDTSwapList = [...urlLoadedTokens]
    const keys = Reflect.ownKeys(tokens)
    for (let j = 0; j < urlLoadedTokens.length; j++) {
      const urlToken = urlLoadedTokens[j].address.toLowerCase()

      for (let i = 0; i < keys.length; i++) {
        const token = tokens[keys[i]]
        const tokenAddress = getAddress(token.address).toLowerCase()
        if (urlToken === tokenAddress) {
          tokenNotInSDTSwapList.splice(j, 1, null)
          break
        }
      }
    }
    return tokenNotInSDTSwapList.filter((list) => list)
  }, [urlLoadedTokens])

  const handleConfirmTokenWarning = useCallback(() => {
    setDismissTokenWarning(true)
  }, [])

  const handleConfirmWarning = () => {
    setTransactionWarning({
      selectedToken: null,
      purchaseType: null,
    })
  }

  const { account } = useActiveWeb3React()

  // @ts-ignore
  const theme = useContext(ThemeContext)

  const [isExpertMode] = useExpertModeManager()

  // get custom setting values for user
  const [deadline] = useUserDeadline()
  const [allowedSlippage] = useUserSlippageTolerance()

  // swap state
  const { independentField, typedValue, recipient } = useSwapState()
  const { v2Trade, currencyBalances, parsedAmount, currencies, inputError: swapInputError } = useDerivedSwapInfo()
  const {
    wrapType,
    execute: onWrap,
    inputError: wrapInputError,
  } = useWrapCallback(currencies[Field.INPUT], currencies[Field.OUTPUT], typedValue)
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE
  const trade = showWrap ? undefined : v2Trade

  const parsedAmounts = showWrap
    ? {
        [Field.INPUT]: parsedAmount,
        [Field.OUTPUT]: parsedAmount,
      }
    : {
        [Field.INPUT]: independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
        [Field.OUTPUT]: independentField === Field.OUTPUT ? parsedAmount : trade?.outputAmount,
      }

  const { onSwitchTokens, onCurrencySelection, onUserInput, onChangeRecipient } = useSwapActionHandlers()
  const isValid = !swapInputError
  const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT

  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, value)
    },
    [onUserInput]
  )

  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value)
    },
    [onUserInput]
  )

  // useEffect(() => {
  //   handleTypeInput('SHM')
  // }, [])

  // modal and loading
  const [{ showConfirm, tradeToConfirm, swapErrorMessage, attemptingTxn, txHash }, setSwapState] = useState<{
    showConfirm: boolean
    tradeToConfirm: Trade | undefined
    attemptingTxn: boolean
    swapErrorMessage: string | undefined
    txHash: string | undefined
  }>({
    showConfirm: false,
    tradeToConfirm: undefined,
    attemptingTxn: false,
    swapErrorMessage: undefined,
    txHash: undefined,
  })

  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: showWrap
      ? parsedAmounts[independentField]?.toExact() ?? ''
      : parsedAmounts[dependentField]?.toSignificant(6) ?? '',
  }

  const route = trade?.route
  const userHasSpecifiedInputOutput = Boolean(
    currencies[Field.INPUT] && currencies[Field.OUTPUT] && parsedAmounts[independentField]?.greaterThan(JSBI.BigInt(0))
  )
  const noRoute = !route

  // check whether the user has approved the router on the input token
  const [approval, approveCallback] = useApproveCallbackFromTrade(trade, allowedSlippage)

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false)

  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect(() => {
    if (approval === ApprovalState.PENDING) {
      setApprovalSubmitted(true)
    }
  }, [approval, approvalSubmitted])

  const maxAmountInput: CurrencyAmount | undefined = maxAmountSpend(currencyBalances[Field.INPUT])
  const atMaxAmountInput = Boolean(maxAmountInput && parsedAmounts[Field.INPUT]?.equalTo(maxAmountInput))

  // the callback to execute the swap
  const { callback: swapCallback, error: swapCallbackError } = useSwapCallback(
    trade,
    allowedSlippage,
    deadline,
    recipient
  )

  const { priceImpactWithoutFee } = computeTradePriceBreakdown(trade)

  const handleSwap = useCallback(() => {
    if (priceImpactWithoutFee && !confirmPriceImpactWithoutFee(priceImpactWithoutFee)) {
      return
    }
    if (!swapCallback) {
      return
    }
    setSwapState((prevState) => ({
      ...prevState,
      attemptingTxn: true,
      swapErrorMessage: undefined,
      txHash: undefined,
    }))
    clickEvent('swap')
    swapCallback()
      .then((hash) => {
        setSwapState((prevState) => ({
          ...prevState,
          attemptingTxn: false,
          swapErrorMessage: undefined,
          txHash: hash,
        }))
      })
      .catch((error) => {
        setSwapState((prevState) => ({
          ...prevState,
          attemptingTxn: false,
          swapErrorMessage: error.message,
          txHash: undefined,
        }))
      })
  }, [priceImpactWithoutFee, swapCallback, setSwapState])

  // errors
  const [showInverted, setShowInverted] = useState<boolean>(false)

  // warnings on slippage
  const priceImpactSeverity = warningSeverity(priceImpactWithoutFee)

  // show approve flow when: no error on inputs, not approved or pending, or approved in current session
  // never show if price impact is above threshold in non expert mode
  const showApproveFlow =
    !swapInputError &&
    (approval === ApprovalState.NOT_APPROVED ||
      approval === ApprovalState.PENDING ||
      (approvalSubmitted && approval === ApprovalState.APPROVED)) &&
    !(priceImpactSeverity > 3 && !isExpertMode)

  const handleConfirmDismiss = useCallback(() => {
    setSwapState((prevState) => ({ ...prevState, showConfirm: false }))

    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.INPUT, '')
    }
  }, [onUserInput, txHash, setSwapState])

  const handleAcceptChanges = useCallback(() => {
    setSwapState((prevState) => ({ ...prevState, tradeToConfirm: trade }))
  }, [trade])

  // This will check to see if the user has selected Syrup or SafeMoon to either buy or sell.
  // If so, they will be alerted with a warning message.
  const checkForWarning = useCallback(
    (selected: string, purchaseType: string) => {
      if (['SYRUP', 'SAFEMOON'].includes(selected)) {
        setTransactionWarning({
          selectedToken: selected,
          purchaseType,
        })
      }
    },
    [setTransactionWarning]
  )

  const handleInputSelect = useCallback(
    (inputCurrency) => {
      setApprovalSubmitted(false) // reset 2 step UI for approvals
      onCurrencySelection(Field.INPUT, inputCurrency)
      if (inputCurrency.symbol === 'SYRUP') {
        checkForWarning(inputCurrency.symbol, 'Selling')
      }
      if (inputCurrency.symbol === 'SAFEMOON') {
        checkForWarning(inputCurrency.symbol, 'Selling')
      }
      clickEvent('trade_on')
    },
    [onCurrencySelection, setApprovalSubmitted, checkForWarning]
  )

  const handleMaxInput = useCallback(() => {
    if (maxAmountInput) {
      onUserInput(Field.INPUT, maxAmountInput.toExact())
    }
  }, [maxAmountInput, onUserInput])

  const handleOutputSelect = useCallback(
    (outputCurrency) => {
      onCurrencySelection(Field.OUTPUT, outputCurrency)
      if (outputCurrency.symbol === 'SYRUP') {
        checkForWarning(outputCurrency.symbol, 'Buying')
      }
      if (outputCurrency.symbol === 'SAFEMOON') {
        checkForWarning(outputCurrency.symbol, 'Buying')
      }
      clickEvent('trade_out')
    },
    [onCurrencySelection, checkForWarning]
  )

  const { isMobile } = useResponsive()
  const showChart = useAnalysisChartVisible()


  // const pairAddressOrTokenAddress = React.useMemo(() => {
  //   if (
  //     (swap.INPUT?.symbol === 'SHM' && swap.OUTPUT?.symbol === 'WSHM') ||
  //     (swap.INPUT?.symbol === 'WSHM' && swap.OUTPUT?.symbol === 'SHM')
  //   ) {
  //     return ''
  //   }

  //   if (chartMode === 1) {
  //     if (swap.INPUT.currencyId) {
  //       return swap.INPUT.currencyId !== 'SHM' ? swap.INPUT.currencyId : WETH[ChainId.MAINNET].address
  //     }
  //     return swap.OUTPUT.currencyId !== 'SHM' ? swap.OUTPUT.currencyId : WETH[ChainId.MAINNET].address
  //   }

  //   if (chartMode === 2) {
  //     const inputToken = new Token(ChainId.MAINNET, swap.INPUT.currencyId !== 'SHM' ? swap.INPUT.currencyId : WETH[ChainId.MAINNET].address, 18)
  //     const outputToken = new Token(
  //       ChainId.MAINNET,
  //       swap.OUTPUT.currencyId !== 'SHM' ? swap.OUTPUT.currencyId : WETH[ChainId.MAINNET].address,
  //       18
  //     )

  //     return Pair.getAddress(inputToken, outputToken, ChainId.MAINNET).toLocaleLowerCase()
  //   }
  //   return ''
  // }, [chartMode, swap.INPUT.currencyId, swap.INPUT?.symbol, swap.OUTPUT.currencyId, swap.OUTPUT?.symbol])

  // React.useEffect(() => {
  //   const url = window.location.href.toLowerCase()
  //   if (url && !url.includes('inputcurrency') && !swap.INPUT.currencyId) {
  //     handleInputSelect(ETHER)
  //   }
  //   if (url && !url.includes('outputcurrency') && !swap.OUTPUT.currencyId) {
  //     handleOutputSelect(SDT[ChainId.MAINNET])
  //   }
  // }, [chainId, handleInputSelect, handleOutputSelect, loadedUrlParams, swap.INPUT.currencyId, swap.OUTPUT.currencyId])

  const showRecipient = React.useMemo(() => {
    return recipient !== null && !showWrap
  }, [recipient, showWrap])

  React.useEffect(() => {
    if (!isExpertMode) {
      onChangeRecipient(null)
    }
  }, [isExpertMode, onChangeRecipient])

  return (
    <Container>
      <Meta title="Exchange | Shardex" />
      <TokenWarningModal
        isOpen={tokenNotInSDTSwap.length > 0 && !dismissTokenWarning}
        // isOpen={true}
        tokens={tokenNotInSDTSwap}
        onConfirm={handleConfirmTokenWarning}
      />
      <SyrupWarningModal
        isOpen={transactionWarning.selectedToken === 'SYRUP'}
        transactionType={transactionWarning.purchaseType}
        onConfirm={handleConfirmWarning}
      />
      {/* <SafeMoonWarningModal isOpen={transactionWarning.selectedToken === 'SAFEMOON'} onConfirm={handleConfirmWarning} /> */}
      {/* {!showChart && <CardNav />} */}
      <Text color="white" fontSize="32px" bold mt="128px" mb="40px">
        One-stop-shop Decentralized Trading
      </Text>
      {/* <SwapChartWrap showChart={showChart}> */}
      {/* {showChart && chartMode === 2 && pairAddressOrTokenAddress && (
          <PairChart
            onSwitchTokens={onSwitchTokens}
            chartMode={chartPairMode}
            setChartMode={setChartPairMode}
            pairAddress={pairAddressOrTokenAddress}
          />
        )} */}
      {/* {showChart && chartMode !== 2 && <ChartLoading />} */}
      {/* {showChart && chartMode === 1 && <TokenChart tokenAddress={pairAddressOrTokenAddress} />} */}
      <CenterBox style={{ marginTop: showChart && isMobile ? '-130px' : '0px', width: '100%' }}>
        <AppBody>
          <Wrapper id="swap-page" style={{ width: isMobile ? '100%' : '480px' }}>
            <ConfirmSwapModal
              isOpen={showConfirm}
              trade={trade}
              originalTrade={tradeToConfirm}
              onAcceptChanges={handleAcceptChanges}
              attemptingTxn={attemptingTxn}
              txHash={txHash}
              recipient={recipient}
              allowedSlippage={allowedSlippage}
              onConfirm={handleSwap}
              swapErrorMessage={swapErrorMessage}
              onDismiss={handleConfirmDismiss}
            />
            <SelectTab />
            <PageHeader title={t('SWAP_1')} description={t('SWAP_2')} />
            <CardBody p="0 32px 36px">
              <SwapCurrencyContainer gap="sm">
                <CurrencyInputPanel
                  showWSHM={true}
                  label={
                    independentField === Field.OUTPUT && !showWrap && trade
                      ? `${t('SWAP_3')}(${t('SWAP_4')})`
                      : t('SWAP_3')
                  }
                  value={formattedAmounts[Field.INPUT]}
                  showMaxButton={!atMaxAmountInput}
                  currency={currencies[Field.INPUT]}
                  onUserInput={handleTypeInput}
                  onMax={handleMaxInput}
                  onCurrencySelect={handleInputSelect}
                  otherCurrency={currencies[Field.OUTPUT]}
                  id="swap-currency-input"
                />
                <AutoColumn justify="space-between">
                  <AutoRow
                    justify={!isExpertMode ? 'center' : showRecipient ? 'center' : 'space-between'}
                    style={{ padding: '0', position: 'relative', width: '100%' }}
                  >
                    {isExpertMode ? (
                      <ArrowWrapper
                        clickable
                        style={{
                          position: 'relative',
                          zIndex: 5,
                          scale: 'sm',
                          // background: theme.colors.input,
                          opacity: 1,
                        }}
                      >
                        <IconButton
                          variant="primary"
                          style={{ background: '#EFEFF2' }}
                          onClick={() => {
                            setApprovalSubmitted(false) // reset 2 step UI for approvals
                            onSwitchTokens()
                          }}
                          scale="sm"
                        >
                          <ArrowDownIcon color="white" width="16px" />
                        </IconButton>
                      </ArrowWrapper>
                    ) : (
                      <ArrowWrapper
                        clickable
                        style={{
                          scale: 'sm',
                          // background: theme.colors.input,
                          opacity: 1,
                        }}
                      >
                        <IconButton
                          variant="primary"
                          style={{ background: '#EFEFF2' }}
                          onClick={() => {
                            setApprovalSubmitted(false) // reset 2 step UI for approvals
                            onSwitchTokens()
                          }}
                          scale="sm"
                        >
                          <ArrowDownIcon color="white" width="16px" />
                        </IconButton>
                      </ArrowWrapper>
                    )}

                    {recipient === null && !showWrap && isExpertMode ? (
                      <LinkStyledButton
                        style={{ width: '200px' }}
                        id="add-recipient-button"
                        onClick={() => onChangeRecipient('')}
                      >
                        + {t('SWAP_5')}
                      </LinkStyledButton>
                    ) : null}
                  </AutoRow>
                </AutoColumn>
                <CurrencyInputPanel
                  showWSHM={true}
                  value={formattedAmounts[Field.OUTPUT]}
                  onUserInput={handleTypeOutput}
                  label={
                    independentField === Field.INPUT && !showWrap && trade
                      ? `${t('SWAP_6')}(${t('SWAP_4')})`
                      : t('SWAP_6')
                  }
                  showMaxButton={false}
                  currency={currencies[Field.OUTPUT]}
                  onCurrencySelect={handleOutputSelect}
                  otherCurrency={currencies[Field.INPUT]}
                  id="swap-currency-output"
                />
              </SwapCurrencyContainer>
              <Card padding="0.8rem 0 0 0" borderRadius="16px">
                {isExpertMode && recipient !== null && !showWrap ? (
                  <>
                    <AutoRow justify="space-between" style={{ padding: '0 1rem 0.8rem 0' }}>
                      <ArrowWrapper
                        style={{
                          display: 'flex',
                          flexFlow: 'row-nowrap',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: '100%',
                          position: 'relative',
                        }}
                        clickable={false}
                      >
                        <ArrowDown size="16" color={theme.colors.textSubtle} />
                      </ArrowWrapper>
                    </AutoRow>
                    <AddressInputPanel
                      id="recipient"
                      value={recipient}
                      onChange={onChangeRecipient}
                      onRemove={() => onChangeRecipient(null)}
                    />
                  </>
                ) : null}
              </Card>
              {showWrap ? null : (
                <Card padding="0px" borderRadius="20px">
                  <AutoColumn gap="10px">
                    <RowBetween align="center">
                      <Text fontSize="14px" color={theme.colors.textRemark}>
                        {t('SWAP_15')}
                      </Text>
                      {trade ? (
                        <TradePrice
                          price={trade?.executionPrice}
                          showInverted={showInverted}
                          setShowInverted={setShowInverted}
                        />
                      ) : (
                        <Text> - </Text>
                      )}
                    </RowBetween>

                    <RowBetween align="center" style={{ padding: '0 0 10px 0', color: '#000', marginTop: '-10px' }}>
                      <Text fontSize="14px" style={{ color: theme.colors.textRemark }}>
                        {t('SWAP_7')}
                      </Text>
                      <Text fontSize="14px" style={{ color: '#000' }}>
                        {allowedSlippage / 100}%
                      </Text>
                    </RowBetween>
                  </AutoColumn>
                </Card>
              )}
              <BottomGrouping>
                {!account ? (
                  <ConnectWalletButton width="100%" />
                ) : showWrap ? (
                  <AnimationButton disabled={Boolean(wrapInputError)} onClick={onWrap} width="100%">
                    {wrapInputError ??
                      (wrapType === WrapType.WRAP ? 'Wrap' : wrapType === WrapType.UNWRAP ? 'Unwrap' : null)}
                  </AnimationButton>
                ) : noRoute && userHasSpecifiedInputOutput ? (
                  <AnimationButton disabled style={{ textAlign: 'center', width: '100%', borderRadius: '22px' }}>
                    {t('SWAP_8')}
                  </AnimationButton>
                ) : showApproveFlow ? (
                  <RowBetween>
                    <AnimationButton
                      onClick={approveCallback}
                      disabled={approval !== ApprovalState.NOT_APPROVED || approvalSubmitted}
                      style={{ width: '48%' }}
                      variant={approval === ApprovalState.APPROVED ? 'success' : 'primary'}
                    >
                      {approval === ApprovalState.PENDING ? (
                        <AutoRow gap="6px" justify="center">
                          Approving <Loader stroke="white" />
                        </AutoRow>
                      ) : approvalSubmitted && approval === ApprovalState.APPROVED ? (
                        t('SWAP_9')
                      ) : (
                        `${t('SWAP_10')} ${currencies[Field.INPUT]?.symbol}`
                      )}
                    </AnimationButton>
                    <AnimationButton
                      onClick={() => {
                        if (isExpertMode) {
                          handleSwap()
                        } else {
                          setSwapState({
                            tradeToConfirm: trade,
                            attemptingTxn: false,
                            swapErrorMessage: undefined,
                            showConfirm: true,
                            txHash: undefined,
                          })
                        }
                      }}
                      style={{ width: '48%', color: '#fff' }}
                      id="swap-button"
                      disabled={
                        !isValid || approval !== ApprovalState.APPROVED || (priceImpactSeverity > 3 && !isExpertMode)
                      }
                      variant={isValid && priceImpactSeverity > 2 ? 'danger' : 'primary'}
                    >
                      {priceImpactSeverity > 3 && !isExpertMode
                        ? t('SWAP_11')
                        : `${t('SWAP_12')}${priceImpactSeverity > 2 ? ` ${t('SWAP_12')}` : ''}`}
                    </AnimationButton>
                  </RowBetween>
                ) : (
                  <AnimationButton
                    onClick={() => {
                      if (isExpertMode) {
                        handleSwap()
                      } else {
                        setSwapState({
                          tradeToConfirm: trade,
                          attemptingTxn: false,
                          swapErrorMessage: undefined,
                          showConfirm: true,
                          txHash: undefined,
                        })
                      }
                    }}
                    id="swap-button"
                    disabled={!isValid || (priceImpactSeverity > 3 && !isExpertMode) || !!swapCallbackError}
                    variant={isValid && priceImpactSeverity > 2 && !swapCallbackError ? 'danger' : 'primary'}
                    width="100%"
                  >
                    {swapInputError ||
                      (priceImpactSeverity > 3 && !isExpertMode
                        ? t('SWAP_14')
                        : `${t('SWAP_12')}${priceImpactSeverity > 2 ? ` ${t('SWAP_13')}` : ''}`)}
                  </AnimationButton>
                )}
                {showApproveFlow && <ProgressSteps steps={[approval === ApprovalState.APPROVED]} />}
                {isExpertMode && swapErrorMessage && recipient !== null ? (
                  <SwapCallbackError error={swapErrorMessage} />
                ) : null}
              </BottomGrouping>
            </CardBody>
          </Wrapper>
          <AdvancedSwapDetailsDropdown trade={trade} />
        </AppBody>
      </CenterBox>
      {/* </SwapChartWrap> */}
    </Container>
  )
}

export default Swap
