import { createSlice } from '@reduxjs/toolkit'
import farmsV2Config from 'constants/farmsV2'
import { fetchFarmsV2 } from './fetchFarms'

import {
  fetchFarmV2UserEarnings,
  fetchFarmV2UserAllowances,
  fetchFarmV2UserTokenBalances,
  fetchFarmV2UserStakedBalances,
  fetchFarmV2UserRewarderEarnings,
} from './fetchFarmUser'

import { FarmsState, Farm, PriceApiListThunk } from '../types'
import { debounce } from 'lodash'

const initialState: FarmsState = { data: [], dataV2: [...farmsV2Config] }

export const farmsSlice = createSlice({
  name: 'Farms',
  initialState,
  reducers: {
    setFarmsPublicData: (state, action) => {
      const liveFarmsDataV2: Farm[] = action.payload.farmsV2
      state.dataV2 = state.dataV2.map((farm) => {
        const liveFarmDataV2 = liveFarmsDataV2.find((f) => f.pid === farm.pid)
        return { ...farm, ...liveFarmDataV2 }
      })
    },
    setFarmUserData: (state, action) => {
      const { arrayOfUserDataObjects } = action.payload
      arrayOfUserDataObjects.forEach((userDataEl) => {
        const { index } = userDataEl
        state.data[index] = { ...state.data[index], userData: userDataEl }
      })
    },
    setFarmV2UserData: (state, action) => {
      const { arrayOfUserDataObjects } = action.payload
      arrayOfUserDataObjects.forEach((userDataEl) => {
        const { index } = userDataEl
        state.dataV2[index] = { ...state.dataV2[index], userData: userDataEl }
      })
    },
  },
})

// Actions
export const { setFarmsPublicData, setFarmUserData, setFarmV2UserData } = farmsSlice.actions

const debounceFetchFarmsPublicDataAsync = debounce(async (prices, dispatch) => {
  const farmsV2 = await fetchFarmsV2(prices)
  dispatch(setFarmsPublicData({ farmsV2 }))
}, 1000)

// Thunks
export const fetchFarmsPublicDataAsync = (prices: PriceApiListThunk) => async (dispatch) => {
  if (prices) {
    debounceFetchFarmsPublicDataAsync(prices, dispatch)
  }
}

export const fetchFarmV2UserDataAsync = (account) => async (dispatch) => {
  const userFarmAllowances = await fetchFarmV2UserAllowances(account) // error
  const userFarmTokenBalances = await fetchFarmV2UserTokenBalances(account)
  const userStakedBalances = await fetchFarmV2UserStakedBalances(account)

  const userFarmEarnings = await fetchFarmV2UserEarnings(account)
  const userFarmRewardEarnings = await fetchFarmV2UserRewarderEarnings(account)
  const arrayOfUserDataObjects = userFarmAllowances.map((farmAllowance, index) => {
    return {
      index,
      allowance: userFarmAllowances[index],
      tokenBalance: userFarmTokenBalances[index],
      stakedBalance: userStakedBalances[index],
      earnings: userFarmEarnings[index],
      doubleDigEarnings: userFarmRewardEarnings[index],
    }
  })
  dispatch(setFarmV2UserData({ arrayOfUserDataObjects }))
}

export default farmsSlice.reducer
