import React from 'react'
import styled from 'styled-components'
import { projectToken } from '../../../../constants/migrate'
import { getTokenUrl } from '../../../../utils/getTokenUrl'
import { SvgProps } from '../types'

const LogoIcon = styled.img`
  border-radius: 50%;
`
const Icon: React.FC<SvgProps> = (props) => {
  return <LogoIcon {...props} src={getTokenUrl(projectToken.symbol)} />
}

export default Icon
