import { ChainId } from 'shardex-sdk'

export const getContractExplorerUrl = (contract: string) => {
  const chainId = process.env.REACT_APP_CHAIN_ID
  if (chainId === '322') {
    return `${process.env.REACT_APP_EXPLORER_URL}/address/${contract}/transactions`
  }
  return `${process.env.REACT_APP_EXPLORER_URL}/address/${contract}`
}

export const getTokenExplorerUrl = (token: string) => {
  const chainId = Number(process.env.REACT_APP_CHAIN_ID)
  if (chainId === ChainId.TESTNET) {
    return `${process.env.REACT_APP_EXPLORER_URL}/address/${token}/transactions`
  }
  return `${process.env.REACT_APP_EXPLORER_URL}/address/${token}`
}
