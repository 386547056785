import React from 'react'
import styled from 'styled-components'
import { Button } from 'uikit'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f5f6f6;
  border-radius: 26px;
  height: 44px;
  width: 252px;
  padding: 0 4px;
  margin-bottom: 32px;
  a {
    padding-left: 12px;
    padding-right: 12px;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    margin-left: 16px;
  }
`

interface Props {
  tab: number
  clickTab: (params) => void
}

const AccountTabButtons: React.FC<Props> = ({ tab, clickTab }) => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <Button
        onClick={() => {
          clickTab(0)
        }}
        style={{
          background: !tab ? 'linear-gradient(91.57deg, #FF3093 1.17%, #5218F4 99.12%)' : 'transparent',
          color: tab ? '#FF3093' : 'white',
          width: '126px',
          padding: 0,
          fontSize: '16px',
          height: '36px',
          boxShadow: 'none',
        }}
      >
        {t('UIKIT_16')}
      </Button>
      <Button
        onClick={() => clickTab(1)}
        style={{
          background: tab ? 'linear-gradient(91.57deg, #FF3093 1.17%, #5218F4 99.12%)' : 'transparent',
          color: !tab ? '#FF3093' : 'white',
          width: '126px',
          padding: 0,
          fontSize: '16px',
          height: '36px',
          boxShadow: 'none',
        }}
      >
        {t('UIKIT_17')}
      </Button>
    </Wrapper>
  )
}

export default AccountTabButtons
