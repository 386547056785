// @ts-nocheck
import { useWeb3React } from '@web3-react/core'
import BN from 'bignumber.js'
import Column from 'components/Column'
import Meta from 'components/Meta'
import React from 'react'
import CountUp from 'react-countup'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Text } from '../../uikit'

import FarmV2 from './FarmsV2'

import { harvestAllFarms, useFarmTotalEarns, useFetchPublicData, useToast } from 'state/hooks'
import { useTransactionAdder } from '../../state/transactions/hooks'

const Header = styled.div`
  background: linear-gradient(91.57deg, #ff3093 1.17%, #5218f4 99.12%);
  height: 620px;
  color: #fff;
  ${({ theme }) => theme.mediaQueries.md} {
    height: 300px;
    padding-left: 40px;
    padding-right: 36px;
  }
`

export const HeaderContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  color: #fff;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;

  padding-top: 25px;
  padding-left: 25px;
  padding-right: 25px;

  ${({ theme }) => theme.mediaQueries.md} {
    height: 300px;
    padding-top: 76px;
    padding-left: 0px;
    padding-right: 0px;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;
  }
`

const BannerImageWrap = styled.div`
  width: 250px;
  align-self: flex-end;
  height: auto;
  position: relative;
  margin: 50px auto -40px auto;
  z-index: 0;
  ${({ theme }) => theme.mediaQueries.md} {
    width: 250px;
    align-self: flex-end;
    height: auto;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin: unset;
  }
`

const HarvestButton = styled.div<{ disabled: boolean }>`
  width: 200px;
  height: 48px;
  left: 0px;
  top: 0px;
  background:#fff;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  cursor: ${({ disabled }) => {
    if (disabled) {
      return 'not-allowed'
    }
    return 'pointer'
  }};
  pointer-events:${({ disabled }) => {
    if (disabled) {
      return 'none'
    }
    return 'default'
  }}
  
  &:hover {
    opacity: 0.8;
  }
`

const BannerImage = styled.img`
  width: 100%;
  height: auto;
  margin-bottom: -3px;
`

const Index: React.FunctionComponent = () => {
  const { account, library } = useWeb3React()
  const { toastInfo } = useToast()
  const transactionAdder = useTransactionAdder()
  const { t } = useTranslation()

  useFetchPublicData()

  const allEarnings = useFarmTotalEarns()

  const harvestAllInFarm = () => {
    if (new BN(allEarnings ?? 0).gte(0.0001)) {
      harvestAllFarms(account as string, library, transactionAdder)
    } else {
      toastInfo(t('FARM_5'), t('FARM_6'))
    }
  }

  return (
    <>
      <Meta title={t('FARM_94')} />
      <Header>
        <HeaderContainer style={{ alignItems: isMobile ? 'center' : 'flex-start' }}>
          <div style={{ position: 'relative', zIndex: 1 }}>
            <Text
              color="#fff"
              fontSize="52px"
              fontFamily="Aqum two"
              fontWeight={700}
              style={{ letterSpacing: '4px', textAlign: isMobile ? 'center' : 'left' }}
            >
              {t('FARM_1')}
            </Text>
            <Text
              color="#fff"
              fontSize="20px"
              fontFamily={isMobile ? 'mojitofont rounded' : 'mojitofont'}
              fontWeight={500}
              style={{
                width: isMobile ? 'auto' : '400px',
                textAlign: isMobile ? 'center' : 'left',
                letterSpacing: isMobile ? '2px' : '0px',
                marginTop: isMobile ? '5px' : '0',
              }}
            >
              {t('FARM_2')}
            </Text>
          </div>
          <Column
            style={{
              paddingTop: '10px',
              width: '220px',
              position: 'relative',
              zIndex: 1,
              alignItems: isMobile ? 'center' : 'flex-start',
              marginTop: isMobile ? '40px' : '0',
            }}
          >
            <Text
              fontSize="18px"
              fontWeight={600}
              color="#fff"
              fontFamily="mojitofont"
              style={{ marginTop: '8px' }}
              lineHeight="21px"
            >
              {t('FARM_3')}
            </Text>
            <Text
              fontSize="32px"
              style={{ marginTop: '8px' }}
              fontWeight={600}
              color="#fff"
              fontFamily="mojitofont"
              lineHeight="38px"
            >
              ${' '}
              <CountUp
                duration={1}
                start={0}
                decimals={2}
                formattingFn={(n) => {
                  return new BN(n).toFormat({ groupSeparator: ',', groupSize: 3, decimalSeparator: '.' })
                }}
                end={new BN(new BN(allEarnings).gt(0) ? allEarnings : 0).toNumber()}
              />
            </Text>

            <HarvestButton onClick={harvestAllInFarm} disabled={!account || !allEarnings}>
              <Text
                fontSize="18px"
                fontWeight={700}
                fontFamily="mojitofont"
                style={{
                  background: `linear-gradient(to right, #FF3093, #0ac4dd)`,
                  color: 'transparent',
                  WebkitBackgroundClip: 'text',
                }}
              >
                {t('FARM_4')}
              </Text>
            </HarvestButton>
          </Column>
          <BannerImageWrap>
            <BannerImage src={require('../../assets/images/farm/banner-bg.png').default} alt="banner-image" />
          </BannerImageWrap>
        </HeaderContainer>
      </Header>
      <FarmV2 />
    </>
  )
}

export default Index
