import React from 'react'
import styled from 'styled-components'

import { SpinnerProps } from './types'

const Container = styled.div`
  position: relative;
`

const Spinner: React.FC<SpinnerProps> = () => {
  return (
    <Container>
      {/* <RotatingPancakeIcon width={`${size * 0.5}px`} /> */}
      {/* <FloatingPanIcon width={`${size}px`} /> */}
    </Container>
  )
}

export default Spinner
