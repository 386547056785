import { ChainId, ETHER } from 'shardex-sdk'

export const KCC_NETWORKS = {
  [ChainId.MAINNET]: {
    name: 'KCC-MAINNET',
    fullName: 'KCC Mainnet Network',
    abbr: 'KCC',
    rpc: process.env.REACT_APP_NETWORK_URL,
    chain_id: ChainId.MAINNET,
    decimals: ETHER.decimals,
    symbol: ETHER.symbol,
    browser: process.env.REACT_APP_NETWORK_URL,
    logo: 'https://cdn.jsdelivr.net/gh/kucoin-community-chain/tokens-info@main/icons/chain-321.png',
  },
  [ChainId.TESTNET]: {
    name: 'Goerli',
    fullName: 'Goerli test network',
    abbr: 'Goerli test network',
    rpc: process.env.REACT_APP_NETWORK_URL,
    chain_id: ChainId.TESTNET,
    decimals: ETHER.decimals,
    symbol: ETHER.symbol,
    browser: process.env.REACT_APP_NETWORK_URL,
    logo: 'https://cdn.jsdelivr.net/gh/kucoin-community-chain/tokens-info@main/icons/chain-321.png',
  },
}

export const KCC_NETWORK_IDS = [ChainId.MAINNET, ChainId.TESTNET]

export interface NetworkType {
  name: string
  rpc: string
  fullName: string
  chain_id: number
  symbol: string
  browser: string
  decimals: number
  logo: string
  bridgeCoreAddress: string
  standard: string
  fee?: number
  abbr: string
}

export interface AddEthereumChainParameter {
  chainId: string // A 0x-prefixed hexadecimal string
  chainName: string
  nativeCurrency: {
    name: string
    symbol: string // 2-6 characters long
    decimals: number
  }
  rpcUrls: string[]
  blockExplorerUrls?: string[]
  iconUrls?: string[] // Currently ignored.
}
