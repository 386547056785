import React from 'react'
import { Modal } from '../../uikit'
import SlippageToleranceSetting from './SlippageToleranceSetting'
import TransactionDeadlineSetting from './TransactionDeadlineSetting'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'

type SettingsModalProps = {
  onDismiss?: () => void
  translateString: (translationId: number, fallback: string) => string
}

const defaultOnDismiss = () => null

const SettingsModal = ({ onDismiss = defaultOnDismiss, translateString }: SettingsModalProps) => {
  const { t } = useTranslation()
  return (
    <Modal
      style={{ borderRadius: 16 }}
      minWidth={isMobile ? '96%' : '440px'}
      title={t('COMPONENTS_52')}
      onDismiss={onDismiss}
    >
      <SlippageToleranceSetting translateString={translateString} />
      <TransactionDeadlineSetting translateString={translateString} styles={{ marginTop: '20px' }} />
    </Modal>
  )
}

export default SettingsModal
