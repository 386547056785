import React from 'react'
import styled from 'styled-components'
import { ChevronDownIcon } from 'uikit'
import { useTranslation } from 'react-i18next'

interface DetailsProps {
  actionPanelToggled: boolean
}

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-right: 8px;
  color: #ff3093;

  ${({ theme }) => theme.mediaQueries.sm} {
    padding-right: 0px;
  }
`

const ArrowIcon = styled(ChevronDownIcon)<{ toggled: boolean }>`
  transform: ${({ toggled }) => (toggled ? 'rotate(180deg)' : 'rotate(0)')};
  height: 20px;
`

const Details: React.FC<DetailsProps> = ({ actionPanelToggled }) => {
  const { t } = useTranslation()

  return (
    <Container>
      {t('FARM_37')}
      <ArrowIcon color="#FF3093" toggled={actionPanelToggled} />
    </Container>
  )
}

export default Details
