import { ChainId } from 'shardex-sdk'
import tokens from './tokens'
import { FarmConfig } from './types'

const devFarms: FarmConfig[] = [
  {
    pid: 1,
    lpSymbol: 'SDT/WGoerliETH LP',
    lpAddresses: {
      [ChainId.TESTNET]: '0x3BFF5bd7cFFc38F3Ec1E269Af90E47c4ed502461',
      [ChainId.MAINNET]: '0xa0d7C8aA789362CDf4FAAE24b9D1528eD5a3777f',
    },
    token: tokens.wchain,
    quoteToken: tokens.usdt,
    // doubleDigRewarder: '0x945dee84f998e20647c1e7e71304d2393b4feb63',
    // doubleDigToken: tokens.wkcs,
    // doubleDigPerBlock: 0.003472222,
    version: 2,
  },
]
const proFarms: FarmConfig[] = []

const mainnetEnvName = ['production-mainnet', 'preview']

const isMainnet = mainnetEnvName.includes(process.env.REACT_APP_ENV_NAME ?? '')

export default isMainnet ? proFarms : devFarms
