import farmsV2 from 'constants/farmsV2'
import { FarmConfig } from 'constants/types'

export function getFarmByPid(pid: number): FarmConfig {
  for (let i = 0; i < farmsV2.length; i++) {
    if (pid === farmsV2[i].pid) {
      return farmsV2[i]
    }
  }
  return farmsV2[0]
}

export function getFarmName(pid: number): string {
  const farm = getFarmByPid(pid)
  return `${farm.token.symbol.toUpperCase()}/${farm.quoteToken.symbol.toUpperCase()}`
}
