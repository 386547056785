import { useEffect, useMemo } from 'react'
import BigNumber from 'bignumber.js'
import { debounce, kebabCase } from 'lodash'
import { Toast, toastTypes } from 'uikit'
import { useSelector } from 'react-redux'
import store, { useAppDispatch } from 'state'

import { getAddress, getMasterChefAddress, getMasterChefV2Address } from 'utils/addressHelpers'
import { getBalanceNumber } from 'utils/formatBalance'
import useRefresh from 'hooks/useRefresh'
import masterchefABI from 'constants/abis/masterchef.json'
import masterchefV2ABI from 'constants/abis/masterchefV2.json'

import { updatePriceList } from 'state/prices'

import {
  fetchFarmsPublicDataAsync,
  fetchPoolsPublicDataAsync,
  fetchPoolsUserDataAsync,
  fetchMinningDataAsync,
  fetchReferralPostAsync,
  push as pushToast,
  remove as removeToast,
  clear as clearToast,
} from './actions'
import { State, Farm, FarmV2, Pool, PriceState, Minning, Referral } from './types'

import useGetSDTUsdtLpPrice, { useTokenUsdtPrice } from '../utils/useTokenUsdtPrice'

import tokens from 'constants/tokens'

import BN from 'bignumber.js'
import { multiSend } from '../utils/multicall'
import { useWeb3React } from '@web3-react/core'

import Web3 from 'web3'
import { getFarmName } from 'utils/getFarm'

import i18next from '../i18n'

export const useGetApiPrices = () => {
  const prices: PriceState['data'] = useSelector((state: State) => state.prices?.data)
  return prices
}

export const useGetApiPrice = (address: any) => {
  const prices = useGetApiPrices()
  if (!prices) {
    return '0'
  }

  return prices[address.toLowerCase()]
}

// let count = 1

const debounceUseFetchPublicData = debounce((prices, dispatch) => {
  dispatch(fetchFarmsPublicDataAsync(prices))
  setTimeout(() => {
    dispatch(fetchPoolsPublicDataAsync())
  }, 500)
}, 500)

export const useFetchPublicData = () => {
  const dispatch = useAppDispatch()
  const prices = useGetApiPrices()

  // need price
  useEffect(() => {
    if (prices && Reflect.ownKeys(prices).length) {
      debounceUseFetchPublicData(prices, dispatch)
    }
  }, [dispatch, prices])

  // useEffect(() => {
  //   const web3 = getWeb3NoAccount()
  //   const interval = setInterval(async () => {
  //     const blockNumber = await web3.eth.getBlockNumber()
  //     dispatch(setBlock(blockNumber))
  //   }, 6000)

  //   return () => {
  //     clearInterval(interval)
  //   }
  // }, [dispatch])
}

// Farms

export const useFarms = (): Farm[] => {
  const farms = useSelector((state: State) => state.farms.data)
  return farms
}

export const useFarmsV2 = (): FarmV2[] => {
  const farms = useSelector((state: State) => state.farms.dataV2)
  return farms
}

export const harvestAllFarms = async (account: string, library: any, transactionAdder) => {
  const { farms } = store.getState()
  const harvestDataV2: any[] = []

  farms.dataV2.forEach((farm) => {
    if (new BN(farm.userData?.earnings ?? 0).gt(0)) {
      harvestDataV2.push({
        address: getMasterChefV2Address(),
        name: 'deposit',
        params: [farm.pid, 0],
      })
    }
  })

  Promise.all([multiSend(masterchefV2ABI, harvestDataV2, account, library, 2)])
    .then((res) => {
      const txsV2: any = res[0]
      for (let i = 0; i < txsV2.length; i++) {
        const farmName = getFarmName(harvestDataV2[i].params[0] as number)
        transactionAdder(
          { hash: txsV2[i]?.transactionHash },
          {
            summary: i18next.t('CONSTANT_35', { farmName, version: '2' }),
          }
        )
      }
    })
    .catch((e) => {
      console.log('e = ', e)
    })
}

export const useFarmTotalEarns = (): number => {
  const farms = useFarms()
  const farmsV2 = useFarmsV2()

  const projectTokenPrice = useGetSDTUsdtLpPrice()
  const priceList = useGetApiPrices()

  if (!priceList) {
    return 0
  }

  let total = '0'
  let doubleDigTotal = '0'
  if (farms?.length) {
    farms.forEach((farm) => {
      total = new BN(total).plus(farm.userData?.earnings ?? '0').toString()
    })
  }
  if (farmsV2?.length) {
    farmsV2.forEach((farm) => {
      total = new BN(total).plus(farm.userData?.earnings ?? '0').toString()
      if (farm?.doubleDigRewarder) {
        const doubleDigRewarder = new BN(farm.userData?.doubleDigEarnings ?? '0')
          .div(new BN(10).pow(18))
          .multipliedBy(priceList[getAddress(farm?.doubleDigToken.address).toLowerCase()])
        doubleDigTotal = new BN(doubleDigTotal).plus(doubleDigRewarder).toString()
      }
    })
  }
  const earning =
    total === '0'
      ? '0'
      : new BN(total)
          .div(new BN(10).pow(new BN(tokens.sdt.decimals)))
          .multipliedBy(projectTokenPrice ?? '0')
          .plus(doubleDigTotal)
          .toFixed(2, 1)
          .toString()

  if (new BN(earning ?? 0).gt(0)) {
    return Number(earning)
  }

  return 0
}

export const useFarmTotalStaked = (): number => {
  const farms = useFarms()
  const farmsV2 = useFarmsV2()

  const projectTokenPrice = useGetSDTUsdtLpPrice()

  let total = '0'
  if (farms?.length) {
    farms.forEach((farm) => {
      total = new BN(total).plus(farm.userData?.stakedBalance ?? '0').toString()
    })
  }

  if (farmsV2?.length) {
    farmsV2.forEach((farm) => {
      total = new BN(total).plus(farm.userData?.stakedBalance ?? '0').toString()
    })
  }

  const earning =
    total === '0'
      ? '0'
      : new BN(total)
          .div(new BN(10).pow(new BN(tokens.sdt.decimals)))
          .multipliedBy(projectTokenPrice ?? '0')
          .toFixed(2, 1)
          .toString()
  if (new BN(earning ?? 0).gt(0)) {
    return Number(earning)
  }

  return 0
}

export const useFarmFromPid = (pid, version?: number): FarmV2 => {
  const farm = useSelector((state: State) => {
    if (version === 2) {
      return state.farms.dataV2.find((f) => f.pid === pid)
    }
    return state.farms.data.find((f) => f.pid === pid)
  })
  return farm as FarmV2
}

export const useFarmFromSymbol = (lpSymbol: string, version?: number): FarmV2 => {
  const farm = useSelector((state: State) => {
    if (version === 2) {
      return state.farms.dataV2.find((f) => f.lpSymbol === lpSymbol)
    }
    return state.farms.data.find((f) => f.lpSymbol === lpSymbol)
  })
  return farm as FarmV2
}

export const useFarmUser = (pid, version?: number) => {
  const farm = useFarmFromPid(pid, version)
  return {
    allowance: farm?.userData ? new BigNumber(farm.userData.allowance) : new BigNumber(0),
    tokenBalance: farm?.userData ? new BigNumber(farm.userData.tokenBalance) : new BigNumber(0),
    stakedBalance: farm?.userData ? new BigNumber(farm.userData.stakedBalance) : new BigNumber(0),
    earnings: farm?.userData ? new BigNumber(farm.userData.earnings) : new BigNumber(0),
    doubleDigEarnings: farm?.userData ? new BigNumber(farm.userData.doubleDigEarnings) : new BigNumber(0),
  }
}

export const useLpTokenPrice = (symbol: string, version?: number) => {
  const farm = useFarmFromSymbol(symbol, version ?? 2)
  const tokenPriceInUsd = useGetApiPrice(getAddress(farm?.token?.address as any))
  const quoteTokenPriceInUsd = useGetApiPrice(getAddress(farm?.quoteToken?.address as any))
  const tokenTotalInLp = new BigNumber(farm?.tokenAmount).div(
    new BigNumber(farm?.lpTokenBalanceMC).div(farm.lpTotalSupply)
  )
  const quoteTokenTotalInLp = new BigNumber(farm?.quoteTokenAmount).div(
    new BigNumber(farm?.lpTokenBalanceMC).div(farm.lpTotalSupply)
  )
  return farm.lpTotalSupply && farm.lpTotalInQuoteToken
    ? new BigNumber(tokenPriceInUsd)
        .times(tokenTotalInLp)
        .plus(new BigNumber(quoteTokenPriceInUsd).times(quoteTokenTotalInLp))
        .div(getBalanceNumber(farm.lpTotalSupply))
    : new BigNumber(0)
}

// Pools
export const usePools = (account): { poolsV2: Pool[] } => {
  const { slowRefresh } = useRefresh()
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (account) {
      dispatch(fetchPoolsUserDataAsync(account))
    }
  }, [account, dispatch, slowRefresh])

  const poolsV2 = useSelector((state: State) => state.pools.dataV2)
  return { poolsV2 }
}

export const usePoolTotalEarns = (): string => {
  const { account } = useWeb3React()
  const { poolsV2 } = usePools(account)
  const projectTokenPrice = useGetSDTUsdtLpPrice()
  let total = '0'

  if (poolsV2?.length) {
    poolsV2.forEach((pool) => {
      total = new BN(total).plus(pool.userData?.pendingReward ?? '0').toString()
    })
  }
  //  console.log(total)

  return total === '0'
    ? '0'
    : new BN(total)
        .div(10 ** tokens.sdt.decimals)
        .multipliedBy(projectTokenPrice ?? '0')
        .toFixed(2, 1)
        .toString()
}

export const harvestAllPools = async (account: any, library: any, transactionAdder) => {
  const web3 = new Web3(library.provider)
  const masterChefContract = new web3.eth.Contract(masterchefABI as any, getMasterChefAddress())
  await masterChefContract.methods
    .leaveStaking(0)
    .send({ from: account })
    .on('transactionHash', (tx) => {
      transactionAdder(
        { hash: tx },
        {
          summary: i18next.t('CONSTANT_36', { version: '1' }),
        }
      )
      return tx.transactionHash
    })

  // const vaultContract = new web3.eth.Contract(vaultABI as any, getVaultAddress(), library)
  // vaultContract.methods.harvest().send({ from: account })
}

export const harvestAllPoolsV2 = async (account: any, library: any, transactionAdder) => {
  const web3 = new Web3(library.provider)
  const masterChefContract = new web3.eth.Contract(masterchefV2ABI as any, getMasterChefV2Address())
  await masterChefContract.methods
    .leaveStaking(0)
    .send({ from: account })
    .on('transactionHash', (tx) => {
      transactionAdder(
        { hash: tx },
        {
          summary: i18next.t('CONSTANT_36', { version: '2' }),
        }
      )
      return tx.transactionHash
    })

  // const vaultContract = new web3.eth.Contract(vaultABI as any, getVaultAddress(), library)
  // vaultContract.methods.harvest().send({ from: account })
}

export const usePoolFromPid = (sortOrder: number): Pool => {
  const pool = useSelector((state: State) => {
    return state.pools.dataV2.find((p) => p.sortOrder === sortOrder)
  })
  return pool
}

// Minning
export const useMinning = (refresh: number, account?: string, library?: any): Minning[] => {
  const dispatch = useAppDispatch()
  const { slowRefresh } = useRefresh()
  useEffect(() => {
    dispatch(fetchMinningDataAsync(account, library))
  }, [account, dispatch, slowRefresh, library, refresh])

  const minning = useSelector((state: State) => state.minning.data)
  return minning
}

export const useMinningUser = (refresh: number, account?: string, library?: any): [number, number] => {
  const dispatch = useAppDispatch()
  const { slowRefresh } = useRefresh()
  useEffect(() => {
    dispatch(fetchMinningDataAsync(account, library))
  }, [account, dispatch, slowRefresh, library, refresh])

  const minning = useSelector((state: State) => state.minning.totalReward)
  const rewardPools = useSelector((state: State) => state.minning.rewardPools)
  return [minning, rewardPools]
}

// Referral
export const useFetchReferralPost = (account?: string) => {
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(fetchReferralPostAsync(account))
  }, [account, dispatch])
}

export const useReferralPost = (): Referral[] => {
  const referralPost = useSelector((state: State) => state.referral.post)
  return referralPost
}

export const useReferralInfo = () => {
  const referralInfo = useSelector((state: State) => state.referral.contractInfo)
  return referralInfo
}

// // Toasts
export const useToast = () => {
  const dispatch = useAppDispatch()
  const helpers = useMemo(() => {
    const push = (toast: Toast) => dispatch(pushToast(toast))

    return {
      toastError: (title: string, description?: string) => {
        return push({ id: kebabCase(title), type: toastTypes.DANGER, title, description })
      },
      toastInfo: (title: string, description?: string) => {
        return push({ id: kebabCase(title), type: toastTypes.INFO, title, description })
      },
      toastSuccess: (title: string, description?: string) => {
        return push({ id: kebabCase(title), type: toastTypes.SUCCESS, title, description })
      },
      toastWarning: (title: string, description?: string) => {
        return push({ id: kebabCase(title), type: toastTypes.WARNING, title, description })
      },
      push,
      remove: (id: string) => dispatch(removeToast(id)),
      clear: () => dispatch(clearToast()),
    }
  }, [dispatch])

  return helpers
}

// export const useAchievements = () => {
//   const achievements: AchievementState = useSelector((state: State) => state.achievements)
//   return achievements
// }

export const useProjectTokenUSDT = (): BigNumber => {
  const { chainId } = useWeb3React()
  const price = useTokenUsdtPrice(tokens.projectToken.address[chainId])
  return price ? new BigNumber(price) : new BigNumber(0)
}

export const usePriceKcsUSDT = (): BigNumber => {
  const { chainId } = useWeb3React()
  const price = useTokenUsdtPrice(tokens.wchain.address[chainId])
  return price ? new BigNumber(price) : new BigNumber(0)
}

// Block
export const useBlock = () => {
  return useSelector((state: State) => state.block)
}

export const useInitialBlock = () => {
  return useSelector((state: State) => state.block.initialBlock)
}

export const usePriceListUpdateTime = (): string => {
  const updated_at = useSelector((state: State) => {
    return state.prices.lastUpdated
  })
  return updated_at
}

// Prices
export const useFetchPriceList = () => {
  const { slowRefresh } = useRefresh()
  const dispatch = useAppDispatch()

  const wchainPrice = useTokenUsdtPrice(getAddress(tokens.wchain.address))
  const sdtPrice = useTokenUsdtPrice(getAddress(tokens.sdt.address))

  const priceData = useMemo(() => {
    const data = {
      updated_at: new Date().getTime().toString(),
      data: {
        [getAddress(tokens.usdt.address)]: {
          symbol: 'USDT',
          price: '1',
        },
        [getAddress(tokens.sdt.address)]: {
          symbol: 'SDT',
          price: sdtPrice,
        },
        [getAddress(tokens.wchain.address)]: {
          symbol: tokens.wchain.symbol,
          price: wchainPrice,
        },
      },
    }

    return {
      updated_at: data.updated_at,
      data: Object.keys(data.data).reduce((accum, token) => {
        return {
          ...accum,
          [token.toLowerCase()]: parseFloat(String(data.data[token].price) ?? '0'),
        }
      }, {}),
    }
  }, [sdtPrice, wchainPrice])

  useEffect(() => {
    dispatch(updatePriceList(priceData))
  }, [dispatch, slowRefresh, priceData])
}
