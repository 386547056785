import { ChainId } from 'shardex-sdk'
import addresses from '../constants/contract'
import tokens from '../constants/tokens'
import { Address } from '../constants/types'

export const getAddress = (address: Address): string => {
  if (!address) {
    return ''
  }
  const chainId = process.env.REACT_APP_CHAIN_ID as any
  return address[chainId] ? address[chainId] : address[ChainId.MAINNET]
}

export const getAirdropAddress = () => {
  return getAddress(addresses.merkleDistributor)
}

export const getWChainAddress = () => {
  return getAddress(tokens.wchain.address)
}

export const getSecondAirdropAddress = () => {
  return getAddress(addresses.secondMerkleDistributor)
}

export const getMinningAddress = () => {
  return getAddress(addresses.swapMining)
}

export const getReferralAddress = (index: number) => {
  const chainId = process.env.REACT_APP_CHAIN_ID as any
  return addresses.referral[chainId][index]
}

export const getSdtAddress = () => {
  return getAddress(tokens.sdt.address)
}

export const getProjectTokenAddress = () => {
  return getAddress(tokens.projectToken.address)
}

export const getMasterChefAddress = () => {
  return getAddress(addresses.masterChef)
}
export const getMasterChefV2Address = () => {
  return getAddress(addresses.masterChefV2)
}
export const getMulticallAddress = () => {
  return getAddress(addresses.mulltiCall)
}

export const getVaultAddress = () => {
  return getAddress(addresses.vault)
}

export const getVaultV2Address = () => {
  return getAddress(addresses.vaultV2)
}

export const getProfileAddress = () => {
  return getAddress(addresses.profile)
}

export const getMojitoCattleAddress = () => {
  return getAddress(addresses.mojitoCattle)
}

export const getMojitoCattleFactoryAddress = () => {
  return getAddress(addresses.mojitoCattleFactory)
}

export const getPointCenterIfoAddress = () => {
  return getAddress(addresses.pointCenterIfo)
}

export const getTradeRobotNftAddress = () => {
  return getAddress(addresses.lemonNft)
}

export const getNftMarketAddress = () => {
  return getAddress(addresses.nftMarket)
}

export const getLuckyDrawResultAddress = () => {
  return getAddress(addresses.luckyDrawResult)
}

export const getRollingAddress = () => {
  return getAddress(addresses.rolling)
}
