import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 16 16" {...props}>
      <path
        d="M5.35732 12.7731L10.1348 7.99581L5.36167 3.22266"
        fill="none"
        stroke={props.color}
        strokeOpacity="0.6"
        strokeWidth="1.5"
      />
    </Svg>
  )
}

export default Icon
