import { Percent } from 'shardex-sdk'
import { ALLOWED_PRICE_IMPACT_HIGH, PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN } from '../../constants'
import i18next from '../../i18n'

/**
 * Given the price impact, get user confirmation.
 *
 * @param priceImpactWithoutFee price impact of the trade without the fee.
 */
export default function confirmPriceImpactWithoutFee(priceImpactWithoutFee: Percent): boolean {
  if (!priceImpactWithoutFee.lessThan(PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN)) {
    return (
      window.prompt(i18next.t('COMPONENTS_207', { asset: PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN.toFixed(0) })) ===
      'confirm'
    )
  }
  if (!priceImpactWithoutFee.lessThan(ALLOWED_PRICE_IMPACT_HIGH)) {
    return window.confirm(i18next.t('COMPONENTS_208', { asset: ALLOWED_PRICE_IMPACT_HIGH.toFixed(0) }))
  }
  return true
}
