import { useCallback } from 'react'
import { useWeb3React } from '@web3-react/core'
import { useAppDispatch } from 'state'
import { fetchFarmV2UserDataAsync, updateUserBalance, updateUserPendingReward } from 'state/actions'
import { soushHarvest, soushHarvestBnb, harvest } from 'utils/callHelpers'
import { useMasterchef, useMasterchefV2, useSousChef, useVaultChefV2 } from './useContract'
import { useTransactionAdder } from 'state/transactions/hooks'

export const useHarvest = (farmPid: number) => {
  const dispatch = useAppDispatch()
  const { account } = useWeb3React()
  const masterChefV2Contract = useMasterchefV2()
  const transactionAdder = useTransactionAdder()

  const handleHarvest = useCallback(async () => {
    const txHash = await harvest(masterChefV2Contract, farmPid, account, transactionAdder)
    dispatch(fetchFarmV2UserDataAsync(account))
    return txHash
  }, [account, dispatch, farmPid, transactionAdder, masterChefV2Contract])

  return { onReward: handleHarvest }
}

export const useAllHarvest = (farmPids: number[]) => {
  const { account } = useWeb3React()
  const masterChefContract = useMasterchef()
  const transactionAdder = useTransactionAdder()
  const handleHarvest = useCallback(async () => {
    const harvestPromises = farmPids.reduce((accum: any, pid) => {
      return [...accum, harvest(masterChefContract, pid, account, transactionAdder)]
    }, [])

    return Promise.all(harvestPromises)
  }, [account, farmPids, masterChefContract, transactionAdder])

  return { onReward: handleHarvest }
}

export const useSousHarvest = (sousId, sortOrder, isUsingBnb = false) => {
  const dispatch = useAppDispatch()
  const { account } = useWeb3React()
  // ??? sortOrder
  const sousChefContract = useSousChef(sousId)
  const transactionAdder = useTransactionAdder()
  const masterChefContractV2 = useMasterchefV2()

  const vaultContractV2 = useVaultChefV2()

  const isAutoPool = sortOrder === 1

  const handleHarvest = useCallback(async () => {
    if (!account) return
    if (sousId === 0) {
      if (isAutoPool) {
        await vaultContractV2.methods.harvest().send({ from: account })
      } else {
        await harvest(masterChefContractV2, 0, account, transactionAdder)
      }
    } else if (isUsingBnb) {
      await soushHarvestBnb(sousChefContract, account, transactionAdder)
    } else {
      await soushHarvest(sousChefContract, account, transactionAdder)
    }
    dispatch(updateUserPendingReward(sortOrder, account) as any)
    dispatch(updateUserBalance(sortOrder, account) as any)
  }, [
    account,
    dispatch,
    isAutoPool,
    isUsingBnb,
    sousChefContract,
    sortOrder,
    sousId,
    transactionAdder,
    vaultContractV2,
    masterChefContractV2,
  ])
  return { onReward: handleHarvest }
}
