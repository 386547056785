import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import XHR from 'i18next-xhr-backend'

// const supportLang = ['en', 'ja', 'ko', 'de', 'es-ES', 'ru', 'fr', 'vi'];
const supportLang = ['en', 'ja', 'it', 'ko', 'fr', 'de', 'es-ES', 'ru', 'vi']

const lngTemplates: { [key: string]: { translation: string } } = {}
const templates = require.context('./locales/', true, /\.ts$/)

supportLang.forEach((lng) => {
  lngTemplates[lng] = { translation: templates(`./${lng}.ts`).default }
})

const storedLangCode = localStorage.getItem('MojitoSwapLanguage')

i18next
  .use(XHR)
  .use(initReactI18next)
  .init(
    {
      resources: { ...lngTemplates } as any,
      debug: false,
      react: {
        useSuspense: true,
        transSupportBasicHtmlNodes: true,
        transKeepBasicHtmlNodesFor: ['br', 'span'],
      },
      fallbackLng: storedLangCode || 'en',
      preload: [storedLangCode || 'en'],
      keySeparator: false,
      interpolation: { escapeValue: false },
    },
    (err) => {
      if (err) console.log('something went wrong loading', err)
    }
  )

export default i18next
