import React from 'react'
import styled from 'styled-components'
import BackgroundImage from './BackgroundImage'
import { BackgroundImageProps } from './types'

const StyledProfileAvatar = styled(BackgroundImage)`
  border-radius: 50%;
`

const StyledMCPlaceholder = styled.img`
  height: 100%;
  width: 100%;
`

const ProfileAvatar: React.FC<BackgroundImageProps> = (props) => (
  <StyledProfileAvatar loadingPlaceholder={<StyledMCPlaceholder src="/images/no-avatar.png" />} {...props} />
)

export default ProfileAvatar
