import React, { useCallback, useMemo, useState } from 'react'
import { Button, Modal, LinkExternal, Text } from 'uikit'
import ModalActions from 'components/ModalActions'
import ModalInput from 'components/ModalInput'
import Row from 'components/Row'
import { getFullDisplayBalance } from 'utils/formatBalance'
import styled from 'styled-components'
import BN from 'bignumber.js'
import { useResponsive } from '../../../hooks/useResponse'
import { useTranslation } from 'react-i18next'


const StyledErrorMessage = styled(Text)`
  position: relative;
  margin: 20px 0 -20px 0;
  a {
    display: inline;
  }
`

const ModalActionButton = styled(Button)<{ disabled: boolean }>`
  border-radius: 30px;
  height: 48px;
  width: 142px;
  z-index: 1;
  box-shadow: none;
  position: relative;
  overflow: visible;
  background: ${({ disabled }) => {
    if (disabled) {
      return '#C9C9C9'
    }
    return 'linear-gradient(to right, #14d89e, #0bc5d9)'
  }};
  color: ${({ disabled }) => {
    if (disabled) {
      return '#C9C9C9'
    }
    return '#FF3093'
  }};
  &::after {
    position: absolute;
    z-index: 99;
    top: 2px;
    bottom: 2px;
    right: 2px;
    left: 2px;
    background: ${({ disabled }) => {
      if (disabled) {
        return '#C9C9C9'
      }
      return '#fff'
    }};
    display: ${({ disabled }) => {
      if (disabled) {
        return 'none'
      }
      return 'block'
    }};
    color: ${({ disabled }) => {
      if (disabled) {
        return '#C9C9C9'
      }
      return '#FF3093'
    }};
    border-radius: 30px;
    content: 'Confirm';
    line-height: 44px;
  }
`

const ModalActionCancelButton = styled(Button)<{ disabled: boolean }>`
  border-radius: 30px;
  height: 48px;
  width: 142px;
  z-index: 1;
  box-shadow: none;
  position: relative;
  overflow: visible;
  background: ${({ disabled }) => {
    if (disabled) {
      return '#C9C9C9'
    }
    return 'linear-gradient(to right, #14d89e, #0bc5d9)'
  }};
  color: #fff;
  &::after {
    position: absolute;
    z-index: 99;
    top: 2px;
    bottom: 2px;
    right: 2px;
    left: 2px;
    background: ${({ disabled }) => {
      if (disabled) {
        return '#C9C9C9'
      }
      return '#fff'
    }};
    display: ${({ disabled }) => {
      if (disabled) {
        return 'none'
      }
      return 'block'
    }};
    color: ${({ disabled }) => {
      if (disabled) {
        return '#fff'
      }
      return '#FF3093'
    }};
    border-radius: 30px;
    content: 'Cancel';
    line-height: 44px;
  }
`

const DepositModal: React.FC<any> = ({ max, onConfirm, onDismiss, tokenName = '', addLiquidityUrl, onRouter }) => {
  const [val, setVal] = useState('')
  const [pendingTx, setPendingTx] = useState(false)
  const { t } = useTranslation()
  const { isMobile } = useResponsive()
  const fullBalance = useMemo(() => {
    return getFullDisplayBalance(max)
  }, [max])

  const handleChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      setVal(e.currentTarget.value)
    },
    [setVal]
  )

  const isValidAmmount = React.useMemo(() => {
    if (new BN(val).gt(fullBalance)) {
      return false
    }
    if (!/^[1-9]\d*\.\d*|0\.\d*[1-9]\d*$/.test(val) && !/^[1-9]\d*$/.test(val)) {
      return false
    }
    return true
  }, [val, fullBalance])

  const insufficentAmmount = React.useMemo(() => {
    if (new BN(val).gt(fullBalance)) {
      return false
    }
    return true
  }, [val, fullBalance])

  const handleSelectMax = useCallback(() => {
    setVal(fullBalance)
  }, [fullBalance, setVal])

  return (
    <Modal title={t('FARM_73')} onDismiss={onDismiss} style={{ width: isMobile ? '96%' : '440px' }}>
      <ModalInput
        isValidAmmount={isValidAmmount}
        value={val}
        onSelectMax={handleSelectMax}
        onChange={handleChange}
        max={fullBalance}
        symbol={tokenName}
        addLiquidityUrl={addLiquidityUrl}
        inputTitle={t('FARM_71')}
        styles={{ background: '#fff', borderRadius: '8px' }}
        inputWidth={isMobile ? '110px' : 'auto'}
      />
      {!insufficentAmmount && (
        <StyledErrorMessage fontSize="14px" color="failure">
          {t('FARM_15')}
        </StyledErrorMessage>
      )}
      <div>
        <ModalActions>
          <ModalActionCancelButton onClick={onDismiss} width="100%" disabled={false}>
            {t('FARM_16')}
          </ModalActionCancelButton>
          <ModalActionButton
            width="100%"
            disabled={pendingTx || fullBalance === '0' || val === '0' || new BN(val).eq(0) || !val || !isValidAmmount}
            onClick={async () => {
              setPendingTx(true)
              try {
                await onConfirm(val)
                onDismiss()
                if (onRouter) onRouter()
              } finally {
                setPendingTx(false)
              }
            }}
          >
            {pendingTx ? t('FARM_17') : t('FARM_18')}
          </ModalActionButton>
        </ModalActions>
      </div>
      <Row>
        <LinkExternal href={addLiquidityUrl} style={{ alignSelf: 'flex-start', textAlign: 'left' }} color="#FF3093">
          <Text color="#FF3093">{t('FARM_72', { asset: tokenName })}</Text>
        </LinkExternal>
        <Text mr="5px" ml="5px">
          or
        </Text>
        <LinkExternal href="/rolling" style={{ alignSelf: 'flex-start', textAlign: 'left' }} color="#FF3093">
          <Text color="#FF3093">{t('FARM_97')}</Text>
        </LinkExternal>
      </Row>
    </Modal>
  )
}

export default DepositModal
