import React, { useCallback } from 'react'
import styled from 'styled-components'
import BigNumber from 'bignumber.js'
import { Button, Flex, Heading, IconButton, AddIcon, MinusIcon, useModal, Text } from 'uikit'
import useStake from 'hooks/useStake'
import useUnstake from 'hooks/useUnstake'
import { getBalanceNumber } from 'utils/formatBalance'
import DepositModal from '../DepositModal'
import WithdrawModal from '../WithdrawModal'
import BN from 'bignumber.js'
import { useFarmFromPid } from '../../../../state/hooks'
import useLpUSD from 'utils/getLpUSD'
import QuestionHelper from '../../../../components/QuestionHelper/index'
import { MouseoverTooltip } from 'components/Tooltip'
import { useTranslation } from 'react-i18next'

const FarmActionButton = styled(Button)<{ disabled?: boolean }>`
  border: 2px solid linear-gradient(91.57deg, #ff3093 1.17%, #5218f4 99.12%);
  background: ${({ disabled }) => (disabled ? '#E6E7E8' : 'linear-gradient(91.57deg, #FF3093 1.17%, #5218F4 99.12%)')};
  height: 48px;
  width: 180px;
  border-radius: 30px;
  margin-top: 16px;
  box-shadow: none;
`
interface FarmCardActionsProps {
  stakedBalance: BigNumber
  tokenBalance?: BigNumber
  tokenName?: string
  pid: number
  version: number
  addLiquidityUrl?: string
}

const IconButtonWrapper = styled.div`
  display: flex;
  svg {
    width: 20px;
  }
`

const StakeAction: React.FC<FarmCardActionsProps> = ({
  stakedBalance,
  tokenBalance,
  tokenName,
  pid,
  version,
  addLiquidityUrl,
}) => {
  const { onStake } = useStake(pid)
  const { onUnstake } = useUnstake(pid)
  const { t } = useTranslation()

  const farm = useFarmFromPid(pid, version)

  const { totalUSD, tokenAmount, tokenPriceUsd, quoteTokenAmount, quoteTokenPriceUsd } = useLpUSD(pid, version)

  const displayBalance = useCallback(() => {
    const stakedBalanceNumber = getBalanceNumber(stakedBalance)
    // console.log('stakedBalanceNumber', stakedBalanceNumber)
    if (stakedBalanceNumber > 0 && stakedBalanceNumber <= 0.0001) {
      return '< 0.0001'
    }
    return new BN(new BN(stakedBalanceNumber).toFixed(4, 1)).toNumber()
  }, [stakedBalance])

  const [onPresentDeposit] = useModal(
    <DepositModal max={tokenBalance} onConfirm={onStake} tokenName={tokenName} addLiquidityUrl={addLiquidityUrl} />
  )
  const [onPresentWithdraw] = useModal(
    <WithdrawModal max={stakedBalance} onConfirm={onUnstake} tokenName={tokenName} />
  )

  const renderStakingButtons = () => {
    return stakedBalance.eq(0) ? (
      <FarmActionButton onClick={onPresentDeposit} disabled={farm?.hasMove ?? false}>
        {t('FARM_53')}
      </FarmActionButton>
    ) : (
      <IconButtonWrapper>
        <IconButton
          variant="tertiary"
          onClick={onPresentWithdraw}
          mr="20px"
          style={{
            background: 'linear-gradient(91.57deg, #FF3093 1.17%, #5218F4 99.12%)',
            borderRadius: '10px',
            boxShadow: 'none',
          }}
        >
          <MinusIcon color="#fff" width="16px" />
        </IconButton>
        <MouseoverTooltip placement="top" text={farm?.hasMove ? t('FARM_51') : ''}>
          <IconButton
            variant="primary"
            onClick={() => {
              if (!farm?.hasMove) {
                onPresentDeposit()
              }
            }}
            disabled={false}
            style={{
              background: farm?.hasMove ? '#E6E7E8' : 'linear-gradient(91.57deg, #FF3093 1.17%, #5218F4 99.12%)',
              borderRadius: '10px',
              boxShadow: 'none',
              cursor: farm?.hasMove ? 'not-allowed' : 'pointer',
            }}
          >
            <AddIcon color="#fff" width="16px" />
          </IconButton>
        </MouseoverTooltip>
      </IconButtonWrapper>
    )
  }

  return (
    <Flex justifyContent="space-between" alignItems="center">
      <Heading
        style={{
          fontSize: '24px',
          height: '48px',
          lineHeight: '48px',
          textAlign: 'left',
          marginTop: '0px',
          fontWeight: 700,
        }}
        color={stakedBalance.eq(0) ? 'textDisabled' : 'text'}
      >
        {displayBalance()}

        <Text fontSize="14px" color="textSubtle" style={{ marginTop: '-12px' }}>
          ≈ ${totalUSD ?? 0}{' '}
          <QuestionHelper
            styles={{ position: 'relative', top: '2px', width: '400px' }}
            color="#303030"
            text={
              <div>
                <div style={{ fontSize: '14px' }}>
                  {`${new BN(tokenAmount).toFixed(3, 1)}${farm.token.symbol} ≈ $${new BN(tokenAmount)
                    .multipliedBy(tokenPriceUsd)
                    .toFixed(3, 1)}`}
                </div>
                <div style={{ fontSize: '14px' }}>
                  {new BN(quoteTokenAmount).toFixed(2, 1)}
                  {farm.quoteToken.symbol} ≈ ${new BN(quoteTokenAmount).multipliedBy(quoteTokenPriceUsd).toFixed(3, 1)}
                </div>
              </div>
            }
            size={18}
          />
        </Text>
      </Heading>
      {renderStakingButtons()}
    </Flex>
  )
}

export default StakeAction
