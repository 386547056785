/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { poolsV2 } from 'constants/pools'
import { Pool, PoolsState } from '../types'
import { fetchPoolsV2BlockLimits, fetchPoolsV2TotalStaking } from './fetchPools'
import {
  fetchPoolsAllowance,
  fetchUserBalances,
  fetchUserPendingRewards,
  fetchUserStakeBalances,
} from './fetchPoolsUser'

const initialState: PoolsState = { dataV2: [...poolsV2] }

export const PoolsSlice = createSlice({
  name: 'Pools',
  initialState,
  reducers: {
    setPoolsPublicData: (state, action) => {
      const livePoolsV2Data: Pool[] = action.payload.poolsV2
      state.dataV2 = state.dataV2.map((pool) => {
        const livePoolDataV2 = livePoolsV2Data.find((entry) => entry.sortOrder === pool.sortOrder)
        return { ...pool, ...livePoolDataV2 }
      })
    },
    setPoolsUserData: (state, action) => {
      const userDataV2 = action.payload.poolsV2
      state.dataV2 = state.dataV2.map((pool) => {
        const userPoolDataV2 = userDataV2.find((entry) => entry.sortOrder === pool.sortOrder)
        return { ...pool, userData: userPoolDataV2 }
      })
    },
    updatePoolsUserData: (state, action) => {
      const { field, value, sortOrder } = action.payload
      const index = state.dataV2.findIndex((p) => p.sortOrder === sortOrder)
      state.dataV2[index] = {
        ...state.dataV2[index],
        userData: { ...state.dataV2[index]?.userData, [field]: value } as any,
      }
    },
  },
})

// Actions
export const { setPoolsPublicData, setPoolsUserData, updatePoolsUserData } = PoolsSlice.actions

// Thunks
export const fetchPoolsPublicDataAsync = () => async (dispatch) => {
  const blockLimitsV2 = await fetchPoolsV2BlockLimits()
  const totalStakingsV2 = await fetchPoolsV2TotalStaking()
  const liveDataV2 = poolsV2.map((pool) => {
    const blockLimit = blockLimitsV2.find((entry) => entry.sousId === pool.sousId)
    const totalStaking = totalStakingsV2.find((entry) => entry.sortOrder === pool.sortOrder)
    return {
      ...blockLimit,
      ...totalStaking,
    }
  })
  dispatch(setPoolsPublicData({ poolsV2: liveDataV2 }))
}

export const fetchPoolsUserDataAsync = (account) => async (dispatch) => {
  const allowancesV2 = await fetchPoolsAllowance(account)
  const stakingTokenBalancesV2 = await fetchUserBalances(account)
  const pendingRewardsV2 = await fetchUserPendingRewards(account)
  const stakedBalancesV2 = await fetchUserStakeBalances(account)

  const userDataV2 = poolsV2.map((pool) => ({
    sortOrder: pool.sortOrder,
    sousId: pool.sousId,
    allowance: allowancesV2[pool.sortOrder],
    stakingTokenBalance: stakingTokenBalancesV2[pool.sortOrder],
    stakedBalance: stakedBalancesV2[pool.sortOrder].userStakedBalance,
    selfShare: stakedBalancesV2[pool.sortOrder].userSelfShare,
    pendingReward: pendingRewardsV2[pool.sortOrder],
    lastDepositedTime: stakedBalancesV2[pool.sortOrder]?.lastDepositedTime,
  }))

  console.log('userDataV2---', userDataV2)

  dispatch(setPoolsUserData({ poolsV2: userDataV2 }))
}

export const updateUserAllowance = (sortOrder: number, account: string) => async (dispatch) => {
  const allowances = await fetchPoolsAllowance(account)
  dispatch(updatePoolsUserData({ sortOrder, field: 'allowance', value: allowances[sortOrder] }))
}

export const updateUserBalance = (sortOrder: string, account: string) => async (dispatch) => {
  const tokenBalances = await fetchUserBalances(account)
  dispatch(
    updatePoolsUserData({
      sortOrder,
      field: 'stakingTokenBalance',
      value: tokenBalances[sortOrder],
    })
  )
}

export const updateUserStakedBalance = (sortOrder: number, account: string) => async (dispatch) => {
  const stakedBalances = await fetchUserStakeBalances(account)
  dispatch(
    updatePoolsUserData({
      sortOrder,
      field: 'stakedBalance',
      value: stakedBalances[sortOrder].userStakedBalance,
    })
  )
}

export const updateUserPendingReward = (sortOrder: number, account: string) => async (dispatch) => {
  const pendingRewards = await fetchUserPendingRewards(account)
  dispatch(updatePoolsUserData({ sortOrder, field: 'pendingReward', value: pendingRewards[sortOrder] }))
}

export default PoolsSlice.reducer
