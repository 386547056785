import React from 'react'
import { useActivePopups } from '../../state/application/hooks'
import { useToast } from '../../state/hooks'
import { useTranslation } from 'react-i18next'



export default function Popups() {
  // get all popups
  const activePopups = useActivePopups()

  const { toastSuccess } = useToast()
  const { t } = useTranslation()

  React.useEffect(() => {
    for (let i = 0; i < activePopups.length; i++) {
      if (activePopups[i].show === true) {
        toastSuccess(t('COMPONENTS_192'), (activePopups[i].content as any).txn.summary as any)
      }
    }
  }, [activePopups, toastSuccess, t])

  return (
    <>
      {/* <FixedPopupColumn gap="8px">
        {activePopups.map((item) => (
          <PopupItem key={item.key} content={item.content} popKey={item.key} removeAfterMs={item.removeAfterMs} />
        ))}
      </FixedPopupColumn>
      <MobilePopupWrapper height={activePopups?.length > 0 ? 'fit-content' : 0}>
        <MobilePopupInner>
          {activePopups // reverse so new items up front
            .slice(0)
            .reverse()
            .map((item) => (
              <PopupItem key={item.key} content={item.content} popKey={item.key} removeAfterMs={item.removeAfterMs} />
            ))}
        </MobilePopupInner>
      </MobilePopupWrapper> */}
    </>
  )
}
