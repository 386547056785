import BigNumber from 'bignumber.js'
import erc20 from 'constants/abis/erc20.json'
import masterchefABI from 'constants/abis/masterchef.json'
import farmsV2Config from 'constants/farmsV2'
import tokens from 'constants/tokens'
import { PriceApiListThunk } from 'state/types'
import { getAddress, getMasterChefAddress, getMasterChefV2Address } from 'utils/addressHelpers'
import { getFarmApr, getFarmDoubleDigApr } from 'utils/apr'
import multicall from 'utils/multicall'
import { FarmConfig } from '../../constants/types'

export const fetchFarmData = async (config, prices: PriceApiListThunk, version = 2) => {
  const data = await Promise.all(
    config.map(async (farmConfig: FarmConfig) => {
      const lpAddress = getAddress(farmConfig.lpAddresses)
      const calls = [
        // Balance of token in the LP contract
        {
          address: getAddress(farmConfig.token.address),
          name: 'balanceOf',
          params: [lpAddress],
        },
        // Balance of quote token on LP contract
        {
          address: getAddress(farmConfig.quoteToken.address),
          name: 'balanceOf',
          params: [lpAddress],
        },
        // Balance of LP tokens in the master chef contract
        {
          address: lpAddress,
          name: 'balanceOf',
          params: [farmConfig.version === 2 ? getMasterChefV2Address() : getMasterChefAddress()],
        },
        // Total supply of LP tokens
        {
          address: lpAddress,
          name: 'totalSupply',
        },
        // Token decimals
        {
          address: getAddress(farmConfig.token.address),
          name: 'decimals',
        },
        // Quote token decimals
        {
          address: getAddress(farmConfig.quoteToken.address),
          name: 'decimals',
        },
      ]

      // above is correct
      const [tokenBalanceLP, quoteTokenBalanceLP, lpTokenBalanceMC, lpTotalSupply, tokenDecimals, quoteTokenDecimals] =
        await multicall(erc20, calls)

      // Ratio in % a LP tokens that are in staking, vs the total number in circulation
      const lpTokenRatio = new BigNumber(lpTokenBalanceMC).div(new BigNumber(lpTotalSupply))

      // Total value in staking in quote token value
      const lpTotalInQuoteToken = new BigNumber(quoteTokenBalanceLP)
        .div(new BigNumber(10).pow(quoteTokenDecimals))
        .times(new BigNumber(2))
        .times(lpTokenRatio)

      // Amount of token in the LP that are considered staking (i.e amount of token * lp ratio)
      const tokenAmount = new BigNumber(tokenBalanceLP).div(new BigNumber(10).pow(tokenDecimals)).times(lpTokenRatio)
      const quoteTokenAmount = new BigNumber(quoteTokenBalanceLP)
        .div(new BigNumber(10).pow(quoteTokenDecimals))
        .times(lpTokenRatio)

      const [info, totalAllocPoint] = await multicall(masterchefABI, [
        {
          address: getMasterChefV2Address(),
          name: 'poolInfo',
          params: [farmConfig.pid],
        },
        {
          address: getMasterChefV2Address(),
          name: 'totalAllocPoint',
        },
      ])

      const allocPoint = new BigNumber(info.allocPoint._hex)
      const poolWeight = allocPoint.div(new BigNumber(totalAllocPoint))

      // v1 apr

      const quoteTokenPriceUsd = Number(prices?.[getAddress(farmConfig.quoteToken.address).toLowerCase()]) ?? 0
      const totalLiquidity = new BigNumber(lpTotalInQuoteToken.toJSON()).times(quoteTokenPriceUsd)
      const apr =
        getFarmApr(
          poolWeight.toJSON() as any,
          new BigNumber(prices?.[getAddress(tokens.sdt.address).toLowerCase()]),
          totalLiquidity
        ) ?? 0

      // v1 apr = apr   const mainTokenApr =  getFarmApr(farm.poolWeight as any, projectTokenPrice, totalLiquidity, 2)

      if (version === 2) {
        const doubleDigTokenPriceUsd = farmConfig.doubleDigToken
          ? Number(prices?.[getAddress(farmConfig?.doubleDigToken.address as any).toLowerCase()])
          : 1

        const doubleDigTokenApr = getFarmDoubleDigApr(
          poolWeight.toJSON() as any,
          new BigNumber(doubleDigTokenPriceUsd ?? 1),
          totalLiquidity,
          farmConfig.doubleDigPerBlock
        )

        const v2Apr = apr + doubleDigTokenApr

        return {
          ...farmConfig,
          tokenAmount: tokenAmount.toJSON(),
          quoteTokenAmount: quoteTokenAmount.toJSON(),
          lpTotalSupply: new BigNumber(lpTotalSupply).toJSON(),
          lpTokenBalanceMC: new BigNumber(lpTokenBalanceMC).toJSON(),
          lpTotalInQuoteToken: lpTotalInQuoteToken.toJSON(),
          tokenPriceVsQuote: tokenAmount.gt(0) ? quoteTokenAmount.div(tokenAmount).toJSON() : '0',
          poolWeight: poolWeight.toJSON(),
          multiplier: `${allocPoint.div(100).toString()}X`,
          liquidity: totalLiquidity,
          doubleDigTokenApr,
          mainTokenApr: apr,
          apr: v2Apr,
        }
      }

      return {
        ...farmConfig,
        tokenAmount: tokenAmount.toJSON(),
        quoteTokenAmount: quoteTokenAmount.toJSON(),
        lpTotalSupply: new BigNumber(lpTotalSupply).toJSON(),
        lpTokenBalanceMC: new BigNumber(lpTokenBalanceMC).toJSON(),
        lpTotalInQuoteToken: lpTotalInQuoteToken.toJSON(),
        tokenPriceVsQuote: tokenAmount.gt(0) ? quoteTokenAmount.div(tokenAmount).toJSON() : '0',
        poolWeight: poolWeight.toJSON(),
        multiplier: `${allocPoint.div(100).toString()}X`,
        liquidity: totalLiquidity,
        apr,
      }
    })
  )
  return data
}

export const fetchFarmsV2 = async (prices: PriceApiListThunk) => {
  const data: any[] = await fetchFarmData(farmsV2Config, prices, 2)
  return data

  // handle cache

  // let feeData: any[] = null

  // const feeDataListCache: { updated_at: number; list: any[] } = JSON.parse(window.localStorage.getItem('PAIR_FEE_LIST'))
  // only update  one day

  // no cache  OR timestamp gt one day compare of cache  OR  cache list length ！= farm config

  // if (
  //   !feeDataListCache ||
  //   new Date().getTime() - feeDataListCache.updated_at > 1000 * 60 * 60 * 24 ||
  //   feeDataListCache.list.length !== farmsV2Config.length
  // ) {
  //   feeData = await Promise.all(
  //     data.map(async (farmConfig: any) => {
  //       // const lpAddress = getAddress(farmConfig.lpAddresses)
  //       // hack since we don't have testnet graph yet
  //       const monthVolResult = await client.query({
  //         query: ONE_PAIR_DAY_DATA(farmConfig.lpAddresses[321]),
  //         fetchPolicy: 'cache-first',
  //       })
  //       let avg = new BigNumber(0)
  //       monthVolResult.data?.pairDayDatas.forEach((item) => {
  //         avg = avg.plus(item.dailyVolumeUSD)
  //       })
  //       avg = avg.div(30).times(0.0018)
  //       return avg
  //     })
  //   )

  //   window.localStorage.setItem('PAIR_FEE_LIST', JSON.stringify({ updated_at: new Date().getTime(), list: feeData }))
  // } else {
  //   feeData = feeDataListCache.list
  // }

  // const result: any[] = []

  // for (let i = 0; i < data.length; i++) {
  //   const doubleDigFeeApr = getFarmDoubleDigFeeApr(data[i]?.poolWeight, data[i]?.liquidity, feeData[i])
  //   result.push({ doubleDigFeeApr: doubleDigFeeApr ?? 0, ...data[i] })
  // }
  // return result
}
