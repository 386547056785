import BigNumber from 'bignumber.js'
import erc20ABI from 'constants/abis/erc20.json'
import masterchefABI from 'constants/abis/masterchef.json'
import masterchefV2ABI from 'constants/abis/masterchefV2.json'
import multicall from 'utils/multicall'
import farmsConfigV2 from 'constants/farmsV2'
import { getAddress, getMasterChefV2Address } from 'utils/addressHelpers'

// fetch user farm allowances data
const fetchFarmAllowances = async (account: string) => {
  const masterChefAddress = getMasterChefV2Address()
  const farmConfig = farmsConfigV2
  const calls = farmConfig.map((farm) => {
    const lpContractAddress = getAddress(farm.lpAddresses)
    return { address: lpContractAddress, name: 'allowance', params: [account, masterChefAddress] }
  })

  const rawLpAllowances = await multicall(erc20ABI, calls)

  const parsedLpAllowances = rawLpAllowances.map((lpBalance) => {
    return new BigNumber(lpBalance).toJSON()
  })

  return parsedLpAllowances
}

export const fetchFarmV2UserAllowances = async (account: string) => {
  const allowancesData = await fetchFarmAllowances(account)
  return allowancesData
}

// fetch user farm token balances data
export const fetchFarmTokenBalances = async (account: string) => {
  const farmConfig = farmsConfigV2
  const calls = farmConfig.map((farm) => {
    const lpContractAddress = getAddress(farm.lpAddresses)
    return {
      address: lpContractAddress,
      name: 'balanceOf',
      params: [account],
    }
  })

  const rawTokenBalances = await multicall(erc20ABI, calls)
  const parsedTokenBalances = rawTokenBalances.map((tokenBalance) => {
    return new BigNumber(tokenBalance).toJSON()
  })
  return parsedTokenBalances
}

export const fetchFarmUserTokenBalances = async (account: string) => {
  const tokenBalancesData = await fetchFarmTokenBalances(account)
  return tokenBalancesData
}

export const fetchFarmV2UserTokenBalances = async (account: string) => {
  const tokenBalancesData = await fetchFarmTokenBalances(account)
  return tokenBalancesData
}

// fetch user farm staked balances data
export const fetchFarmStakedBalances = async (account: string) => {
  const masterChefAddress = getMasterChefV2Address()
  const farmConfig = farmsConfigV2

  const calls = farmConfig.map((farm) => {
    return {
      address: masterChefAddress,
      name: 'userInfo',
      params: [farm.pid, account],
    }
  })

  const rawStakedBalances = await multicall(masterchefABI, calls)
  const parsedStakedBalances = rawStakedBalances.map((stakedBalance) => {
    return new BigNumber(stakedBalance[0]._hex).toJSON()
  })

  return parsedStakedBalances
}

export const fetchFarmV2UserStakedBalances = async (account: string) => {
  const parsedStakedBalances = await fetchFarmStakedBalances(account)
  return parsedStakedBalances
}

// fetch user farm earnings data
export const fetchFarmEarnings = async (account: string) => {
  const masterChefAddress = getMasterChefV2Address()
  const farmConfig = farmsConfigV2

  const calls = farmConfig.map((farm) => {
    return {
      address: masterChefAddress,
      name: 'pendingShardex',
      params: [farm.pid, account],
    }
  })

  const rawEarnings = await multicall(masterchefABI, calls)

  const parsedEarnings = rawEarnings.map((earnings) => {
    return new BigNumber(earnings).toJSON()
  })
  return parsedEarnings
}

export const fetchFarmUserEarnings = async (account: string) => {
  const parsedEarnings = await fetchFarmEarnings(account)
  return parsedEarnings
}

export const fetchFarmV2UserEarnings = async (account: string) => {
  const parsedEarnings = await fetchFarmEarnings(account)
  return parsedEarnings
}

// fetch user farm double dig token earnings data
export const fetchFarmV2UserRewarderEarnings = async (account: string) => {
  const masterChefAddress = getMasterChefV2Address()
  const calls = farmsConfigV2.map((farm) => {
    return {
      address: masterChefAddress,
      name: 'pendingReward',
      params: [farm.pid, account],
    }
  })
  const rawEarnings = await multicall(masterchefV2ABI, calls)
  const parsedEarnings = rawEarnings.map((earnings) => {
    return new BigNumber(earnings?.pendingBonusToken?._hex ?? 0).toJSON()
  })
  return parsedEarnings
}
