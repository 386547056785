import { PreloadIamge } from 'components/PreloadImage'
import * as React from 'react'

const Preload = () => {
  return (
    <>
      <PreloadIamge name="pairLoadingImage" url="/chart-loading.png" />
    </>
  )
}

export default Preload
