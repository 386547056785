import { MenuEntry } from '../../uikit'
import i18next from '../../i18n'
import { BRIDGE } from 'constants/migrate'

const IconList = {
  reward: require('assets/images/icon/reward.svg').default,
  products: require('assets/images/icon/products.svg').default,
}

const PROD_MENU: MenuEntry[] = [
  {
    label: i18next.t('Trade'),
    ga: 'page_sidebar_trade',
    items: [
      {
        label: i18next.t('SWAP_12'),
        href: '/swap',
      },
      {
        label: i18next.t('Liquidity'),
        href: '/pool',
      },
      {
        label: i18next.t('Bridge'),
        href: BRIDGE,
        disabled: true,
      },
    ],
  },
  {
    label: i18next.t('Earn'),
    items: [
      {
        label: i18next.t('Farm'),
        href: '/farm',
      },
      {
        label: i18next.t('Pool'),
        href: '/pool',
      },
    ],
  },
  {
    label: i18next.t('Win'),
    href: '/',
    disabled: true,
  },
  {
    label: i18next.t('Reward'),
    icon: IconList.reward,
    href: '/',
    disabled: true,
  },
  {
    label: i18next.t('Shardex Products'),
    icon: IconList.products,
    href: '/',
    disabled: true,
  },
]

const DEV_MENU: MenuEntry[] = [
  {
    label: i18next.t('Trade'),
    ga: 'page_sidebar_trade',
    items: [
      {
        label: i18next.t('SWAP_12'),
        href: '/swap',
      },
      {
        label: i18next.t('Liquidity'),
        href: '/pool',
      },
      // {
      //   label: i18next.t('Bridge'),
      //   href: BRIDGE,
      //   disabled: true,
      // },
    ],
  },
  {
    label: i18next.t('Earn'),
    disabled: true,
    items: [
      {
        label: i18next.t('Farm'),
        href: '/farm',
      },
      {
        label: i18next.t('Pool'),
        href: '/pool',
      },
    ],
  },
  {
    label: i18next.t('Win'),
    href: '/',
    disabled: true,
  },
  {
    label: i18next.t('Reward'),
    icon: IconList.reward,
    href: '/',
    isReward: true,
    disabled: true,
  },
  {
    label: i18next.t('Shardex Products'),
    icon: IconList.products,
    href: '/',
    disabled: true,
  },
]

const config =
  process.env.REACT_APP_ENV_NAME === 'production-mainnet' || process.env.REACT_APP_ENV_NAME === 'preview'
    ? PROD_MENU
    : DEV_MENU

export default config
