import React, { cloneElement, Children, ReactElement } from 'react'
import styled from 'styled-components'
import { space } from 'styled-system'
import { scales, variants } from '../Button/types'
import { ButtonMenuProps } from './types'

interface StyledButtonMenuProps extends ButtonMenuProps {
  theme: any
}

const getBackgroundColor = ({ theme, variant }: StyledButtonMenuProps) => {
  if (variant === variants.TERTIARY) return theme.colors.backgroundAlt
  return theme.colors[variant === variants.SUBTLE ? 'input' : 'primary']
}

const getBorderColor = () => {
  return 'none'
}

const StyledButtonMenu = styled.div<StyledButtonMenuProps>`
  background-color: ${getBackgroundColor};
  border-radius: 16px;
  display: inline-flex;
  border: 1px solid ${getBorderColor};
  padding: 4px 6px;
  color: & > button + button, & > a + a {
    margin-left: 2px; // To avoid focus shadow overlap
  }
  ${space}
`

const ButtonMenu: React.FC<ButtonMenuProps> = ({
  activeIndex = 0,
  scale = scales.MD,
  variant = variants.PRIMARY,
  onItemClick,
  children,
  styles,
  ...props
}) => {
  return (
    <StyledButtonMenu variant={variant} {...props} style={styles}>
      {Children.map(children, (child: ReactElement, index) => {
        return cloneElement(child, {
          isActive: activeIndex === index,
          onClick: onItemClick ? () => onItemClick(index) : undefined,
          scale,
          variant,
        })
      })}
    </StyledButtonMenu>
  )
}

export default ButtonMenu
