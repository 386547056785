import { useCallback } from 'react'
import { useWeb3React } from '@web3-react/core'
import { useAppDispatch } from 'state'
import {
  fetchFarmV2UserDataAsync,
  updateUserStakedBalance,
  updateUserBalance,
  fetchPoolsUserDataAsync,
  fetchFarmsPublicDataAsync,
  fetchPoolsPublicDataAsync,
} from 'state/actions'
import { unstake, sousUnstake, sousEmergencyUnstake } from 'utils/callHelpers'
import { useMasterchefV2, useSousChef, useVaultChefV2 } from './useContract'
import { vaultUnstake } from '../utils/callHelpers'
import { usePools, useGetApiPrices } from '../state/hooks'
import { useTransactionAdder } from 'state/transactions/hooks'

const useUnstake = (pid: number) => {
  const dispatch = useAppDispatch()
  const { account } = useWeb3React()
  const masterChefV2Contract = useMasterchefV2()

  const transactionAdder = useTransactionAdder()
  const handleUnstake = useCallback(
    async (amount: string) => {
      const txHash = await unstake(masterChefV2Contract, pid, amount, account, transactionAdder)
      dispatch(fetchFarmV2UserDataAsync(account))
      console.info(txHash)
    },
    [account, dispatch, pid, transactionAdder, masterChefV2Contract]
  )

  return { onUnstake: handleUnstake }
}

const SYRUPIDS = [5, 6, 3, 1, 22, 23, 78]

export const useSousUnstake = (sousId, sortOrder) => {
  const dispatch = useAppDispatch()
  const { account } = useWeb3React()
  const masterChefContractV2 = useMasterchefV2()
  const sousChefContract = useSousChef(sousId)
  const isOldSyrup = SYRUPIDS.includes(sousId)

  const { poolsV2 } = usePools(account)

  const transactionAdder = useTransactionAdder()

  const vaultContractV2 = useVaultChefV2()

  const isAutoPool = sortOrder === 1

  const prices = useGetApiPrices()

  const handleUnstake = useCallback(
    async (amount: string, decimals: number) => {
      if (sousId === 0) {
        if (isAutoPool) {
          const txHash = await vaultUnstake(vaultContractV2, amount, account, poolsV2, transactionAdder)
          console.log(txHash)
        } else {
          await unstake(masterChefContractV2, 0, amount, account, transactionAdder)
          // console.log(txHash)
        }
        fetchPoolsUserDataAsync(account)
      } else if (isOldSyrup) {
        const txHash = await sousEmergencyUnstake(sousChefContract, amount, account)
        console.info(txHash)
      } else {
        const txHash = await sousUnstake(sousChefContract, amount, decimals, account)
        console.info(txHash)
      }

      if (isAutoPool) {
        dispatch(fetchPoolsPublicDataAsync())
      } else {
        dispatch(fetchFarmsPublicDataAsync(prices))
      }

      dispatch(updateUserStakedBalance(sortOrder, account as string))
      dispatch(updateUserBalance(sortOrder, account as string))
    },
    [
      sousId,
      isOldSyrup,
      isAutoPool,
      dispatch,
      sortOrder,
      account,
      vaultContractV2,
      poolsV2,
      transactionAdder,
      masterChefContractV2,
      sousChefContract,
      prices,
    ]
  )

  return { onUnstake: handleUnstake }
}

export default useUnstake
