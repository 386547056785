import React from 'react'
import styled from 'styled-components'
import { useFarmUser } from 'state/hooks'
import { Text, Flex } from 'uikit'
import { MouseoverTooltip } from 'components/Tooltip'
import { getBalanceNumber } from 'utils/formatBalance'
import { FarmTableDoubleLogo } from '../../../../components/DoubleLogo/FarmDoubleLogo'
import { useTranslation } from 'react-i18next'
import farmConfig from 'constants/farmsV2'

export interface FarmProps {
  label: string
  pid: number
  image: string
}

const Container = styled.div`
  padding-left: 16px;
  display: flex;
  align-items: center;
  width: auto;

  ${({ theme }) => theme.mediaQueries.md} {
    padding-left: 32px;
  }
`

export const TimeLimitedTag = styled.div`
  background: rgba(10, 196, 221, 0.15);
  border-radius: 13px;
  height: 22px;
  width: auto;
  padding: 0 8px;
  text-align: center;
  line-height: 22px;
  font-size: 12px;
  color: #0ac4dd;
`

export const TorchesLp = styled.a<{ height?: string }>`
  height: ${({ height }) => height ?? '28px'};
  padding: 0 7px;
  margin-left: -4px;
  display: flex;
  align-items: center;
  align-items: center;
  background: #f5f5f5;
  border-radius: 20px;
  :hover {
    background: rgba(20, 216, 157, 0.16);
  }
`

export const TorchesLogo = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 4px;
`

const Farm: React.FunctionComponent<FarmProps> = ({ label, pid }) => {
  const { stakedBalance } = useFarmUser(pid)
  const { t } = useTranslation()
  const rawStakedBalance = getBalanceNumber(stakedBalance)
  const handleRenderFarming = (): JSX.Element | null => {
    if (rawStakedBalance) {
      return (
        <Text color="secondary" fontSize="12px" bold>
          {t('FARM_36')}
        </Text>
      )
    }

    return null
  }

  const [currency0, currency1] = label.split('/')
  const hasEndBlock = farmConfig.find((item: any) => item?.pid === pid && item?.dual?.endBlock)
  const hasTorchesTag = farmConfig.find((item: any) => item?.pid === pid && item?.torchesSupport)
  const torchesTips = t('FARM_103')
  return (
    <Container>
      {/* <IconImage src={`/images/farms/${image}.svg`} alt="icon" width={40} height={40} mr="8px" /> */}
      <div style={{ width: '60px', marginRight: '16px', alignItems: 'center', display: 'flex' }}>
        <FarmTableDoubleLogo currency0={currency0} currency1={currency1} />
      </div>
      <Flex flexDirection="column" alignItems="flex-start">
        {handleRenderFarming()}
        <Text fontSize="20px" color="#01142a">
          {label}
        </Text>
        {hasEndBlock ? <TimeLimitedTag>{t('FARM_102')}</TimeLimitedTag> : null}
        {hasTorchesTag ? (
          <MouseoverTooltip placement="top" text={torchesTips}>
            <TorchesLp href="https://www.torches.finance/en/crypto-bank" target="_blank">
              <TorchesLogo src={require('assets/images/farm/torches.png').default} />
              <Text fontSize="12px">{t('FARM_101')}</Text>
            </TorchesLp>
          </MouseoverTooltip>
        ) : null}
      </Flex>
    </Container>
  )
}

export default Farm
