import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" color="white" {...props}>
      <path
        d="M12 20.4166C13.0615 21.3667 14.4633 21.9444 16 21.9444C19.3137 21.9444 22 19.2581 22 15.9444C22 13.294 20.2815 11.0449 17.8979 10.2508"
        stroke="#040A2D"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5853 13.7495C13.8527 14.4293 13.9995 15.1697 13.9995 15.9444C13.9995 19.2581 11.3132 21.9444 7.99951 21.9444C4.6858 21.9444 1.99951 19.2581 1.99951 15.9444C1.99951 13.287 3.72722 11.033 6.12066 10.2445"
        stroke="#040A2D"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 13.9445C15.3137 13.9445 18 11.2582 18 7.94453C18 4.63081 15.3137 1.94452 12 1.94452C8.6863 1.94452 6 4.63081 6 7.94453C6 11.2582 8.6863 13.9445 12 13.9445Z"
        stroke="#040A2D"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default Icon
