import { MinningConfig } from './types'

const proMinning: MinningConfig[] = []
const devMinning = []

const mainnetEnvName = ['production-mainnet', 'preview']

const isMainnet = mainnetEnvName.includes(process.env.REACT_APP_ENV_NAME ?? '')

export default isMainnet ? proMinning : devMinning
