import React, { useMemo, useState } from 'react'
import { Trade, TradeType } from 'shardex-sdk'
import { SyncAltIcon, Text } from '../../uikit'

import { useTranslation } from 'react-i18next'
import { Field } from '../../state/swap/actions'
import {
  computeSlippageAdjustedAmounts,
  computeTradePriceBreakdown,
  formatExecutionPrice,
  warningSeverity
} from '../../utils/prices'
import { AnimationButton, SwapButton } from '../Button'
import { AutoColumn } from '../Column'
import { AutoRow, RowBetween, RowFixed } from '../Row'
import FormattedPriceImpact from './FormattedPriceImpact'
import { StyledBalanceMaxMini, SwapCallbackError } from './styleds'

export default function SwapModalFooter({
  trade,
  onConfirm,
  allowedSlippage,
  swapErrorMessage,
  disabledConfirm,
}: {
  trade: Trade
  allowedSlippage: number
  onConfirm: () => void
  swapErrorMessage: string | undefined
  disabledConfirm: boolean
}) {
  const [showInverted, setShowInverted] = useState<boolean>(false)
  const slippageAdjustedAmounts = useMemo(
    () => computeSlippageAdjustedAmounts(trade, allowedSlippage),
    [allowedSlippage, trade]
  )

  const { priceImpactWithoutFee, realizedLPFee } = useMemo(() => computeTradePriceBreakdown(trade), [trade])
  const severity = warningSeverity(priceImpactWithoutFee)
  const { t } = useTranslation()

  return (
    <>
      <AutoColumn gap="0px">
        <RowBetween align="center">
          <Text fontSize="14px" color="#666666">
            {t('COMPONENTS_117')}
          </Text>
          <Text
            fontSize="14px"
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              textAlign: 'right',
              paddingLeft: '8px',
              fontWeight: 500,
            }}
          >
            {formatExecutionPrice(trade, showInverted)}
            <StyledBalanceMaxMini onClick={() => setShowInverted(!showInverted)}>
              <SyncAltIcon width="20px" color="primary" />
            </StyledBalanceMaxMini>
          </Text>
        </RowBetween>

        <RowBetween mt="5px">
          <RowFixed>
            <Text fontSize="14px" color="#666666">
              {trade.tradeType === TradeType.EXACT_INPUT ? t('COMPONENTS_109') : t('COMPONENTS_110')}
            </Text>
            {/* <QuestionHelper text={t('COMPONENTS_118')} /> */}
          </RowFixed>
          <RowFixed>
            <Text fontSize="14px">
              {trade.tradeType === TradeType.EXACT_INPUT
                ? slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(4, {}, 0) ?? '-'
                : slippageAdjustedAmounts[Field.INPUT]?.toSignificant(4, {}, 0) ?? '-'}
            </Text>
            <Text fontSize="14px" marginLeft="4px">
              {trade.tradeType === TradeType.EXACT_INPUT
                ? trade.outputAmount.currency.symbol
                : trade.inputAmount.currency.symbol}
            </Text>
          </RowFixed>
        </RowBetween>
        <RowBetween mt="5px">
          <RowFixed>
            <Text fontSize="14px" color="#666666">
              {t('COMPONENTS_119')}
            </Text>
            {/* <QuestionHelper text={t('COMPONENTS_120')} /> */}
          </RowFixed>
          <FormattedPriceImpact priceImpact={priceImpactWithoutFee} />
        </RowBetween>
        <RowBetween mt="5px">
          <RowFixed>
            <Text fontSize="14px" color="#666666">
              {t('COMPONENTS_122')}
            </Text>
            {/* <QuestionHelper text={t('COMPONENTS_123')} /> */}
          </RowFixed>
          <Text fontSize="14px">
            {realizedLPFee ? `${realizedLPFee?.toSignificant(6)} ${trade.inputAmount.currency.symbol}` : '-'}
          </Text>
        </RowBetween>
      </AutoColumn>

      <AutoRow>
        {severity > 2 ? (
          <SwapButton
            onClick={onConfirm}
            disabled={disabledConfirm}
            mt="10px"
            id="confirm-swap-or-send"
            width="100%"
            style={{
              background: '#FF3093',
              color: 'white',
              border: 'none',
            }}
          >
            {t('COMPONENTS_124')}
          </SwapButton>
        ) : (
          <AnimationButton
            onClick={onConfirm}
            disabled={disabledConfirm}
            mt="10px"
            id="confirm-swap-or-send"
            width="100%"
          >
            {t('COMPONENTS_125')}
          </AnimationButton>
        )}
        {swapErrorMessage ? <SwapCallbackError error={swapErrorMessage} /> : null}
      </AutoRow>
    </>
  )
}
