import BigNumber from 'bignumber.js'
import { projectToken } from 'constants/migrate'
import { ethers } from 'ethers'
import { getFarmName } from './getFarm'

export const approve = async (lpContract, masterChefContract, account): Promise<any> => {
  if (lpContract.methods) {
    return lpContract.methods
      .approve(masterChefContract.options.address, ethers.constants.MaxUint256)
      .send({ from: account })
  }
  return lpContract.functions.approve(masterChefContract.options.address, ethers.constants.MaxUint256)
}

export const stake = async (masterChefContract, pid, amount, account, transactionAdder, version?: number) => {
  if (pid === 0) {
    return masterChefContract.methods
      .enterStaking(new BigNumber(amount).times(new BigNumber(10).pow(18)).toString(10))
      .send({ from: account, gas: 200000 })
      .on('transactionHash', (tx) => {
        transactionAdder(
          { hash: tx },
          {
            summary: `Stake ${amount} ${projectToken.symbol} to manual staking POOL`,
          }
        )
        return tx.transactionHash
      })
  }

  return masterChefContract.methods
    .deposit(pid, new BigNumber(amount).times(new BigNumber(10).pow(18)).toString(10))
    .send({ from: account, gas: version === 2 ? 800000 : 200000 })
    .on('transactionHash', (tx) => {
      const farmName = getFarmName(pid)
      transactionAdder(
        { hash: tx },
        {
          summary: `Stake ${amount}  LP of ${farmName} BAR`,
        }
      )
      return tx.transactionHash
    })
}

export const vaultStake = async (vaultContract, amount, account, transactionAdder) => {
  return vaultContract.methods
    .deposit(new BigNumber(amount).times(new BigNumber(10).pow(18)).toString(10))
    .send({ from: account })
    .on('transactionHash', (tx) => {
      transactionAdder(
        { hash: tx },
        {
          summary: `Stake ${amount} ${projectToken.symbol} to automatic restaking POOL`,
        }
      )
      return tx.transactionHash
    })
}

export const sousStake = async (sousChefContract, amount, decimals = 18, account, transactionAdder) => {
  return sousChefContract.methods
    .deposit(new BigNumber(amount).times(new BigNumber(10).pow(decimals)).toString(10))
    .send({ from: account, gas: 200000 })
    .on('transactionHash', (tx) => {
      transactionAdder(
        { hash: tx },
        {
          summary: `Stake`,
        }
      )
      return tx.transactionHash
    })
}

export const sousStakeBnb = async (sousChefContract, amount, account) => {
  return sousChefContract.methods
    .deposit()
    .send({ from: account, gas: 800000, value: new BigNumber(amount).times(new BigNumber(10).pow(18)).toString(10) })
    .on('transactionHash', (tx) => {
      return tx.transactionHash
    })
}

export const unstake = async (masterChefContract, pid, amount, account, transactionAdder, version?: number) => {
  if (pid === 0) {
    return masterChefContract.methods
      .leaveStaking(new BigNumber(amount).times(new BigNumber(10).pow(18)).toString(10))
      .send({ from: account, gas: 200000 })
      .on('transactionHash', (tx) => {
        transactionAdder(
          { hash: tx },
          {
            summary: `Unstake ${amount} ${projectToken.symbol} of manual POOL`,
          }
        )
        return tx.transactionHash
      })
  }

  return masterChefContract.methods
    .withdraw(pid, new BigNumber(amount).times(new BigNumber(10).pow(18)).toString(10))
    .send({ from: account, gas: version === 2 ? 800000 : 200000 })
    .on('transactionHash', (tx) => {
      const farmName = getFarmName(pid)
      transactionAdder(
        { hash: tx },
        {
          summary: `Unstake ${amount} LP of ${farmName} BAR`,
        }
      )
      return tx.transactionHash
    })
}

export const vaultUnstake = async (vaultContract, amount, account, pools, transactionAdder) => {
  const pool = pools[1]
  // console.log(pool)

  if (pool.userData) {
    const amountInWei = new BigNumber(amount).times(new BigNumber(10).pow(18)).toString(10)
    let share = new BigNumber(amountInWei).div(pool.totalStaked).multipliedBy(pool.totalShare).toFixed(0)

    // amount overflow
    if (
      new BigNumber(share).gt(pool.userData.selfShare) ||
      new BigNumber(pool.userData.selfShare).minus(share).lte(0.1 * 10 ** 18) // a range for maxnium
    ) {
      share = pool.userData.selfShare
    }

    const p = await vaultContract.methods
      .withdraw(share.toString())
      .send({ from: account })
      .on('transactionHash', (tx) => {
        transactionAdder(
          { hash: tx },
          {
            summary: `Unstake ${amount} ${projectToken.symbol} of automatic restaking POOL`,
          }
        )

        return tx.transactionHash
      })
    return p
  }
  return null
}

export const sousUnstake = async (sousChefContract, amount, decimals = 18, account) => {
  // shit code: hard fix for old CTK and BLK
  if (sousChefContract.options.address === '0x3B9B74f48E89Ebd8b45a53444327013a2308A9BC') {
    return sousChefContract.methods
      .emergencyWithdraw()
      .send({ from: account })
      .on('transactionHash', (tx) => {
        return tx.transactionHash
      })
  }
  if (sousChefContract.options.address === '0xBb2B66a2c7C2fFFB06EA60BeaD69741b3f5BF831') {
    return sousChefContract.methods
      .emergencyWithdraw()
      .send({ from: account })
      .on('transactionHash', (tx) => {
        return tx.transactionHash
      })
  }
  if (sousChefContract.options.address === '0x453a75908fb5a36d482d5f8fe88eca836f32ead5') {
    return sousChefContract.methods
      .emergencyWithdraw()
      .send({ from: account })
      .on('transactionHash', (tx) => {
        return tx.transactionHash
      })
  }

  return sousChefContract.methods
    .withdraw(new BigNumber(amount).times(new BigNumber(10).pow(decimals)).toString(10))
    .send({ from: account, gas: 200000 })
    .on('transactionHash', (tx) => {
      return tx.transactionHash
    })
}

export const sousEmergencyUnstake = async (sousChefContract, amount, account) => {
  return sousChefContract.methods
    .emergencyWithdraw()
    .send({ from: account })
    .on('transactionHash', (tx) => {
      return tx.transactionHash
    })
}

export const harvest = async (masterChefContract, pid, account, transactionAdder, version?: number) => {
  if (pid === 0) {
    const t1 = await masterChefContract.methods
      .leaveStaking('0')
      .send({ from: account, gas: 200000 })
      .on('transactionHash', (tx) => {
        transactionAdder(
          { hash: tx },
          {
            summary: `Harvest ${projectToken.symbol} from manual POOL`,
          }
        )
        return tx.transactionHash
      })

    return t1
  }

  const x = await masterChefContract.methods
    .deposit(pid, '0')
    .send({ from: account, gas: version === 2 ? 400000 : 200000 })
    .on('transactionHash', (tx) => {
      const farmName = getFarmName(pid)
      transactionAdder(
        { hash: tx },
        {
          summary: `Harvest ${projectToken.symbol} from ${farmName} BAR`,
        }
      )
      return tx.transactionHash
    })

  return x
}

export const soushHarvest = async (sousChefContract, account, transactionAdder) => {
  return sousChefContract.methods
    .deposit('0')
    .send({ from: account, gas: 200000 })
    .on('transactionHash', (tx) => {
      transactionAdder(
        { hash: tx },
        {
          summary: `Harvest ${projectToken.symbol}`,
        }
      )
      return tx.transactionHash
    })
}

export const soushHarvestBnb = async (sousChefContract, account, transactionAdder) => {
  return sousChefContract.methods
    .deposit()
    .send({ from: account, gas: 200000, value: new BigNumber(0) })
    .on('transactionHash', (tx) => {
      transactionAdder(
        { hash: tx },
        {
          summary: `Harvest ${projectToken.symbol}`,
        }
      )
      return tx.transactionHash
    })
}

export const zapIn = async (rollingContract, to, amount, transactionAdder, account) => {
  return rollingContract.methods
    .zapIn(to)
    .send({ from: account, value: amount })
    .on('error', (error) => {
      console.log('zapIn error =', error)
      return error?.message
    })
    .on('transactionHash', (tx) => {
      transactionAdder(
        { hash: tx },
        {
          summary: `Rolling KCS`,
        }
      )
      return tx.transactionHash
    })
}

export const zapInToken = async (rollingContract, from, to, amount, transactionAdder, fromSymbol, account) => {
  return rollingContract.methods
    .zapInToken(from, amount, to)
    .send({ from: account })
    .on('error', (error) => {
      console.log('zapInToken error =', error)
      return error?.message
    })
    .on('transactionHash', (tx) => {
      transactionAdder(
        { hash: tx },
        {
          summary: `Rolling ${fromSymbol}`,
        }
      )
      return tx.transactionHash
    })
}
