import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <path
        d="M3 4C3 3.44772 3.44772 3 4 3H6C6.55228 3 7 3.44772 7 4V6C7 6.55228 6.55228 7 6 7H4C3.44772 7 3 6.55228 3 6V4Z"
        fill={props.color}
      />
      <path
        d="M3 11C3 10.4477 3.44772 10 4 10H6C6.55228 10 7 10.4477 7 11V13C7 13.5523 6.55228 14 6 14H4C3.44772 14 3 13.5523 3 13V11Z"
        fill={props.color}
      />
      <path
        d="M3 18C3 17.4477 3.44772 17 4 17H6C6.55228 17 7 17.4477 7 18V20C7 20.5523 6.55228 21 6 21H4C3.44772 21 3 20.5523 3 20V18Z"
        fill={props.color}
      />
      <path
        d="M10 4C10 3.44772 10.4477 3 11 3H13C13.5523 3 14 3.44772 14 4V6C14 6.55228 13.5523 7 13 7H11C10.4477 7 10 6.55228 10 6V4Z"
        fill={props.color}
      />
      <path
        d="M10 11C10 10.4477 10.4477 10 11 10H13C13.5523 10 14 10.4477 14 11V13C14 13.5523 13.5523 14 13 14H11C10.4477 14 10 13.5523 10 13V11Z"
        fill={props.color}
      />
      <path
        d="M10 18C10 17.4477 10.4477 17 11 17H13C13.5523 17 14 17.4477 14 18V20C14 20.5523 13.5523 21 13 21H11C10.4477 21 10 20.5523 10 20V18Z"
        fill={props.color}
      />
      <path
        d="M17 4C17 3.44772 17.4477 3 18 3H20C20.5523 3 21 3.44772 21 4V6C21 6.55228 20.5523 7 20 7H18C17.4477 7 17 6.55228 17 6V4Z"
        fill={props.color}
      />
      <path
        d="M17 11C17 10.4477 17.4477 10 18 10H20C20.5523 10 21 10.4477 21 11V13C21 13.5523 20.5523 14 20 14H18C17.4477 14 17 13.5523 17 13V11Z"
        fill={props.color}
      />
      <path
        d="M17 18C17 17.4477 17.4477 17 18 17H20C20.5523 17 21 17.4477 21 18V20C21 20.5523 20.5523 21 20 21H18C17.4477 21 17 20.5523 17 20V18Z"
        fill={props.color}
      />
    </Svg>
  )
}

export default Icon
