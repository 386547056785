import React from 'react'
import BigNumber from 'bignumber.js'
import styled from 'styled-components'
import { IconButton, useModal, CalculateIcon, Flex } from 'uikit'
import RoiCalculatorModal from 'components/RoiCalculatorModal'
import { useLpTokenPrice, useFarmUser } from 'state/hooks'
import { useTranslation } from 'react-i18next'

export interface ApyButtonProps {
  variant: 'text' | 'text-and-button'
  pid: number
  lpSymbol: string
  multiplier?: string
  lpLabel?: string
  projectTokenPrice?: BigNumber
  apr?: number
  displayApr?: string
  addLiquidityUrl?: string
  isAutoPool?: boolean
  poolApr?: number
  version?: number
  details?: any
}

const ApyLabelContainer = styled(Flex)`
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
`

const ApyButton: React.FC<ApyButtonProps> = ({
  variant,
  pid,
  lpSymbol,
  multiplier,
  lpLabel,
  projectTokenPrice,
  apr,
  addLiquidityUrl,
  displayApr,
  version,
  details,
}) => {
  // const [onPresentApyModal] = useModal(
  //   <ApyCalculatorModal lpLabel={lpLabel} cakePrice={projectTokenPrice} apr={apr} addLiquidityUrl={addLiquidityUrl} isAutoPool={isAutoPool} poolApr={poolApr} />
  // )

  const lpPrice = useLpTokenPrice(lpSymbol, version)
  const { tokenBalance, stakedBalance } = useFarmUser(pid, version)
  const { t } = useTranslation()

  const [onPresentApyModal] = useModal(
    <RoiCalculatorModal
      linkLabel={t('FARM_41', { asset: lpLabel })}
      stakingTokenBalance={stakedBalance.plus(tokenBalance)}
      stakingTokenSymbol={lpSymbol}
      stakingTokenPrice={lpPrice.toNumber()}
      earningTokenPrice={projectTokenPrice.toNumber()}
      apr={apr}
      multiplier={multiplier}
      displayApr={displayApr}
      linkHref={addLiquidityUrl}
      details={details}
      isFarm
    />
  )

  const handleClickButton = (event): void => {
    event.stopPropagation()
    onPresentApyModal()
  }

  return (
    <ApyLabelContainer alignItems="center" onClick={handleClickButton}>
      {displayApr}
      {variant === 'text-and-button' && (
        <IconButton variant="text" scale="sm" ml="4px">
          <CalculateIcon width="20px" color="#494E67" />
        </IconButton>
      )}
    </ApyLabelContainer>
  )
}

export default ApyButton
