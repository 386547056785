import addresses from 'constants/contract'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { ChainId } from 'shardex-sdk'
import { MASTERCHEF_PER_BLOCK } from './index'
import tokens from './tokens'
import { PoolCategory, PoolConfig } from './types'

dayjs.extend(utc)

// when adding new pool, pls keep sousId the same as sortOrder
export const poolsV2: PoolConfig[] = [
  {
    name: 'Manual SDT',
    sousId: 0,
    stakingToken: tokens.projectToken,
    earningToken: tokens.projectToken,
    contractAddress: {
      [ChainId.TESTNET]: addresses.masterChefV2[ChainId.TESTNET],
      [ChainId.MAINNET]: addresses.masterChefV2[ChainId.MAINNET],
    },
    poolCategory: PoolCategory.Manual,
    harvest: true,
    tokenPerBlock: MASTERCHEF_PER_BLOCK.multipliedBy(0.25).toString(),
    sortOrder: 0,
    isFinished: false,
  },
  {
    name: 'Auto SDT',
    sousId: 0,
    stakingToken: tokens.projectToken,
    earningToken: tokens.projectToken,
    contractAddress: {
      [ChainId.TESTNET]: addresses.vaultV2[ChainId.TESTNET],
      [ChainId.MAINNET]: addresses.vaultV2[ChainId.MAINNET],
    },
    poolCategory: PoolCategory.Auto,
    harvest: true,
    tokenPerBlock: MASTERCHEF_PER_BLOCK.multipliedBy(0.25).toString(),
    sortOrder: 1, // Never change it,it's the unique tag for AutoPool
    isFinished: false,
  },
]
