import JSBI from 'jsbi'

// exports for external consumption
export type BigintIsh = JSBI | bigint | string

export enum ChainId {
  MAINNET = 1,
  TESTNET = 5
}

export enum TradeType {
  EXACT_INPUT,
  EXACT_OUTPUT
}

export enum Rounding {
  ROUND_DOWN,
  ROUND_HALF_UP,
  ROUND_UP
}

export const FACTORY_ADDRESS = {
  [ChainId.TESTNET]: '0xD1c9b058DA559e026FF3Ca2493D1acf639De82Cf',
  [ChainId.MAINNET]: '0x8C04cd539AdaFca6000e6aAC8F7A0d8cc2bf77Bb'
}

export const INIT_CODE_HASH = {
  [ChainId.TESTNET]: '0xbc35e969cd1e1c58b5afee4224ddb75dd452f663ec66c08993756dfc0155a4d8',
  [ChainId.MAINNET]: '0xbc35e969cd1e1c58b5afee4224ddb75dd452f663ec66c08993756dfc0155a4d8'
}

export const ROUTER_ADDRESS = {
  [ChainId.TESTNET]: '0x2734e20c8f0e72b91A665d413eDa3E5697Aed489',
  [ChainId.MAINNET]: '0xc24bb4deBb3c99C6D7403eE4BFA572D16C129e95'
}

export const MINIMUM_LIQUIDITY = JSBI.BigInt(1000)

// exports for internal consumption
export const ZERO = JSBI.BigInt(0)
export const ONE = JSBI.BigInt(1)
export const TWO = JSBI.BigInt(2)
export const THREE = JSBI.BigInt(3)
export const THIRTY = JSBI.BigInt(30)
export const FIVE = JSBI.BigInt(5)
export const TEN = JSBI.BigInt(10)
export const _100 = JSBI.BigInt(100)
export const _998 = JSBI.BigInt(998)
export const _1000 = JSBI.BigInt(1000)
export const _10000 = JSBI.BigInt(10000)

export enum SolidityType {
  uint8 = 'uint8',
  uint256 = 'uint256'
}

export const SOLIDITY_TYPE_MAXIMA = {
  [SolidityType.uint8]: JSBI.BigInt('0xff'),
  [SolidityType.uint256]: JSBI.BigInt('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff')
}

export const PAIR_SWAP_FEE: { [key in ChainId]: { [key: string]: number } } = {
  [ChainId.TESTNET]: {},
  [ChainId.MAINNET]: {}
}
