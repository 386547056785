import { useWeb3React } from '@web3-react/core'
import { useCallback } from 'react'
import { useAppDispatch } from 'state'
import {
  fetchFarmsPublicDataAsync,
  fetchFarmV2UserDataAsync,
  fetchPoolsPublicDataAsync,
  fetchPoolsUserDataAsync,
  updateUserBalance,
  updateUserStakedBalance,
} from 'state/actions'
import { useGetApiPrices } from 'state/hooks'
import { useTransactionAdder } from 'state/transactions/hooks'
import { sousStake, sousStakeBnb, stake } from 'utils/callHelpers'
import { vaultStake } from '../utils/callHelpers'
import { useMasterchefV2, useSousChef, useVaultChefV2 } from './useContract'

const useStake = (pid: number) => {
  const dispatch = useAppDispatch()
  const { account } = useWeb3React()
  const masterChefV2Contract = useMasterchefV2()

  const transactionAdder = useTransactionAdder()

  const handleStake = useCallback(
    async (amount: string) => {
      await stake(masterChefV2Contract, pid, amount, account, transactionAdder)
      dispatch(fetchFarmV2UserDataAsync(account))
    },
    [masterChefV2Contract, pid, account, transactionAdder, dispatch]
  )

  return { onStake: handleStake }
}

export const useSousStake = (sousId, sortOrder, isUsingBnb = false) => {
  const dispatch = useAppDispatch()
  const { account } = useWeb3React()

  const masterchefContractV2 = useMasterchefV2()

  const sousChefContract = useSousChef(sousId)

  const transactionAdder = useTransactionAdder()

  const vaultContractV2 = useVaultChefV2()

  const isAutoPool = sortOrder === 1

  const prices = useGetApiPrices()

  const handleStake = useCallback(
    async (amount: string, decimals: number) => {
      if (sousId === 0) {
        if (isAutoPool) {
          await vaultStake(vaultContractV2, amount, account, transactionAdder)
        } else {
          await stake(masterchefContractV2, 0, amount, account, transactionAdder)
        }
        dispatch(fetchPoolsUserDataAsync(account))
      } else if (isUsingBnb) {
        await sousStakeBnb(sousChefContract, amount, account)
      } else {
        await sousStake(sousChefContract, amount, decimals, account, transactionAdder)
      }

      if (sousId >= 0) {
        dispatch(fetchPoolsPublicDataAsync())
      } else {
        dispatch(fetchFarmsPublicDataAsync(prices))
      }

      dispatch(updateUserStakedBalance(sortOrder, account as string))
      dispatch(updateUserBalance(sortOrder, account as string))
    },
    [
      sousId,
      isUsingBnb,
      isAutoPool,
      dispatch,
      sortOrder,
      account,
      transactionAdder,
      sousChefContract,
      vaultContractV2,
      masterchefContractV2,
      prices,
    ]
  )

  return { onStake: handleStake }
}

export default useStake
