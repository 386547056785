import React from 'react'
import styled from 'styled-components'
import { useRouteMatch, Link, useHistory } from 'react-router-dom'
import { useLocation } from 'react-router'
import { ButtonMenuItem } from 'uikit'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    padding-left: 12px;
    padding-right: 12px;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    margin-left: 16px;
  }
`

interface FarmTabProps {
  version?: number
}

const FarmTabButtons: React.FC<FarmTabProps> = ({ version }) => {
  const { url } = useRouteMatch()
  const { pathname } = useLocation()
  const { t } = useTranslation()
  const isExact = pathname.includes('historyV2')
  const history = useHistory()

  const scrollToDual = () => {
    if (isExact) {
      history.push('/farm')
    }
    setTimeout(() => {
      const getV1Pos = document.getElementById('farmDual')?.offsetTop
      window.scrollTo({
        top: getV1Pos,
        behavior: 'smooth',
      })
    })
  }

  const scrollToClassic = () => {
    if (isExact) {
      history.push('/farm')
    }
    setTimeout(() => {
      const getV1Pos = document.getElementById('farmClassic')?.offsetTop
      window.scrollTo({
        top: getV1Pos,
        behavior: 'smooth',
      })
    })
  }

  return (
    <Wrapper>
      <ButtonMenuItem
        as={Link}
        to={`${url}`}
        style={{
          background: !isExact ? 'linear-gradient(91.57deg, #FF3093 1.17%, #5218F4 99.12%)' : 'transparent',
          width: '78px',
          fontSize: '14px',
          height: '28px',
          boxShadow: 'none',
          fontWeight: 'normal',
          color: !isExact ? 'white' : '#01142A',
        }}
      >
        {t('FARM_92')}
      </ButtonMenuItem>
      <ButtonMenuItem
        onClick={scrollToClassic}
        style={{
          color: '#01142A',
          width: '78px',
          fontSize: '14px',
          height: '28px',
          boxShadow: 'none',
          fontWeight: 'normal',
        }}
      >
        {t('FARM_98')}
      </ButtonMenuItem>
      <ButtonMenuItem
        onClick={scrollToDual}
        style={{
          color: '#01142A',
          width: '78px',
          fontSize: '14px',
          height: '28px',
          boxShadow: 'none',
          fontWeight: 'normal',
        }}
      >
        {t('FARM_99')}
      </ButtonMenuItem>
      <ButtonMenuItem
        as={Link}
        to={`${url}/historyV${version ?? ''}`}
        style={{
          background: isExact ? 'linear-gradient(91.57deg, #FF3093 1.17%, #5218F4 99.12%)' : 'transparent',
          width: '78px',
          fontSize: '14px',
          height: '28px',
          boxShadow: 'none',
          fontWeight: 'normal',
          color: isExact ? 'white' : '#01142A',
          marginLeft: isExact && !isMobile ? '10px' : '0',
        }}
      >
        {t('FARM_100')}
      </ButtonMenuItem>
    </Wrapper>
  )
}

export default FarmTabButtons
