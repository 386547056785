import React from 'react'
import styled from 'styled-components'
import { useLastTruthy } from '../../hooks/useLast'
import { AdvancedSwapDetails, AdvancedSwapDetailsProps } from './AdvancedSwapDetails'

const AdvancedDetailsFooter = styled.div`
  padding: 0px 16px 10px;
  width: 100%;
  max-width: 480px;
  color: ${({ theme }) => theme.colors.textSubtle};
  z-index: 1;
  border-top: 1px solid rgba(115, 126, 141, 0.16);
  @media (max-width: 768px) {
    max-width: 340px;
  }
`

export default function AdvancedSwapDetailsDropdown({ trade, ...rest }: AdvancedSwapDetailsProps) {
  const lastTrade = useLastTruthy(trade)
  if (!trade) {
    return null
  }
  return (
    <AdvancedDetailsFooter>
      <AdvancedSwapDetails {...rest} trade={trade ?? lastTrade ?? undefined} />
    </AdvancedDetailsFooter>
  )
}
