import React, { useCallback } from 'react'
import styled from 'styled-components'
import { Text } from '../../uikit'
import useENS from '../../hooks/useENS'
import { LinkStyledButton } from '../Shared'
import { AutoColumn } from '../Column'
import { RowBetween } from '../Row'
import { useTranslation } from 'react-i18next'

const InputPanel = styled.div`
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  border-radius: 1.25rem;
  background-color: ${({ theme }) => theme.colors.invertedContrast};
  z-index: 1;
  width: 100%;
`

const ContainerRow = styled.div<{ error: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1.25rem;
  border: 1px solid ${({ error, theme }) => (error ? theme.colors.failure : theme.colors.invertedContrast)};
  transition: border-color 300ms ${({ error }) => (error ? 'step-end' : 'step-start')},
    color 500ms ${({ error }) => (error ? 'step-end' : 'step-start')};
  background-color: ${({ theme }) => theme.colors.invertedContrast};
`

const InputContainer = styled.div`
  flex: 1;
  padding: 1rem;
`

const Input = styled.input<{ error?: boolean }>`
  font-size: 1.25rem;
  outline: none;
  border: none;
  flex: 1 1 auto;
  width: 0;
  background-color: ${({ theme }) => theme.colors.invertedContrast};
  transition: color 300ms ${({ error }) => (error ? 'step-end' : 'step-start')};
  color: ${({ error, theme }) => (error ? theme.colors.failure : theme.colors.primary)};
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  width: 100%;
  ::placeholder {
    color: ${({ theme }) => theme.colors.textDisabled};
  }
  padding: 0px;
  -webkit-appearance: textfield;

  ::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  ::placeholder {
    color: ${({ theme }) => theme.colors.textDisabled};
  }
`

export default function AddressInputPanel({
  id,
  value,
  onChange,
  onRemove,
}: {
  id?: string
  // the typed string value
  value: string
  // triggers whenever the typed value changes
  onChange: (value: string) => void
  onRemove?: () => void
}) {
  const { address, loading } = useENS(value)

  const handleInput = useCallback(
    (event) => {
      const input = event.target.value
      const withoutSpaces = input.replace(/\s+/g, '')
      onChange(withoutSpaces)
    },
    [onChange]
  )

  const error = Boolean(value.length > 0 && !loading && !address)
  const { t } = useTranslation()

  return (
    <InputPanel id={id} style={{ background: '#F5F5F5' }}>
      <ContainerRow error={error} style={{ background: '#F5F5F5', border: 'none', borderRadius: '16px' }}>
        <InputContainer>
          <AutoColumn gap="md">
            <RowBetween>
              <Text color="textSubtle" fontWeight={500} fontSize="14px">
                {t('COMPONENTS_174')}
              </Text>
              <LinkStyledButton
                style={{ position: 'relative', right: 0 }}
                id="remove-recipient-button"
                onClick={onRemove}
              >
                {t('COMPONENTS_1')}
              </LinkStyledButton>
            </RowBetween>
            <Input
              style={{ fontSize: '14px', height: '48px', paddingLeft: '5px', background: '#fff', borderRadius: '8px' }}
              className="recipient-address-input"
              type="text"
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              spellCheck="false"
              placeholder={t('COMPONENTS_175')}
              error={error}
              pattern="^(0x[a-fA-F0-9]{40})$"
              onChange={handleInput}
              value={value}
            />
          </AutoColumn>
        </InputContainer>
      </ContainerRow>
    </InputPanel>
  )
}
