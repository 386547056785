import { ChainId, WETH } from 'shardex-sdk'

const tokens = {
  GoerliETH: {
    symbol: 'GoerliETH',
    projectLink: '',
  },
  wchain: {
    symbol: WETH[ChainId.MAINNET].symbol,
    address: {
      [ChainId.MAINNET]: WETH[ChainId.MAINNET].address,
      [ChainId.TESTNET]: WETH[ChainId.TESTNET].address,
    },
    decimals: WETH[ChainId.MAINNET].decimals,
    projectLink: '',
  },
  usdt: {
    symbol: 'USDT',
    address: {
      [ChainId.MAINNET]: '0x311253Da81A67699B68874fA4Aff39ecAd54A7Db',
      [ChainId.TESTNET]: '0x87F4A50382B6E8598F08fbcbeD1Bc402c6000F50',
    },
    decimals: 18,
    projectLink: '',
  },
  sdt: {
    symbol: 'SDT',
    address: {
      [ChainId.MAINNET]: '0x311253Da81A67699B68874fA4Aff39ecAd54A7Db',
      [ChainId.TESTNET]: '0x18290CF820b0C9b6468B7C315cA57c3608C06732',
    },
    decimals: 18,
    projectLink: '',
  },
  projectToken: {
    symbol: 'SDT',
    address: {
      [ChainId.MAINNET]: '0x311253Da81A67699B68874fA4Aff39ecAd54A7Db',
      [ChainId.TESTNET]: '0x18290CF820b0C9b6468B7C315cA57c3608C06732',
    },
    decimals: 18,
    projectLink: '',
  },
}

export default tokens
