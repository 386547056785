import React from 'react'
import { SvgProps } from '../types'
import styled from 'styled-components'

const swapIconSource = require('../../../../assets/images/swap.svg').default

const SwapIcon = styled.img`
  width: 16px;
  height: 16px;
`

const Icon: React.FC<SvgProps> = () => {
  return <SwapIcon src={swapIconSource} />
}

export default Icon
