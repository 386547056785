import { useFarmFromPid, useGetApiPrices } from '../state/hooks'
import { getAddress } from './addressHelpers'
import BN from 'bignumber.js'
import { useWeb3React } from '@web3-react/core'

export default function useLpUSD(pid: number, version?: number) {
  const { account } = useWeb3React()

  const prices = useGetApiPrices()
  const farm = useFarmFromPid(pid, version)

  if (!farm) {
    return null
  }

  if (!farm.userData?.stakedBalance || !prices || !farm.lpTokenBalanceMC || new BN(farm?.lpTokenBalanceMC).lte(0)) {
    return { totalUSD: 0, tokenAmount: 0, quoteTokenAmount: 0, quoteTokenPriceUsd: 0, tokenPriceUsd: 0 }
  }

  const percentage = new BN(farm.userData?.stakedBalance).div(new BN(farm.lpTokenBalanceMC ?? 0)).toNumber() ?? 0

  // console.log('percentage', percentage)

  const tokenAmount = account ? new BN(farm?.tokenAmount ?? 0).multipliedBy(percentage ?? 0).toString() ?? 0 : 0
  const quoteTokenAmount = new BN(farm?.quoteTokenAmount ?? 0).multipliedBy(percentage).toString() ?? 0

  const quoteTokenPriceUsd = new BN(prices[getAddress(farm.quoteToken.address as any).toLowerCase()]).gt(0)
    ? prices[getAddress(farm.quoteToken.address as any).toLowerCase()]
    : 0

  const tokenPriceUsd = new BN(prices[getAddress(farm.token.address as any).toLowerCase()]).gt(0)
    ? prices[getAddress(farm.token.address as any).toLowerCase()]
    : 0

  const totalUSD =
    new BN(tokenAmount)
      .multipliedBy(tokenPriceUsd)
      .plus(new BN(quoteTokenAmount).multipliedBy(quoteTokenPriceUsd))
      .toFixed(2, 1) ?? 0

  return { name: farm.lpSymbol, totalUSD, tokenAmount, quoteTokenAmount, quoteTokenPriceUsd, tokenPriceUsd }
}
