import BN from 'bignumber.js'
import QuestionHelper from 'components/QuestionHelper'
import UnlockButton from 'components/UnlockButton'
import { usePairs } from 'data/Reserves'
import { useTotalSupply } from 'data/TotalSupply'
import { useActiveWeb3React } from 'hooks'
import { useCurrency } from 'hooks/Tokens'
import useUnstake from 'hooks/useUnstake'
import WithdrawModal from 'pages/Pools/components/WithdrawModal'
import React, { useCallback } from 'react'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { Token } from 'shardex-sdk'
import { useFarmUser, useLpTokenPrice } from 'state/hooks'
import { useTokenBalance } from 'state/wallet/hooks'
import styled from 'styled-components'
import { IconButton, Text, useModal } from 'uikit'
import { getBalanceNumber, getFullDisplayBalance } from 'utils/formatBalance'
import useLpUSD from 'utils/getLpUSD'
import CellLayout from './CellLayout'
import Farm, { FarmProps } from './Farm'
import '../FarmStyle/index.css'

export interface RowV1Props {
  farm: FarmProps
  activeStatus?: ActiveProps
  staked?: any
  version?: number
  tokenName?: string
  account?: string
}
interface ActiveProps {
  isActive: boolean
  text: string
}

const StyledTr = styled.tr`
  height: 99px;
  cursor: pointer;
  & + & {
    border-top: 1px solid rgba(115, 126, 141, 0.16);
  }
  user-select: none;
`

const ActiveCell = styled.td`
  padding: 0 0;
  width: 69px;
`
const ActiveBtn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 8px;
  border-radius: 10px;
  line-height: 20px;
  font-size: 12px;
  vertical-align: middle;
`

const FarmMobileCell = styled.td`
  width: 245px;
`

const StackeCell = styled.td`
  width: 191px;
  //  padding: 0 30px 0 30px ;
  text-align: center;
`
const StackedTd = styled.td`
  width: 140px;
`
const StakeText = styled.span`
  font-family: 'SF Pro Display';
  font-size: 12px;
  color: #494e67;
  vertical-align: middle;
  line-height: 1.5;
`
const StakeFirst = styled.tr`
  display: flex;
  justify-content: flex-start;
  flex-wrap: no-warp;
  align-items: center !important;
`
const MigrateWarp = styled.td`
  width: 120px;
`
const ContainerTr = styled.tr`
  display: flex;
  padding: 4px 0 0 0;
  text-align: left;
  width: 100px;
  flex-wrap: no-warp;
`
const WithDrawButton = styled.a`
  display: block;
  width: 100%;
  height: 100%;
  background: transparent;
  border-radius: 30px;
  border: none;
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 36px;
  color: #fff;
`
const Container = styled.td`
  padding: 19px 54px 19px 32px;
  display: flex;
  height: 99px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`

const TotalUsdTr = styled.tr`
  display: flex;
  width: 100px;
  font-size: 14px;
  color: rgba(18, 25, 45, 0.38);
  justify-content: flex-start;
  align-items: center;
`

const UnlockColorButton = styled(UnlockButton)`
  background: linear-gradient(91.57deg, #ff3093 1.17%, #5218f4 99.12%);
  height: 36px;
  border-radius: 30px;
  box-shadow: none;
  font-size: 14px;
  width: 120px;
`
const MobileCell = styled.tr`
  margin-bottom: 32px;
  display: flex;
  align-items: center;
`

const MobileWrap = styled.td`
  padding: 36px 24px 4px 0px;
`

const Row: React.FunctionComponent<RowV1Props> = (props) => {
  const { t } = useTranslation()
  const { staked, activeStatus, account } = { ...props }
  const farm = staked.farmData
  const { stakedBalance, tokenBalance } = useFarmUser(staked.pid, staked.version)
  const { totalUSD, tokenAmount, tokenPriceUsd, quoteTokenAmount, quoteTokenPriceUsd } = useLpUSD(
    staked.pid,
    staked.version
  )

  const { onUnstake } = useUnstake(staked.pid)
  const displayBalance = useCallback(() => {
    const stakedBalanceNumber = getBalanceNumber(stakedBalance)
    if (stakedBalanceNumber > 0 && stakedBalanceNumber < 0.000001) {
      return getFullDisplayBalance(stakedBalance).toLocaleString()
    }
    return stakedBalanceNumber.toLocaleString()
  }, [stakedBalance])
  // todo

  const tokenPrice = getBalanceNumber(tokenBalance.multipliedBy(useLpTokenPrice(staked?.lpName, 1)))
  const totalLpStake = Number.isNaN(tokenPrice) ? 0 : new BN(tokenPrice).toFixed(3, 1)

  const tokenBalanceNum = tokenBalance ? getBalanceNumber(new BN(tokenBalance)).toFixed(3) : 0
  const toV2Link = farm.lpSymbol.split(' ')[0].replace('/', '-').toLowerCase()
  const { chainId } = useActiveWeb3React()

  const token1 = {
    address: farm.token.address[chainId],
    chainId,
    decimals: farm.token.decimals,
    name: farm.token.symbol,
    symbol: farm.token.symbol,
  }

  const token2 = {
    address: farm.quoteToken.address[chainId],
    chainId,
    decimals: farm.quoteToken.decimals,
    name: farm.quoteToken.symbol,
    symbol: farm.quoteToken.symbol,
  }

  const usePairsData = usePairs([[useCurrency(token1.address), useCurrency(token2.address)]])
  const pair = usePairsData[0][1]
  const totalPoolTokens = useTotalSupply(pair?.liquidityToken)
  const userPoolBalance = useTokenBalance(account, pair?.liquidityToken)

  const tokenF = chainId
    ? new Token(token1.chainId, token1.address, token1.decimals, token1.symbol, token1.name)
    : undefined
  const tokenS = chainId
    ? new Token(token2.chainId, token2.address, token2.decimals, token2.symbol, token2.name)
    : undefined

  const [token0Deposited, token1Deposited] =
    !!pair && !!totalPoolTokens && !!tokenF && !!tokenS && !!userPoolBalance
      ? [
          pair.getLiquidityValue(tokenF, totalPoolTokens, userPoolBalance, false),
          pair.getLiquidityValue(tokenS, totalPoolTokens, userPoolBalance, false),
        ]
      : [undefined, undefined]

  const renderButton = (text: string, cb?: () => void) => {
    return (
      <IconButton
        variant="tertiary"
        onClick={cb}
        mr="20px"
        style={{
          width: '120px',
          height: '36px',
          background: 'linear-gradient(91.57deg, #FF3093 1.17%, #5218F4 99.12%)',
          borderRadius: '30px',
          boxShadow: 'none',
        }}
      >
        <WithDrawButton>{text}</WithDrawButton>
      </IconButton>
    )
  }

  const [onPresentWithdraw] = useModal(
    <WithdrawModal max={stakedBalance} onConfirm={onUnstake} tokenName={staked.tokenName} />
  )
  if (isMobile) {
    return (
      <MobileWrap>
        <MobileCell style={{ justifyContent: 'flex-start' }}>
          <FarmMobileCell style={{ width: 'auto' }}>
            <CellLayout>
              <Farm {...props.farm} />
            </CellLayout>
          </FarmMobileCell>
          <ActiveCell style={{ paddingLeft: '24px' }}>
            <CellLayout>
              <ActiveBtn className={activeStatus.isActive ? 'activeFa' : 'inActiveFa'}>{activeStatus.text}</ActiveBtn>
            </CellLayout>
          </ActiveCell>
        </MobileCell>
        <MobileCell style={{ paddingLeft: '24px' }}>
          <StackeCell>
            <CellLayout>
              <StackedTd>
                <StakeFirst>
                  <Text textTransform="uppercase" color="secondary" fontSize="12px" pr="3px" fontWeight={500}>
                    {staked.lpName}
                  </Text>
                  <StakeText>{t('FARM_35')}</StakeText>
                </StakeFirst>
                <ContainerTr>{displayBalance()}</ContainerTr>
                <TotalUsdTr>
                  ≈${totalUSD}
                  <QuestionHelper
                    styles={{ position: 'relative', top: '0', width: '40px' }}
                    text={
                      <div>
                        <div style={{ fontSize: '14px' }}>
                          {`${new BN(tokenAmount).toFixed(3, 1)}${farm.token.symbol} ≈ $${new BN(tokenAmount)
                            .multipliedBy(tokenPriceUsd)
                            .toFixed(3, 1)}`}
                        </div>
                        <div style={{ fontSize: '14px' }}>
                          {new BN(quoteTokenAmount).toFixed(3, 1)}
                          {farm.quoteToken.symbol} ≈ $
                          {new BN(quoteTokenAmount).multipliedBy(quoteTokenPriceUsd).toFixed(3, 1)}
                        </div>
                      </div>
                    }
                    size={18}
                  />
                </TotalUsdTr>
              </StackedTd>
            </CellLayout>
          </StackeCell>
          {!account ? (
            <UnlockColorButton mt="0px" width="100%" text={t('FARM_96')} />
          ) : (
            renderButton('Withdraw', onPresentWithdraw)
          )}
        </MobileCell>
        <MobileCell style={{ paddingLeft: '24px' }}>
          <StackeCell>
            <CellLayout>
              <td style={{ width: '191px' }}>
                <StakeFirst>
                  <StakeText style={{ display: 'block', marginRight: '5px' }}>{t('FARM_77')}</StakeText>
                  <Text textTransform="uppercase" color="secondary" fontSize="12px" pr="3px" fontWeight={500}>
                    {staked.lpName}
                  </Text>
                  <StakeText>{t('FARM_35')}</StakeText>
                </StakeFirst>
                <ContainerTr>{tokenBalanceNum}</ContainerTr>
                <TotalUsdTr>
                  ≈${totalLpStake}
                  <QuestionHelper
                    styles={{ position: 'relative', top: '0', width: '40px' }}
                    text={
                      <div>
                        <div style={{ fontSize: '14px' }}>
                          {`${new BN(token0Deposited ? token0Deposited.toSignificant(6) : 0).toFixed(3, 1)}${
                            farm.token.symbol
                          } ≈ $${new BN(token0Deposited ? token0Deposited.toSignificant(6) : 0)
                            .multipliedBy(tokenPriceUsd)
                            .toFixed(3, 1)}`}
                        </div>
                        <div style={{ fontSize: '14px' }}>
                          {`${new BN(token1Deposited ? token1Deposited.toSignificant(6) : 0).toFixed(3, 1)}${
                            farm.quoteToken.symbol
                          } ≈ $${new BN(token1Deposited ? token1Deposited.toSignificant(6) : 0)
                            .multipliedBy(quoteTokenPriceUsd)
                            .toFixed(3, 1)}`}
                        </div>
                      </div>
                    }
                    size={18}
                  />
                </TotalUsdTr>
              </td>
            </CellLayout>
          </StackeCell>
          <MigrateWarp>
            <IconButton
              variant="tertiary"
              mr="20px"
              style={{
                width: '120px',
                height: '36px',
                background: 'linear-gradient(91.57deg, #FF3093 1.17%, #5218F4 99.12%)',
                borderRadius: '30px',
                boxShadow: 'none',
              }}
            >
              <WithDrawButton href={`farm#${toV2Link}`}>{t('FARM_11')}</WithDrawButton>
            </IconButton>
          </MigrateWarp>
        </MobileCell>
      </MobileWrap>
    )
  }
  return (
    <>
      <StyledTr className="itemTr">
        <Container>
          <ActiveCell>
            <CellLayout>
              <ActiveBtn className={activeStatus.isActive ? 'activeFa' : 'inActiveFa'}>{activeStatus.text}</ActiveBtn>
            </CellLayout>
          </ActiveCell>
          <FarmMobileCell>
            <CellLayout>
              <Farm {...props.farm} />
            </CellLayout>
          </FarmMobileCell>
          <StackeCell>
            <CellLayout>
              <StackedTd>
                <StakeFirst>
                  <Text textTransform="uppercase" color="secondary" fontSize="12px" pr="3px" fontWeight={500}>
                    {staked.lpName}
                  </Text>
                  <StakeText>{t('FARM_35')}</StakeText>
                </StakeFirst>
                <ContainerTr>{displayBalance()}</ContainerTr>
                <TotalUsdTr>
                  ≈${new BN(totalUSD).toFixed(3, 1)}
                  <QuestionHelper
                    styles={{ position: 'relative', top: '0', width: '40px' }}
                    text={
                      <div>
                        <div style={{ fontSize: '14px' }}>
                          {`${new BN(tokenAmount).toFixed(3, 1)}${farm.token.symbol} ≈ $${new BN(tokenAmount)
                            .multipliedBy(tokenPriceUsd)
                            .toFixed(3, 1)}`}
                        </div>
                        <div style={{ fontSize: '14px' }}>
                          {new BN(quoteTokenAmount).toFixed(3, 1)}
                          {farm.quoteToken.symbol} ≈ $
                          {new BN(quoteTokenAmount).multipliedBy(quoteTokenPriceUsd).toFixed(3, 1)}
                        </div>
                      </div>
                    }
                    size={18}
                  />
                </TotalUsdTr>
              </StackedTd>
            </CellLayout>
          </StackeCell>
          <td style={{ width: '194px' }}>
            {!account ? (
              <UnlockColorButton mt="0px" width="100%" text={t('FARM_96')} />
            ) : (
              renderButton('Withdraw', onPresentWithdraw)
            )}
          </td>
          <StackeCell>
            <CellLayout>
              <td style={{ width: '191px' }}>
                <StakeFirst>
                  <StakeText style={{ display: 'block', marginRight: '5px' }}>{t('FARM_77')}</StakeText>
                  <Text textTransform="uppercase" color="secondary" fontSize="12px" pr="3px" fontWeight={500}>
                    {staked.lpName}
                  </Text>
                </StakeFirst>
                <ContainerTr>{tokenBalanceNum}</ContainerTr>
                <TotalUsdTr>
                  ≈${totalLpStake}
                  <QuestionHelper
                    styles={{ position: 'relative', top: '0', width: '40px' }}
                    text={
                      <div>
                        <div style={{ fontSize: '14px' }}>
                          {`${new BN(token0Deposited ? token0Deposited.toSignificant(6) : 0).toFixed(3, 1)}${
                            farm.token.symbol
                          } ≈ $${new BN(token0Deposited ? token0Deposited.toSignificant(6) : 0)
                            .multipliedBy(tokenPriceUsd)
                            .toFixed(3, 1)}`}
                        </div>
                        <div style={{ fontSize: '14px' }}>
                          {`${new BN(token1Deposited ? token1Deposited.toSignificant(6) : 0).toFixed(3, 1)}${
                            farm.quoteToken.symbol
                          } ≈ $${new BN(token1Deposited ? token1Deposited.toSignificant(6) : 0)
                            .multipliedBy(quoteTokenPriceUsd)
                            .toFixed(3, 1)}`}
                        </div>
                      </div>
                    }
                    size={18}
                  />
                </TotalUsdTr>
              </td>
            </CellLayout>
          </StackeCell>
          <MigrateWarp>
            <IconButton
              variant="tertiary"
              mr="20px"
              style={{
                width: '120px',
                height: '36px',
                background: 'linear-gradient(91.57deg, #FF3093 1.17%, #5218F4 99.12%)',
                borderRadius: '30px',
                boxShadow: 'none',
              }}
            >
              <WithDrawButton href={`farm#${toV2Link}`}>{t('FARM_11')}</WithDrawButton>
            </IconButton>
          </MigrateWarp>
        </Container>
      </StyledTr>
    </>
  )
}

export default Row
