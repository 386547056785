import React from 'react'
import { useWalletModal } from '../../uikit'
import useAuth from 'hooks/useAuth'
import { useTranslation } from 'react-i18next'
import { AnimationButton } from '../Button'

const UnlockButton: React.FC<any> = (props) => {
  const { t } = useTranslation()
  const { login, logout } = useAuth()
  const { onPresentConnectModal } = useWalletModal(login, logout)

  return (
    <AnimationButton onClick={onPresentConnectModal} {...props}>
      {t('COMPONENTS_157')}
    </AnimationButton>
  )
}

export default UnlockButton
