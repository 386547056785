import React from 'react'
import styled from 'styled-components'
import { getTokenUrl } from '../../utils/getTokenUrl'

const Wrapper = styled.div<{ margin: boolean; sizeraw: number }>`
  position: relative;
  display: flex;
  flex-direction: row;
  overflow: visible;
`

interface DoubleCurrencyLogoProps {
  margin?: boolean
  size?: number
  currency0: string
  currency1: string
}

const MainLogo = styled.img`
  width: 56px;
  height: 56px;
  border-radius: 50%;
`

const MainLaunchpadLogo = styled.img`
  width: 48px;
  height: 48px;
  border: 3px solid #fff;
  border-radius: 50%;
`

const TableWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  overflow: visible;
  height: 42px;
  width: 72px;
`

const TableMainLogo = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 50%;
`

const TableSubLogo = styled.img`
  border: 3px solid #fff;
  position: absolute;
  bottom: 3px;
  left: 26px;
  z-index: 1;
  border-radius: 50%;
  background: #fff;
`

const SubLogo = styled.img`
  width: 36px;
  height: 36px;
  border: 3px solid #fff;
  position: absolute;
  background: #fff;
  bottom: 0px;
  right: -24px;
  border-radius: 50%;
`

export default function FarmDoubleLogo({ currency0, currency1, size = 16, margin = false }: DoubleCurrencyLogoProps) {
  return (
    <Wrapper sizeraw={size} margin={margin}>
      <MainLogo src={getTokenUrl(currency0)} />
      <SubLogo src={getTokenUrl(currency1)} />
    </Wrapper>
  )
}

export function LaunchpadDoubleLogo({ currency0, currency1, size = 16, margin = false }: DoubleCurrencyLogoProps) {
  return (
    <Wrapper sizeraw={size} margin={margin}>
      <MainLaunchpadLogo src={getTokenUrl(currency0)} />
      <SubLogo src={getTokenUrl(currency1)} />
    </Wrapper>
  )
}

export function FarmTableDoubleLogo({ currency0, currency1 }: DoubleCurrencyLogoProps) {
  return (
    <TableWrapper>
      <TableMainLogo src={getTokenUrl(currency0)} />
      <TableSubLogo width="42px" height="42px" src={getTokenUrl(currency1)} />
    </TableWrapper>
  )
}
