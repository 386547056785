import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" color="white" {...props}>
      <path d="M24 0H0V24H24V0Z" fill="white" fillOpacity="0.01" />
      <path
        d="M19 2H5C4.44772 2 4 2.44772 4 3V21C4 21.5523 4.44772 22 5 22H19C19.5523 22 20 21.5523 20 21V3C20 2.44772 19.5523 2 19 2Z"
        stroke="#040A2D"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path d="M10.5 7H16.5" stroke="#040A2D" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.5 12H16.5" stroke="#040A2D" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.5 17H16.5" stroke="#040A2D" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 8C8.0523 8 8.5 7.5523 8.5 7C8.5 6.4477 8.0523 6 7.5 6C6.9477 6 6.5 6.4477 6.5 7C6.5 7.5523 6.9477 8 7.5 8Z"
        fill="#040A2D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 13C8.0523 13 8.5 12.5523 8.5 12C8.5 11.4477 8.0523 11 7.5 11C6.9477 11 6.5 11.4477 6.5 12C6.5 12.5523 6.9477 13 7.5 13Z"
        fill="#040A2D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 18C8.0523 18 8.5 17.5523 8.5 17C8.5 16.4477 8.0523 16 7.5 16C6.9477 16 6.5 16.4477 6.5 17C6.5 17.5523 6.9477 18 7.5 18Z"
        fill="#040A2D"
      />
    </Svg>
  )
}

export default Icon
