import React from 'react'
import styled from 'styled-components'
import { useLocation } from 'react-router'
import { useRouteMatch, Link } from 'react-router-dom'
import { ButtonMenu, ButtonMenuItem, Toggle, Text } from 'uikit'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 32px;
  width: 100%;
  background: #fff;
  height: 60px;
  border-radius: 20px;
`

const ToggleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 32px;

  ${Text} {
    margin-left: 8px;
  }
`

export const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 40px;
  padding-right: 36px;
`

const PoolTabButtons = ({ stakedOnly, setStakedOnly, version }) => {
  const { url } = useRouteMatch()
  const { pathname } = useLocation()
  const isExact = version === 2 ? pathname.includes('historyV2') : pathname.includes('historyV1')
  const { t } = useTranslation()
  return (
    <Wrapper>
      <Container>
        <ToggleWrapper>
          <Toggle
            checked={stakedOnly}
            onChange={() => setStakedOnly(!stakedOnly)}
            scale="sm"
            style={{ width: '48px', height: '28px' }}
          />
          <Text fontSize="14px" color="#01142A" fontWeight={500} fontFamily="mojitofont">
            {t('POOL_20')}
          </Text>
        </ToggleWrapper>
        <ButtonMenu
          activeIndex={!isExact ? 0 : 1}
          scale="sm"
          variant="subtle"
          styles={{ background: '#E8E9EB', borderRadius: '18px', height: '36px' }}
        >
          <ButtonMenuItem
            as={Link}
            to={`${url}`}
            style={{
              background: !isExact ? 'linear-gradient(91.57deg, #FF3093 1.17%, #5218F4 99.12%)' : 'transparent',
              width: '78px',
              fontSize: '14px',
              height: '28px',
              boxShadow: 'none',
            }}
          >
            {t('POOL_21')}
          </ButtonMenuItem>
          <ButtonMenuItem
            as={Link}
            to={`${url}/historyV${version ?? ''}`}
            style={{
              background: isExact ? 'linear-gradient(91.57deg, #FF3093 1.17%, #5218F4 99.12%)' : 'transparent',
              width: '78px',
              fontSize: '14px',
              height: '28px',
              boxShadow: 'none',
            }}
          >
            {t('POOL_22')}
          </ButtonMenuItem>
        </ButtonMenu>
      </Container>
    </Wrapper>
  )
}

export default PoolTabButtons
