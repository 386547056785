// @ts-nocheck
import { useWeb3React } from '@web3-react/core'
import BN from 'bignumber.js'
import Column from 'components/Column'
import Meta from 'components/Meta'
import React from 'react'
import CountUp from 'react-countup'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { harvestAllPoolsV2, usePools, usePoolTotalEarns } from 'state/hooks'
import styled from 'styled-components'
import { Text } from 'uikit'
import { useToast } from '../../state/hooks'
import { useTransactionAdder } from '../../state/transactions/hooks'
import PoolsV2 from './SyrupV2'

const BannerImageWrap = styled.div`
  display: none;
  ${({ theme }) => theme.mediaQueries.md} {
    display: block;
    z-index: 0;
    width: 250px;
    align-self: flex-end;
    height: auto;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
`

const HarvestButton = styled.div<{ disabled: boolean }>`
  width: 200px;
  height: 48px;
  left: 0px;
  top: 0px;
  background:#fff;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  cursor: ${({ disabled }) => {
    if (disabled) {
      return 'not-allowed'
    }
    return 'pointer'
  }};
  pointer-events:${({ disabled }) => {
    if (disabled) {
      return 'none'
    }
    return 'default'
  }}
  
  &:hover {
    opacity: 0.8;
  }
`

const BannerImage = styled.img`
  width: 100%;
  height: auto;
  margin-bottom: 6px;
  transform: scale(1.1);
`

const BG = require('../../assets/images/pool/banner-bg.svg').default

const Header = styled.div`
  background: linear-gradient(91.57deg, #ff3093 1.17%, #5218f4 99.12%);
  height: 690px;
  color: #fff;

  ${({ theme }) => theme.mediaQueries.md} {
    height: 300px;
  }
`

export const HeaderContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  color: #fff;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  height: 100%;
  background: url(${BG}) center bottom no-repeat;
  background-size: 70% auto;

  padding-top: 25px;
  padding-left: 25px;
  padding-right: 25px;

  ${({ theme }) => theme.mediaQueries.md} {
    background: none;
    height: 300px;
    padding-top: 76px;
    padding-left: 40px;
    padding-right: 36px;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;
  }
`

export const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  @media (max-width: 1200px) {
    padding: 0 24px;
  }
`

export const FilterSizeContainer = styled(Container)`
  ${({ theme }) => theme.mediaQueries.md} {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

const Index: React.FC = () => {
  const { t } = useTranslation()
  const { account, library } = useWeb3React()
  const { poolsV2 } = usePools(account)

  const allEarnings = usePoolTotalEarns()

  const { toastInfo } = useToast()

  const transactionAdder = useTransactionAdder()

  const harvestAll = () => {
    const promises: any[] = []
    if (new BN(poolsV2[0]?.userData?.pendingReward ?? 0).div(new BN(10).pow(18)).gte(0.0001)) {
      promises.push(harvestAllPoolsV2(account, library, transactionAdder))
    }
    if (promises.length) {
      Promise.all(promises).then(() => {})
    } else {
      toastInfo(t('POOL_59'), t('POOL_58'))
    }
  }

  return (
    <>
      <Meta title="Wine Pools | Shardex" />
      <Header>
        <HeaderContainer style={{ alignItems: isMobile ? 'center' : 'flex-start' }}>
          <div style={{ position: 'relative', zIndex: 1 }}>
            <Text
              color="#fff"
              fontSize="52px"
              fontFamily="Aqum two"
              fontWeight={700}
              style={{ letterSpacing: '4px', textAlign: isMobile ? 'center' : 'left' }}
            >
              {t('POOL_60')}
            </Text>
            <Text
              color="#fff"
              fontSize="20px"
              fontFamily={isMobile ? 'mojitofont rounded' : 'mojitofont'}
              fontWeight={500}
              style={{
                width: isMobile ? 'auto' : '400px',
                textAlign: isMobile ? 'center' : 'left',
                letterSpacing: isMobile ? '2px' : '0px',
                marginTop: isMobile ? '8px' : '0',
              }}
            >
              {t('POOL_61')}
            </Text>
          </div>
          <Column
            style={{
              paddingTop: '10px',
              width: '200px',
              position: 'relative',
              zIndex: 1,
              alignItems: isMobile ? 'center' : 'flex-start',
              marginTop: isMobile ? '36px' : '0',
            }}
          >
            <Text
              fontSize="18px"
              fontWeight={600}
              color="#fff"
              fontFamily="mojitofont"
              style={{ marginTop: '8px' }}
              lineHeight="21px"
            >
              {t('POOL_62')}
            </Text>
            <Text
              fontSize="32px"
              style={{ marginTop: '8px' }}
              fontWeight={600}
              color="#fff"
              fontFamily="mojitofont"
              lineHeight="38px"
            >
              ${' '}
              <CountUp
                duration={1}
                start={0}
                decimals={2}
                formattingFn={(n) => {
                  return new BN(n).toFormat({ groupSeparator: ',', groupSize: 3, decimalSeparator: '.' })
                }}
                end={new BN(new BN(allEarnings).gt(0) ? allEarnings : 0).toNumber()}
              />
            </Text>

            <HarvestButton disabled={!account || !allEarnings} onClick={harvestAll}>
              <Text
                fontSize="18px"
                fontWeight={700}
                fontFamily="mojitofont"
                style={{
                  background: `linear-gradient(to right, #FF3093, #0ac4dd)`,
                  color: 'transparent',
                  WebkitBackgroundClip: 'text',
                }}
              >
                {t('POOL_63')}
              </Text>
            </HarvestButton>
          </Column>
          <BannerImageWrap>
            <BannerImage src={require('../../assets/images/pool/banner-bg.svg').default} alt="" />
          </BannerImageWrap>
        </HeaderContainer>
      </Header>
      {/* <InfoContainer>
        <ImgInfo src={require('assets/images/farm/warning.svg').default} />
        <Text fontSize="14px" lineHeight="22px">
          {t('POOL_64')} <InfoAnchor>{t('POOL_65')}</InfoAnchor> {t('POOL_66')}
        </Text>
      </InfoContainer> */}
      <Container>
        <PoolsV2 />
      </Container>
    </>
  )
}

export default Index
