import CurrencyLogo from 'components/CurrencyLogo'
import React from 'react'
import { SvgProps } from '../types'
import { useCurrency } from '../../../../hooks/Tokens'

const Icon: React.FC<SvgProps> = (props) => {
  const kcs = useCurrency('kcs')
  return <CurrencyLogo currency={kcs} size={(props.width as string) ?? '24px'} />
}

export default Icon
