import Metamask from './icons/Metamask'
import TokenPocket from './icons/TokenPocket'
import TrustWallet from './icons/TrustWallet'
import WalletConnect from './icons/WalletConnect'
import Coin98Wallet from './icons/Coin98Wallet'
import BitKeep from './icons/BitKeepWallet'
import NaBox from './icons/Nabox'
import { Config, ConnectorNames } from './types'

const connectors: Config[] = [
  {
    title: 'Metamask',
    icon: Metamask,
    connectorId: ConnectorNames.Injected,
  },
  {
    title: 'WalletConnect',
    icon: WalletConnect,
    connectorId: ConnectorNames.WalletConnect,
  },
  {
    title: 'Coin98',
    icon: Coin98Wallet,
    connectorId: ConnectorNames.Injected,
  },
  {
    title: 'BitKeep',
    icon: BitKeep,
    connectorId: ConnectorNames.Injected,
  },
  {
    title: 'TokenPocket',
    icon: TokenPocket,
    connectorId: ConnectorNames.Injected,
  },
  {
    title: 'Nabox',
    icon: NaBox,
    connectorId: ConnectorNames.Injected,
  },
  {
    title: 'TrustWallet',
    icon: TrustWallet,
    connectorId: ConnectorNames.WalletConnect,
  },
]

export default connectors
export const connectorLocalStorageKey = 'connectorId'
