/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { fetchReferralPost } from './hooks'

const initialState = {
  post: [],
  contractInfo: {
    loading: false,
    canContract: [],
    canAmount: [],
    canProof: [],
    canParams: [],
    canDay: [],
    totalAmount: '',
  },
}

export const referralSlice = createSlice({
  name: 'Referral',
  initialState,
  reducers: {
    setReferralPostData: (state, action) => {
      state.post = action.payload
    },
    setContractInfo: (state, action) => {
      state.contractInfo = action.payload
    },
  },
})

// Actions
export const { setReferralPostData, setContractInfo } = referralSlice.actions

// Thunks
export const fetchReferralPostAsync = (account?: string) => async (dispatch) => {
  if (!account) return
  const post = await fetchReferralPost(account)
  dispatch(setReferralPostData(post))
}

export default referralSlice.reducer
