/* eslint-disable no-restricted-syntax */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-return-assign */
/* eslint-disable consistent-return */
import { useState, useMemo, useRef } from 'react'
import { TransactionResponse } from '@ethersproject/providers'
import { calculateGasMargin, isAddress } from '../../utils'
import { useTransactionAdder } from '../transactions/hooks'
import BigNumber from 'bignumber.js'
import { BigNumber as BN } from '@ethersproject/bignumber'
import { getReferralContract } from 'utils/contractHelpers'
import { useActiveWeb3React } from '../../hooks'
import { useMultipleContractMultipleData } from '../multicall/hooks'
import { Interface } from '@ethersproject/abi'
import referralAbi from 'constants/abis/referral.json'
import addresses from 'constants/contract'
import i18next from '../../i18n'

const api = process.env.REACT_APP_REFERRAL_API_URL
const REFERRAL_INTERFACE = new Interface(referralAbi)

interface ReferralClaimData {
  index: number
  amount: string
  proof: string[]
}

interface ReferralUserData {
  [key: string]: ReferralClaimData
}

export const handleResposne = (res, resolve, reject, params) => {
  if ((res.code && res.code === 0) || (res.msg && res.msg === 'SUCCESS')) {
    if (params && params.original) {
      resolve(res)
      return
    }
    resolve(res.content || res.data)
  } else {
    if (res.code === '401') {
      window.location.href = '/'
    }
    if (res.code) {
      reject({ code: res.code, message: res.msg })
    } else {
      reject(res.error)
    }
  }
}

export const handleError = (err, reject) => {
  console.log(err)
  reject({ code: 'NET_ERROR', message: i18next.t('CONSTANT_30') })
}

export const get = (url, params?) =>
  new Promise((resolve, reject) => {
    if (params) {
      const paramsArray: any[] = []
      Object.keys(params).forEach((key) => paramsArray.push(`${key}=${params[key]}`))
      if (paramsArray.length > 0) {
        if (url.search(/\?/) === -1) {
          url += `?${paramsArray.join('&')}`
        } else {
          url += `&${paramsArray.join('&')}`
        }
      }
    }
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response: any) => response.json())
      .then((res) => handleResposne(res, resolve, reject, params))
      .catch((err) => handleError(err, reject))
  })

export const post = (url, jsonData) =>
  new Promise((resolve, reject) => {
    fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(jsonData),
    })
      // eslint-disable-next-line no-unused-expressions
      .then((response: any) => response.json())
      .then((res) => handleResposne(res, resolve, reject, jsonData))
      .catch((err) => handleError(err, reject))
  })

const checkAddress = (account: string) => {
  const formatted = isAddress(account)
  if (!formatted) {
    return Promise.reject(new Error(i18next.t('CONSTANT_28')))
  }
  return true
}

export function fetchLogin(account?: string): any {
  if (!account) return
  checkAddress(account)
  return post(`${api}/v1/login`, { address: account })
}

export function getPost(account: string): any {
  if (!account) return
  checkAddress(account)
  return get(`${api}/v1/referral_img`, { address: account })
}

export function createPost(account: string, shape: string): any {
  if (!account) return
  checkAddress(account)
  return post(`${api}/v1/referral_create_image`, { address: account, shape })
}

export function getCodeInfo(code: string): Promise<any> {
  return get(`${api}/v1/info_with_code`, { code })
}

export function postBind(account: string, code: string, sign: string, data: any): Promise<any> {
  checkAddress(account)
  return post(`${api}/v1/bind_referee`, { address: account, code, sign, data })
}

export function getFriends(address: string, page?: number): Promise<any> {
  checkAddress(address)
  return get(`${api}/v1/friends`, { address, page, limit: 5 })
}

export function getRewardHistory(address: string): Promise<any> {
  checkAddress(address)
  return get(`${api}/v1/account`, { address })
}

export const fetchReferralPost = async (account: string) => {
  if (!account) return
  let postList: any = []
  const getPostResult = await getPost(account)
  if (getPostResult.length === 9) {
    postList = getPostResult
  } else {
    await Promise.all([
      createPost(account, 'VERTICAL'),
      createPost(account, 'HORIZONTAL'),
      createPost(account, 'SQUARE'),
    ]).then((res: any) => {
      postList = [...res[0], ...res[1], ...res[2]]
    })
  }
  return postList
}

const CLAIM_PROMISES: { [key: string]: Promise<any> } = {}

export async function getClaim(address: string, chainId): Promise<any> {
  checkAddress(address)
  const key = `${chainId}:${address}`
  const isLogin = await fetchLogin(address)
  if (isLogin.code) {
    return (CLAIM_PROMISES[key] = CLAIM_PROMISES[key] ?? get(`${api}/v1/airdrop`, { address }))
  }
  return () => {}
}

export function useReferralClaimData(account: string | null | undefined): [ReferralUserData, boolean] {
  const { chainId } = useActiveWeb3React()
  const [claimInfo, setClaimInfo] = useState<{ [key: string]: any }>({})
  // const [loading, setLoading] = useState<boolean>(true)
  const loading = useRef(true)
  const key = `${chainId}:${account}`

  useMemo(async () => {
    if (!account || !chainId) return
    const data = await getClaim(account, chainId)
    // setLoading(false)
    loading.current = false
    setClaimInfo((info) => {
      return {
        ...info,
        [key]: data,
      }
    })
  }, [account, chainId, key, loading])
  return [account && chainId ? claimInfo[key] : undefined, loading.current]
}

// check if user is in blob and has not yet claimed
export function useUserHasAvailableClaim(): [any[], any[], any[], any[], any[], any[], boolean] {
  const { account, chainId } = useActiveWeb3React()
  const [userClaimData, loading] = useReferralClaimData(account)

  // console.log('userClaimData =', userClaimData)
  const userClaimDataValue = Object.values(userClaimData ?? {})
  const userClaimDataKey = Object.keys(userClaimData ?? {})
  const contractList = addresses.referral[chainId ?? 321]
  const searchContractAmount: string[] = []
  const searchContract: string[] = []
  const searchContractProof: any[] = []
  const searchContractParams: number[] = []
  const searchWeek: number[] = []

  // eslint-disable-next-line guard-for-in
  for (const i in userClaimDataValue) {
    if (userClaimDataValue[i].amount) {
      let targetKeyDate = new Date(Number(userClaimDataKey[i]) * 86400 * 1000).getUTCDay()
      targetKeyDate = targetKeyDate > 0 ? targetKeyDate - 1 : 6
      searchContractParams.push(userClaimDataValue[i].index)
      searchContractAmount.push(userClaimDataValue[i].amount)
      searchContractProof.push(userClaimDataValue[i].proof)
      searchContract.push(contractList[targetKeyDate])
      searchWeek.push(targetKeyDate)
    }
  }
  // console.log('searchContract =', searchContract, 'searchContractParams =', searchContractParams)
  const results = useMultipleContractMultipleData(
    searchContract.length ? searchContract : contractList,
    REFERRAL_INTERFACE,
    'isClaimed',
    searchContractParams.map((data) => [data])
  )
  // user is in blob and contract marks as unclaimed
  return [
    results,
    searchContract,
    searchContractParams,
    searchContractAmount,
    searchContractProof,
    searchWeek,
    loading || results[0]?.loading,
  ]
}

export const useUserUnclaimedData = (): [any[], any[], any[], any[], any[], BigNumber, boolean] => {
  const [
    userClaimData,
    contract,
    contractParams,
    contractAmount,
    contractProof,
    contractDay,
    loading,
  ] = useUserHasAvailableClaim()
  const canContract: any[] = []
  const canParams: any[] = []
  const canAmount: any[] = []
  const canProof: any[] = []
  const canDay: any[] = []
  let totalAmount = new BigNumber(0, 16)
  if (!loading) {
    for (let i = 0; i < contract.length; i++) {
      if (userClaimData && userClaimData[i] && userClaimData[i].result && !userClaimData[i].result[0]) {
        canContract.push(contract[i])
        canParams.push(contractParams[i])
        canAmount.push(contractAmount[i])
        canProof.push(contractProof[i])
        canDay.push(contractDay[i])
        totalAmount = totalAmount.plus(new BigNumber(contractAmount[i]))
      }
    }
  }
  totalAmount = new BigNumber(totalAmount).div(10 ** 18)
  return useMemo(() => {
    // console.log('tag 1 loading = ', loading, 'userClaimData =', userClaimData)
    return [canContract, canParams, canAmount, canProof, canDay, totalAmount, loading]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])
}

export function useUserUnclaimedAmount(): string | undefined {
  const [userClaimData, contract, contractAmount, loading] = useUserHasAvailableClaim()
  let totalAmount = new BigNumber(0, 16)
  // console.log('userClaimData =', userClaimData, 'contractAmount =', contractAmount, 'loading =', loading)
  if (!loading) {
    for (let i = 0; i < contract.length; i++) {
      if (userClaimData[i].result && !userClaimData[i].result[0]) {
        totalAmount = totalAmount.plus(new BigNumber(contractAmount[i]))
        // console.log(i, 'totalAmount =', totalAmount)
      }
    }
  }

  // console.log('totalAmount =', totalAmount.div(new BigNumber(10).pow(18)).toFixed(2, 1))

  return new BigNumber(totalAmount).div(10 ** 18).toFixed(2, 1)
}

export function useClaimCallback(
  library: any
): {
  claimCallback: () => Promise<any>
  totalAmount: BigNumber
  loading: boolean
} {
  // get claim data for this account
  const { chainId, account } = useActiveWeb3React()
  const [canContract, canParams, canAmount, canProof, canDay, totalAmount, loading] = useUserUnclaimedData()
  const resultHash: any[] = []
  const addTransaction = useTransactionAdder()
  const claimCallback = async () => {
    // console.log('page2 loading =', loading, 'canContract =', canContract)
    if (!account || !library || !chainId || loading || !canContract.length) return null
    await Promise.all(
      canContract.map(async (contractAddress: any, index) => {
        const args = [canParams[index], account, canAmount[index], canProof[index]]
        // console.log('contractAddress =', contractAddress, 'canDay[index] =', canDay[index], 'args =', args )
        const contract = getReferralContract(library, canDay[index])
        await contract.estimateGas
          .claim(...args, {})
          .then(async (estimatedGasLimit: BN) => {
            await contract
              .claim(...args, { value: null, gasLimit: calculateGasMargin(estimatedGasLimit) })
              .then((response: TransactionResponse) => {
                console.log(response)
                if (index === 0) {
                  addTransaction(response, {
                    summary: `Claim Referral Reward Success！`,
                    claim: { recipient: account },
                  } as any)
                }
                resultHash.push(response.hash)
              })
              .catch((e) => {
                console.log('args =', args, e)
              })
          })
          .catch(() => {
            console.log('error contractAddress =', contractAddress, 'canDay[index] =', canDay[index], args)
          })
      })
    )
    return resultHash
  }
  return { claimCallback, totalAmount, loading }
}
