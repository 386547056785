import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M6 9.00007L12 14.9883L18 9.00007"
        stroke={props.color}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default Icon
