import BN from 'bignumber.js'
import useInterval from 'hooks/useInterval'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from 'uikit'
import { calculateCountdown } from '../../../utils/datecountdown'

type Props = {
  userData: any
  withdrawFee: any
}

const PoolCutDownPanel: React.FC<Props> = ({ userData, withdrawFee }) => {
  const { t } = useTranslation()
  const [count, setCount] = React.useState(0)

  const DateCountDown = React.useMemo(() => {
    if (!userData?.lastDepositedTime) {
      return null
    }
    const threeDays = new BN(userData.lastDepositedTime).plus(60 * 60 * 24 * 3 * 1000).toNumber()
    return calculateCountdown(threeDays)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, count])

  const withdrawNeedFees = React.useMemo(() => {
    if (!DateCountDown && userData?.stakedBalance) {
      return false
    }

    if (!DateCountDown) {
      return true
    }

    if (DateCountDown.days === 0 && DateCountDown.hours === 0 && DateCountDown.min === 0 && DateCountDown.sec === 0) {
      return false
    }
    return true
  }, [DateCountDown, userData?.stakedBalance])

  const addLeadingZeros = (value: any) => {
    let value1 = String(value)
    while (value.length < 2) {
      value1 = `0${value1}`
    }
    return value1
  }

  useInterval(() => {
    if (new BN(userData?.lastDepositedTime ?? 0).plus(60 * 60 * 24 * 3 * 1000).gte(new Date().getTime())) {
      setCount((c) => c + 1)
    }
  }, 1000)

  if (withdrawNeedFees) {
    return (
      <>
        <Text color="#666" fontWeight={500}>
          {`${withdrawFee ?? 0}% ${t('POOL_47')} `}
          {new BN(userData?.stakedBalance ?? 0).gt(0) ? '' : '72h'}
        </Text>
        <Text color="#01142A" fontSize="16px">
          {new BN(userData?.stakedBalance ?? 0).gt(0) && DateCountDown
            ? `${addLeadingZeros(DateCountDown.days)}d:${addLeadingZeros(DateCountDown.hours)}h:${addLeadingZeros(
                DateCountDown.min
              )}m:${addLeadingZeros(DateCountDown.sec)}s`
            : ''}
        </Text>
      </>
    )
  }
  return (
    <Text color="#666" fontWeight={500}>
      {t('POOL_48')}
    </Text>
  )
}

export default React.memo(PoolCutDownPanel, (prev, next) => {
  if (prev.userData === next.userData && prev.withdrawFee === next.withdrawFee) {
    return true
  }
  return true
})
