import MenuList from 'components/Menu/config'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import 'rsuite/dist/rsuite.min.css'
import Dropdown from 'rsuite/Dropdown'
import styled from 'styled-components'
import { MenuEntry } from 'uikit'
import clickEvent from 'utils/gtag'
import { RowFixed } from '../../../components/Row'
import Flex from '../../components/Box/Flex'
import { useMatchBreakpoints } from '../../hooks'
import Logo from './components/Logo'
import UserBlock from './components/UserBlock'
import { NavProps } from './types'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`

const StyledNav = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transition: top 0.1s;
  padding-top: 10px;
  width: 100%;
  z-index: 20;
  transform: translate3d(0, 0, 0);
  height: 60px;
  list-style: none;
  .rs-dropdown-toggle,
  .rs-dropdown-toggle.rs-btn {
    background: transparent;
    color: white;
    font-size: 16px;
    margin: 0 24px;
    padding: 0px;
    display: flex;
    :hover {
      &.rs-btn-disabled {
        color: white;
        opacity: 0.75;
      }
      color: #ff3093;
    }
  }
  .rs-btn:focus-visible {
    outline: none;
  }
  .rs-dropdown-menu {
    width: 160px;
    border-radius: 16px;
    overflow: hidden;
    margin-top: 10px;
    box-shadow: none;
    a {
      width: 100%;
      outline: none;
      text-decoration: none;
      color: #040a2d;
    }
    li::before {
      content: '';
    }
    li {
      text-align: center;
    }
    .rs-dropdown-item {
      &:hover {
        background: #efeff2;
        color: #040a2d;
      }
    }
  }
  @media (max-width: 768px) {
    padding: 15px;
  }
`

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const BodyWrapper = styled.div`
  position: relative;
  display: flex;
`

const Inner = styled.div<{ isPushed: boolean }>`
  flex-grow: 1;
  // transition: margin-top 0.2s, margin-left 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transform: translate3d(0, 0, 0);
  max-width: 100%;
`

const ImgMobileLogo = styled.img`
  width: 36px;
  height: 36px;
`

const ImgNavigation = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 5px;
`

const StyledNavigationTitle = styled.span<{ isReward?: boolean }>`
  font-size: 16px;
  color: ${({ isReward }) => (isReward ? '#FFC530' : 'white')};
`

const StyleNavigationItem = styled(Flex)<{ isLastKey: boolean; disabled: boolean }>`
  align-items: center;
  margin: ${({ isLastKey }) => (isLastKey ? '0' : '0 24px')};
  padding-left: ${({ isLastKey }) => (isLastKey ? '24px' : '0')};
  border-left: ${({ isLastKey }) => (isLastKey ? '1px solid white' : 'none')};
  &:hover {
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    opacity: ${({ disabled }) => (disabled ? '0.75' : '1')};
  }
`

interface NavigationItemProps extends MenuEntry {
  isLastKey: boolean
}

const NavigationItem: React.FC<NavigationItemProps> = ({
  label,
  icon,
  isLastKey,
  isReward,
  disabled = false,
}) => {
  return (
    <StyleNavigationItem isLastKey={isLastKey} disabled={disabled}>
      {icon ? <ImgNavigation src={icon} /> : null}
      <StyledNavigationTitle isReward={isReward}>{label}</StyledNavigationTitle>
    </StyleNavigationItem>
  )
}

const Navigation: React.FC<{ isMobile: boolean }> = ({ isMobile }) => {
  if (isMobile) return null
  return (
    <Flex>
      {MenuList.map((data, index) => {
        if (data?.items?.length) {
          return (
            <Dropdown title={data.label} disabled={data?.disabled ?? false} noCaret key={data.label}>
              {data.items.map((subMenu, subIndex) => {
                return (
                  <Link to={subMenu.href} key={subMenu.label}>
                    <Dropdown.Item key={subIndex}>
                      {subMenu.label}
                      {/* {subMenu.disabled ? subMenu.label : <Link to={subMenu.href}>{subMenu.label}</Link>} */}
                    </Dropdown.Item>
                  </Link>
                )
              })}
            </Dropdown>
          )
        }
        return <NavigationItem {...data} key={data.label} isLastKey={index === MenuList.length - 1} />
      })}
    </Flex>
  )
}

const Menu: React.FC<NavProps> = ({
  account,
  login,
  logout,
  isDark,
  links,
  pendingCount,
  children,
}) => {
  const { isXl } = useMatchBreakpoints()
  const isMobile = isXl === false
  const [isPushed, setIsPushed] = useState(!isMobile)

  // Find the home link if provided
  const homeLink = links.find((link) => link.label === 'Home')

  const handleScroll = () => {
    const { scrollTop } = document.documentElement
    const getNav = document.getElementById('shardexNav')
    if (scrollTop >= 20) {
      getNav.style.background = '#000000'
    } else {
      getNav.style.background = 'transparent'
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <Wrapper>
      <StyledNav id="shardexNav">
        <Container>
          <RowFixed>
            {isMobile ? (
              <a href={homeLink?.href ?? '/'}>
                <ImgMobileLogo src="/images/logo/logo.svg" alt="logo" />
              </a>
            ) : (
              <Logo
                isPushed={isPushed}
                togglePush={() => {
                  clickEvent('page_bar_logo')
                  setIsPushed((prevState: boolean) => !prevState)
                }}
                isDark={isDark}
                href={homeLink?.href ?? '/'}
              />
            )}
            <Navigation isMobile={isMobile} />
          </RowFixed>
          {!!login && !!logout && (
            <Flex alignItems="center">
              <UserBlock pendingCount={pendingCount} account={account} login={login} logout={logout} />
            </Flex>
          )}
        </Container>
      </StyledNav>
      <BodyWrapper>
        <Inner isPushed={isPushed}>{children}</Inner>
      </BodyWrapper>
    </Wrapper>
  )
}

export default Menu
