import styled from 'styled-components'

const FlexLayout = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  & > * {
    min-width: 356px;
    max-width: 31.5%;
    width: 100%;
    margin-right: 0px;
    margin-bottom: 24px;
  }
  ${({ theme }) => theme.mediaQueries.md} {
    & > * {
      max-width: auto;
      margin-right: 20px;
      margin-bottom: 24px;
    }
  }
`

export default FlexLayout
