import tokens from 'constants/tokens'

import { Address } from 'constants/types'
import { ChainId } from 'shardex-sdk'

export function getTokenSymbolByAddress(tokenAddress: string) {
  const symbols = Reflect.ownKeys(tokens) as string[]
  for (let i = 0; i < symbols.length; i++) {
    const address: Address | undefined = tokens[symbols[i]]?.address
    if (address) {
      if (
        address[ChainId.MAINNET]?.toLowerCase() === tokenAddress ||
        address[ChainId.TESTNET]?.toLowerCase() === tokenAddress
      ) {
        return symbols[i].toLowerCase()
      }
    }
  }
  return ''
}

export function getTokenUrl(tokenNameOrAddress: string) {
  let tokenName = tokenNameOrAddress
  if (tokenNameOrAddress.toLowerCase().startsWith('0x')) {
    tokenName = getTokenSymbolByAddress(tokenNameOrAddress.toLowerCase())
    if (!tokenName) return '/images/coins/token.png'
  }

  return `/images/coins/${tokenName?.toLowerCase()}.png`
}
